import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UsersService } from './users.service';
import { ApplicationAccess } from '../structures/application';

import { log } from './decorators/log.decorator';

/**
 * Service gérant l'utilisateur actuel
 */
@Injectable({
    providedIn: 'root'
})
export class ApplicationService {
    constructor(private http: HttpClient, private usersService: UsersService) {}

    @log() getApplicationsAccess(structureid: number): Observable<any> {
        return this.http
            .get(`/applications/access/${structureid}`, {
                params: {
                    with_users: 1
                }
            })
            .pipe(map((data: any) => data.applications));
    }

    @log() setApplicationAccess(params: any): Observable<any> {
        return this.http.post(`/applications/access/${params.structureid}`, params);
    }

    @log() getAdditionalRolesAccess(structureid: number): Observable<any> {
        return this.http.get(`/additional_roles/access/${structureid}`).pipe(
            map((data: any) => data.additional_roles),
            map((data: any) => this.usersService.addIconsToRoles(data))
        );
    }

    @log() setAdditionalRolesAccess(params: any): Observable<any> {
        return this.http.post(`/additional_roles/access/${params.structureid}`, params);
    }
}
