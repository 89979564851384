import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { LoginService } from './login.service';

import { Contact } from '../structures/contact';
import { log } from './decorators/log.decorator';

@Injectable({
    providedIn: 'root'
})
export class ContactService {
    constructor(private http: HttpClient) {}

    @Output() contactCreated: Subject<Contact> = new Subject();
    @Output() contactUpdated: Subject<Contact> = new Subject();
    @Output() contactDeleted: Subject<number> = new Subject();

    @log() getContacts(params): Observable<Array<Contact>> {
        return this.http.get<Array<Contact>>(`/contacts`, { params });
    }

    @log() createContact(contact: Contact): Observable<any> {
        return this.http.post<Array<Contact>>(`/contacts`, contact);
    }

    @log() updateContact(contact: Contact): Observable<any> {
        return this.http.put<Array<Contact>>(`/contacts/${contact.id}`, contact);
    }

    @log() cloneContact(contactId): Observable<any> {
        return this.http.post<Array<Contact>>(`/contacts/${contactId}/duplicate`, undefined);
    }

    @log() toggleContactVisibility(contactId): Observable<any> {
        return this.http.put<Array<Contact>>(`/contacts/${contactId}/hide_show`, undefined);
    }

    @log() deleteContact(contactId): Observable<any> {
        return this.http.delete<Array<Contact>>(`/contacts/${contactId}`);
    }

    @log() postAvatar(contact: Contact, fileBase64String: any) {
        const config = {
            responseType: 'text' as 'text'
        };
        const blob = this.base64ImageToBlob(fileBase64String);

        const formData: FormData = new FormData();
        formData.append('avatar', blob, 'fils.png');

        return this.http.post(`/users/post_avatar/${contact.id}`, formData, config);
    }

    base64ImageToBlob(str: any) {
        const pos = str.indexOf(';base64,');
        const type = str.substring(5, pos);
        const b64 = str.substr(pos + 8);
        const imageContent = atob(b64);
        const buffer = new ArrayBuffer(imageContent.length);
        const view = new Uint8Array(buffer);

        for (let n = 0; n < imageContent.length; n++) {
            view[n] = imageContent.charCodeAt(n);
        }
        return new Blob([buffer], { type: type });
    }

    emitContactCreated(contact: Contact) {
        this.contactCreated.next(contact);
    }

    emitContactUpdated(contact: Contact) {
        this.contactUpdated.next(contact);
    }

    emitContactDeleted(contactId: number) {
        this.contactDeleted.next(contactId);
    }
}
