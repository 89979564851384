import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { pairwise, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { ConfigService } from '../../../services/config.service';
import { StructuresService } from '../../../services/structures.service';
import { DialogService } from '../../../services/dialog.service';
import { DiagnosticService } from '../../../services/diagnostic.service';
import { LoginService } from '../../../services/login.service';

import { Structure } from '../../../structures/structure';

import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(
        private loginService: LoginService,
        private structuresService: StructuresService,
        private configService: ConfigService,
        private dialogService: DialogService,
        private diagnosticService: DiagnosticService,
        private cookieService: CookieService,
        private formBuilder: UntypedFormBuilder,
        private router: Router
    ) {}

    subscriptions$: Subscription = new Subscription();

    loginForm: UntypedFormGroup;
    errorMessage: string;
    passwordPreview = false;
    viewPassword = false;
    structures: Structure[];
    today: Date;
    RPList: any[] = [];
    returnTo = undefined;

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.today = new Date();

        if (this.cookieService.get('username')) {
            this.loginForm.get('username').setValue(this.cookieService.get('username'));
        }

        this.subscriptions$.add(
            this.structuresService.getStructures().subscribe((data: Structure[]) => {
                this.structures = data.map((structure: Structure) => ({
                    ...structure,
                    key: structure.id,
                    title: structure.name
                }));
                for (const structure of this.structures) {
                    for (const alias of structure.aliases) {
                        this.structures.push({
                            id: structure.id,
                            key: structure.id + alias,
                            name: alias,
                            title: alias,
                            has_rps: structure.has_rps,
                            aliases: []
                        });
                    }
                }
                this.initSelectedStructure();
                this.structures.push({
                    title: 'Administration nationale',
                    key: 0,
                    id: 0
                });
                this.checkHasRPList();
            })
        );

        this.calculateViewportHeight();
        window.addEventListener('resize', this.calculateViewportHeight);
        window.addEventListener('orientationchange', this.calculateViewportHeight);
        window.addEventListener('message', (e) => this.onMessageReceived(e));
    }

    isDarkTheme() {
        return this.cookieService.get('dark') === 'true';
    }

    initSelectedStructure() {
        let structureCode: string;
        if (window.location.href.split('?')[1]) {
            if (new URLSearchParams('?' + window.location.href.split('?')[1]).get('refer')) {
                structureCode = new URLSearchParams('?' + window.location.href.split('?')[1])
                    .get('refer')
                    .split('.')[0];
            }
            if (new URLSearchParams('?' + window.location.href.split('?')[1]).get('ReturnTo')) {
                this.returnTo = new URLSearchParams('?' + window.location.href.split('?')[1]).get(
                    'ReturnTo'
                );
            }
            if (structureCode) {
                this.structures.map((structure: Structure) => {
                    if (structure.shortname === structureCode) {
                        structure.selected = true;
                    }
                });
            }
        }
    }

    onMessageReceived(message) {
        if (message.data.setPassword) {
            this.loginForm.get('password').setValue(message.data.setPassword);
        }
    }

    ngAfterViewInit() {
        this.initPasswordRevealListener();
        this.loginForm.get('password').setValue('');
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    calculateViewportHeight() {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    getHeaderText() {
        return this.configService.getConfig().header_text;
    }

    getImageTop() {
        return this.configService.getConfig().left_image_top;
    }

    getImageBottom() {
        return this.configService.getConfig().left_image_bot;
    }

    getVersion() {
        return this.configService.getConfig().version;
    }

    getRightSidePanelBackground(): any {
        return {
            'background-image': `url(${this.configService.getConfig().right_image})`
        };
    }

    checkStructureSelected() {
        if (
            this.loginForm.get('username').value &&
            this.loginForm.get('password').value &&
            this.getSelectedStructure()
        ) {
            this.checkLogin();
        }
        this.checkHasRPList();
    }

    getRPList() {
        this.subscriptions$.add(
            this.loginService.getRPList(this.getSelectedStructure().id).subscribe((data: any[]) => {
                this.RPList = data;
            })
        );
    }

    checkHasRPList() {
        if (this.getSelectedStructure() && this.getSelectedStructure().has_rps) {
            this.getRPList();
        } else {
            this.RPList = [];
        }
    }

    redirectForRP(rp: any): void {
        window.location.href = this.configService.getEndPoint() + '/oicrp/' + rp.id + '/login';
    }

    isAzureEnabled(): boolean {
        return (
            this.configService.getConfig().displayAzure ||
            (this.getSelectedStructure() && this.getSelectedStructure().enable_azure_form)
        );
    }

    /**
     * Initialise le listener qui surveille les copier/coller dans l'input password
     * Désactive le reveal password si besoin
     */
    initPasswordRevealListener() {
        this.subscriptions$.add(
            this.loginForm
                .get('password')
                .valueChanges.pipe(
                    pairwise(),
                    map((values: Array<string>) => {
                        const [oldValue, newValue] = values;
                        const oldValueLength = Math.abs(oldValue.length);
                        const newValueLength = Math.abs(newValue.length);
                        if (Math.abs(newValueLength - oldValueLength) > 1) {
                            this.viewPassword = false;
                        } else if (newValue === '') {
                            this.viewPassword = true;
                        } else if (
                            newValueLength === 1 &&
                            (oldValueLength >= 7 || oldValueLength === 0)
                        ) {
                            this.viewPassword = true;
                        }
                    })
                )
                .subscribe()
        );
    }

    checkLogin() {
        if (this.canLogin()) {
            if (this.showLoginWarning()) {
                this.dialogService.openDiagnostic(true).subscribe((data: boolean) => {
                    if (data) {
                        this.login();
                    }
                });
                return;
            } else {
                this.login();
            }
        } else if (
            this.getSelectedStructure().id === undefined &&
            this.configService.getConfig().showLoginStructure
        ) {
            this.errorMessage = 'Veuillez sélectionner une structure';
        } else if (!this.loginForm.value.username) {
            this.errorMessage = 'Veuillez renseigner votre identifiant';
        } else if (!this.loginForm.value.password) {
            this.errorMessage = 'Veuillez renseigner votre mot de passe';
        }
    }

    login() {
        this.subscriptions$.add(
            this.loginService
                .login(
                    this.loginForm.value.username,
                    this.loginForm.value.password,
                    this.getSelectedStructure().id,
                    this.returnTo
                )
                .subscribe(
                    (data: any) => {
                        const expires = new Date();
                        expires.setFullYear(expires.getFullYear() + 1);
                        this.cookieService.set('username', this.loginForm.get('username').value, {
                            expires: expires,
                            path: '/',
                            sameSite: 'None',
                            secure: true
                        });
                        this.errorMessage = '';
                        if (this.isAzureEnabled()) {
                            this.loginService.displayAzureForm().subscribe((data: any) => {
                                if (data.should_display) {
                                    this.dialogService.openAzureLogin().subscribe((status) => {
                                        if (status) {
                                            this.dialogService
                                                .openAzureConfirmation()
                                                .subscribe(() => {
                                                    if (
                                                        this.loginService.getRedirectURL() &&
                                                        !this.loginService.getException()
                                                    ) {
                                                        window.location.href =
                                                            this.loginService.getRedirectURL();
                                                        return;
                                                    }
                                                    this.loginService.clearExceptionAndNavigate();
                                                });
                                        } else {
                                            if (
                                                this.loginService.getRedirectURL() &&
                                                !this.loginService.getException()
                                            ) {
                                                window.location.href =
                                                    this.loginService.getRedirectURL();
                                                return;
                                            }
                                            this.loginService.clearExceptionAndNavigate();
                                        }
                                    });
                                } else {
                                    if (
                                        this.loginService.getRedirectURL() &&
                                        !this.loginService.getException()
                                    ) {
                                        window.location.href = this.loginService.getRedirectURL();
                                        return;
                                    }
                                    this.loginService.clearExceptionAndNavigate();
                                }
                            });
                        } else {
                            if (
                                this.loginService.getRedirectURL() &&
                                !this.loginService.getException()
                            ) {
                                window.location.href = this.loginService.getRedirectURL();
                                return;
                            }
                            this.loginService.clearExceptionAndNavigate();
                        }
                    },
                    (error: any) => {
                        this.errorMessage = error.error.userMessage;
                        if (error.error.errorCode === 'AUTH_SELECT_STRUCTURE') {
                            this.configService.getConfig().showLoginStructure = true;
                        }
                        if (error.error.errorCode === 'USER_ALREADY_CONNECT') {
                            this.loginService.clearExceptionAndNavigate();
                            this.errorMessage = '';
                        }
                    }
                )
        );
    }

    showStructuresList() {
        return this.configService.getConfig().showLoginStructure;
    }

    showPlatformWarning() {
        return this.configService.getConfig().display_platform_warning;
    }

    getMSLogo() {
        return './assets/img/logo_ms.png';
    }

    openAzureModal() {
        window.addEventListener('message', (event) => {
            if (event.data.endOfAzure) {
                window.location.reload();
            }
        });

        window.open(
            `${this.configService.getEndPoint()}/azure/login/azure`,
            '',
            'left=100,top=100,width=600,height=400'
        );
    }

    getSelectedStructure(): Structure | undefined {
        if (this.structures) {
            const structureSelected = this.structures.find((s) => s.selected);
            if (structureSelected) {
                return structureSelected;
            } else {
                return {};
            }
        }
    }

    getPasswordInputType(): string {
        return this.passwordPreview && this.viewPassword ? 'text' : 'password';
    }

    /**
     * Autorise le reveal password si celui-ci existe et si l'utilisateur n'a pas copié/collé
     */
    canViewPassword(): boolean {
        return this.viewPassword && this.loginForm.get('password').value.length > 0;
    }

    /**
     * Au clic maintenu l'input password révèle le mot de passe
     */
    togglePasswordPreview() {
        this.passwordPreview = !this.passwordPreview;
    }

    /**
     * redirige  vers la page resendPassword
     */
    resendPassword() {
        this.router.navigate(['resendPassword']);
    }

    /**
     * Vérifie que le formulaire est valide et qu'une structure est bien sélectionnée
     */
    canLogin(): boolean {
        return (
            this.loginForm.valid &&
            (this.getSelectedStructure().id !== undefined ||
                !this.configService.getConfig().showLoginStructure)
        );
    }

    showLoginWarning(): boolean {
        return (
            this.configService.getConfig().showLoginWarning &&
            !(
                this.diagnosticService.isBrowserOK() &&
                this.diagnosticService.isCookieOK() &&
                this.diagnosticService.isPopupOK()
            )
        );
    }

    getLoginInfoMessage() {
        return this.configService.getConfig().login_info_message;
    }

    goToCGU() {
        this.router.navigate(['cgu']);
    }

    openDiagnostic() {
        this.dialogService.openDiagnostic(false);
    }

    getAnchoredLogoURL() {
        return this.configService.getConfig().anchored_logo_url;
    }
}
