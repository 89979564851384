<div class="header">
    <div class="icon-apprenant"></div>
    <div class="label">{{ getTitle() }}</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<div class="container">
    <div class="name">
        <div class="icon-apprenant"></div>
        <input type="text" placeholder="Titre du contact" [(ngModel)]="contact.name" />
        <lib-uimm-dropdown
            [label]="'Structure'"
            [labelWidth]="'220px'"
            [(items)]="structures"
            (itemsChange)="updateStructure()"
            [headers]="{ search: true }"
            *ngIf="isNationalAdmin() && !contact.id"
        ></lib-uimm-dropdown>
        <div *ngIf="contact.id" class="structure-name">{{ getSelectedStructure().name }}</div>
    </div>
    <div class="fiche">
        <div class="title">Fiche</div>
        <div class="fiche-container">
            <div
                class="assistance-card-container"
                [ngClass]="contact.display_contact_card ? '' : 'expended'"
            >
                <editor
                    [ngClass]="displayFirstMenu()"
                    [(ngModel)]="contact.assistance_card"
                    [init]="tinyConfig"
                    [apiKey]="getAPIKey()"
                    [ngModelOptions]="{
                        standalone: true
                    }"
                    (onFocus)="toggleFirstMenu()"
                    (onBlur)="toggleFirstMenu()"
                ></editor>
                <div class="icon-Editer" *ngIf="!firstMenu"></div>
            </div>
            <div class="contact-container" *ngIf="contact.display_contact_card">
                <div class="avatar-container" (click)="uploadAvatar()">
                    <img [src]="contact.avatar" *ngIf="contact.avatar" />
                    <div *ngIf="!contact.avatar" class="icon-AdminActivite"></div>
                </div>
                <div class="contact-card-container">
                    <editor
                        [ngClass]="displaySecondMenu()"
                        [(ngModel)]="contact.contact_card"
                        [init]="tinyConfig"
                        [apiKey]="getAPIKey()"
                        [ngModelOptions]="{
                            standalone: true
                        }"
                        (onFocus)="toggleSecondMenu()"
                        (onBlur)="toggleSecondMenu()"
                    ></editor>
                    <div class="icon-Editer" *ngIf="!secondMenu"></div>
                </div>
            </div>
        </div>
        <div class="legend">
            <span class="label">Afficher le cartouche « Contact »</span>
            <span
                class="icon-select"
                [ngClass]="contact.display_contact_card ? 'icon-selected' : 'icon-select'"
                (click)="toggleDisplayContactCard()"
            ></span>
        </div>
    </div>
    <div class="visibility">
        <div class="title">Visiblité</div>
        <div class="role">
            <div>
                <span
                    class="icon-select"
                    [ngClass]="getAllSelectedRoleClass()"
                    (click)="toggleAllRole()"
                ></span>
                <span class="label">Rôle : </span>
            </div>
            <div class="role-list">
                <div
                    *ngFor="let role of roles"
                    [ngClass]="getSelectedRoleClass(role)"
                    [tooltip]="role.name"
                    (click)="toggleRole(role)"
                ></div>
            </div>
        </div>
        <div class="fi-fc" *ngIf="contact.roles.learner || contact.roles.prospect">
            <span class="legend"> Type de formation : </span>
            <span
                class="icon-select"
                [ngClass]="isSelectedTrainingType('FI') ? 'icon-selected' : 'icon-select'"
                (click)="toggleTrainingType('FI')"
            ></span>
            <span class="legend">FI</span>
            <span
                class="icon-select"
                [ngClass]="isSelectedTrainingType('FC') ? 'icon-selected' : 'icon-select'"
                (click)="toggleTrainingType('FC')"
            ></span>
            <span class="legend">FC</span>
        </div>
        <div class="application">
            <div>
                <span
                    class="icon-select"
                    [ngClass]="getAllSelectedApplicationClass()"
                    (click)="toggleAllApplication()"
                ></span>
                <span class="label">Applications : </span>
            </div>
            <div class="application-list">
                <div
                    *ngFor="let application of applications"
                    [ngClass]="[
                        getApplicationIcon(application),
                        getSelectedApplicationClass(application)
                    ]"
                    [tooltip]="application.name"
                    (click)="toggleApplication(application)"
                ></div>
            </div>
        </div>
        <div class="sites">
            <div class="label">Sites :</div>
            <lib-uimm-dropdown
                [label]="getSiteDropdownLabel()"
                [labelWidth]="'220px'"
                [(items)]="sites"
                [headers]="{ search: true }"
                [multiselect]="true"
            ></lib-uimm-dropdown>
        </div>
    </div>
</div>
<div class="footer">
    <div
        class="create-button"
        [ngClass]="{
            disabled: !contact.name
        }"
        (click)="createContact()"
    >
        {{ getFooter() }}
    </div>
</div>
