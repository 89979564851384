import { Injectable } from '@angular/core';
import { LogService } from './log.service';
import { ConfigService } from './config.service';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { News } from '../structures/news';
import { log } from './decorators/log.decorator';
import { EasiHttpParams } from '../interceptors/easi-http-params';

@Injectable({
    providedIn: 'root'
})
export class UploadFilesService {
    constructor(private configService: ConfigService, private http: HttpClient) {}

    @log() uploadNewsImage(file: any, news: News) {
        const config = {
            withCredentials: true,
            responseType: 'text' as 'text',
            reportProgress: true,
            observe: 'events',
            params: new EasiHttpParams({ overrideBaseUrl: true })
        };
        const blob = file;

        const formData: FormData = new FormData();
        formData.append('attachment', blob);

        const req = new HttpRequest(
            'POST',
            `${this.configService.getEndPoint()}/news/${news.id}/file`,
            formData,
            config
        );

        return this.http.request(req);
    }
}
