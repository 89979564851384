import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ConfigService } from '../../../services/config.service';
import { DialogService } from '../../../services/dialog.service';
import { LoginService } from '../../../services/login.service';
import { UsersService } from '../../../services/users.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-set-password',
    templateUrl: './set-password.component.html',
    styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit, OnDestroy {
    constructor(
        private formBuilder: UntypedFormBuilder,
        private configService: ConfigService,
        private dialogService: DialogService,
        private loginService: LoginService,
        private usersService: UsersService,
        private router: Router,
        private route: ActivatedRoute,
        private cookieService: CookieService
    ) {}

    subscriptions: Subscription = new Subscription();

    errorMessage: string;

    setPasswordForm: UntypedFormGroup;

    userid: number;
    token: string;

    ngOnInit() {
        this.userid = +this.route.snapshot.queryParamMap.get('userid');
        this.token = this.route.snapshot.queryParamMap.get('token');
        this.setPasswordForm = this.formBuilder.group({
            password: ['', Validators.required],
            confirmation_password: ['', Validators.required]
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    isDarkTheme() {
        return this.cookieService.get('dark') === 'true';
    }

    getHeaderText() {
        return this.configService.getConfig().header_text;
    }

    getImageTop() {
        return this.configService.getConfig().left_image_top_dev;
    }

    getImageBottom() {
        return this.configService.getConfig().left_image_bot;
    }

    getVersion() {
        return this.configService.getConfig().version;
    }

    getRightSidePanelBackground(): any {
        return {
            'background-image': `url(${this.configService.getConfig().right_image})`
        };
    }

    setPassword(): void {
        if (
            this.setPasswordForm.value.password === this.setPasswordForm.value.confirmation_password
        ) {
            this.subscriptions.add(
                this.usersService
                    .setPassword(this.userid, this.setPasswordForm.value.password, this.token)
                    .subscribe(
                        (data: any) => {
                            this.loginService.clearExceptionAndNavigate();
                        },
                        (error: HttpErrorResponse) => {
                            this.errorMessage = error.error.userMessage;
                        }
                    )
            );
        } else {
            this.errorMessage =
                'Les mots de passe que vous avez renseignés ne sont pas identiques.';
        }
    }

    goToCGU() {
        this.router.navigate(['cgu']);
    }

    openDiagnostic() {
        this.dialogService.openDiagnostic(false);
    }

    getAnchoredLogoURL() {
        return this.configService.getConfig().anchored_logo_url;
    }
}
