import { ApplicationRef, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';

import { AppComponent } from './app.component';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

import { LoginService } from './services/login.service';
import { ConfigService } from './services/config.service';
import { DialogService } from './services/dialog.service';

import { CookieService } from 'ngx-cookie-service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { isIE } from 'mobile-device-detect';

import { LoginModule } from './modules/login.module';
import { AdminModule } from './modules/admin.module';
import { RoutingModule } from './modules/routing.module';
import { SharedModule } from './modules/shared.module';
import { CredentialsInterceptor } from './interceptors/credentials.interceptor';
import { LogInterceptor } from './interceptors/log.interceptor';
import { EndpointInterceptor } from './interceptors/endpoint.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';

@NgModule({
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatNativeDateModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        LoginModule,
        AdminModule,
        RoutingModule,
        SharedModule
    ],
    providers: [
        MatNativeDateModule,
        { provide: MAT_DATE_LOCALE, useValue: 'fr' },
        CookieService,
        { provide: HTTP_INTERCEPTORS, useClass: EndpointInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CredentialsInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ],
    declarations: [AppComponent, HeaderComponent, FooterComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    constructor(
        private loginService: LoginService,
        private dialogService: DialogService,
        private configService: ConfigService,
        private cookieService: CookieService
    ) {}

    ngDoBootstrap(app) {
        if (isIE) {
            window.location.href = 'https://cdn.easi-training.fr/assets/html/ie.html';
        }

        const getConfig = new XMLHttpRequest();
        getConfig.onload = (data: any) => {
            const response = JSON.parse(data.target.response);
            this.configService.setConfig(response);
            const params = new URLSearchParams('?' + window.location.hash.split('?')[1]);
            if (params.get('user_id') && params.get('token') && params.get('redirect')) {
                this.loginService
                    .loginWithHub3E(+params.get('user_id'), params.get('token'))
                    .subscribe(() => {
                        window.location.href = params.get('redirect');
                    });
                return;
            }
            this.loginService.postCookies().subscribe((dataFromPost: number) => {
                this.loginService.getCookies().subscribe((dataFromGet: number) => {
                    if (dataFromPost === dataFromGet) {
                        this.getProfileConfigSetConfigAndBootstrap(response, app);
                    } else {
                        this.dialogService.openError(
                            'Veuillez autoriser les cookies pour poursuivre votre navigation sur easi'
                        );
                    }
                });
            });
        };
        getConfig.open('get', `./assets/config/config.json?${Date.now()}`, true);
        getConfig.send();
    }

    private setConfigAndBootStrap(config: object, app: ApplicationRef): void {
        this.configService.setConfig(config);
        (document.querySelector(
            '#favicon'
        ) as HTMLLinkElement).href = this.configService.getFaviconURL();
        this.loginService.getCurrentUser().subscribe(() => {
            this.loginService.forceRefreshException();
            app.bootstrap(AppComponent);
        });
    }

    private getProfileConfigSetConfigAndBootstrap(
        response: { profile: string },
        app: ApplicationRef
    ): void {
        const getProfileConfig = new XMLHttpRequest();

        let configFile;
        if (new URLSearchParams(document.location.href.split('?')[1]).get('refer')) {
            configFile = new URLSearchParams(document.location.href.split('?')[1])
                .get('refer')
                .split('.')[0];
            this.cookieService.set('config', configFile, {
                expires: 24,
                secure: true,
                sameSite: 'None'
            });
        } else if (this.cookieService.check('config')) {
            configFile = this.cookieService.get('config');
        } else if (response.profile) {
            configFile = response.profile;
        } else {
            configFile = 'uimm';
        }

        getProfileConfig.onload = (data2: any) => {
            const profileResponse = JSON.parse(data2.target.response);
            this.setConfigAndBootStrap(
                {
                    ...response,
                    ...profileResponse
                },
                app
            );
        };
        getProfileConfig.onloadend = () => {
            if (getProfileConfig.status === 404) {
                getProfileConfig.open(
                    'get',
                    `https://cdn.easi-training.fr/assets/config/uimm.json?${Date.now()}`
                );
                getProfileConfig.send();
            }
        };
        getProfileConfig.open(
            'get',
            `https://cdn.easi-training.fr/assets/config/${configFile}.json?${Date.now()}`
        );
        getProfileConfig.send();
    }
}
