<div id="history-component" *ngIf="!isLoading('historyMainView')">
    <div class="history-component_header">
        <div class="dropdowns">
            <lib-uimm-dropdown
                [label]="'Structure'"
                [labelWidth]="'220px'"
                [(items)]="structures"
                (itemsChange)="onStructureChange()"
                [headers]="{
                    search: true,
                    selectAll: false,
                    count: false
                }"
                *ngIf="isNationalAdmin()"
            ></lib-uimm-dropdown>
            <ng-container *ngIf="structureSelected()">
                <lib-uimm-dropdown
                    [label]="'Site'"
                    [labelWidth]="'220px'"
                    [(items)]="sites"
                    (itemsChange)="onFiltersChange()"
                    [headers]="{
                        search: true,
                        selectAll: false,
                        count: true
                    }"
                ></lib-uimm-dropdown>
                <lib-uimm-dropdown
                    [label]="'Entreprise'"
                    [labelWidth]="'220px'"
                    [(items)]="corporations"
                    (itemsChange)="onFiltersChange()"
                    [disabled]="!structureSelected()"
                    [headers]="{
                        search: true,
                        selectAll: false,
                        count: true
                    }"
                ></lib-uimm-dropdown>
                <lib-uimm-dropdown
                    [label]="'Type d\'utilisateur'"
                    [labelWidth]="'300px'"
                    [items]="getRolesItems()"
                    (itemsChange)="onFiltersChange()"
                ></lib-uimm-dropdown>
                <div
                    class="icon-Supprimerdugroupe"
                    (click)="resetFilters()"
                    tooltip="Annuler les filtres"
                ></div>
            </ng-container>
        </div>
        <div class="counter-and-search" *ngIf="structureSelected()">
            <div class="counter" *ngIf="showCounter()">
                <span class="count">{{ usersCount }}&nbsp;</span>
                Utilisateur{{ usersCount > 1 ? 's' : '' }} actif{{ usersCount > 1 ? 's' : ''
                }}<span
                    class="disabled-count"
                    *ngIf="disabledUsersCount > 0"
                    (click)="showDisabledUsers = !showDisabledUsers"
                >
                    {{ !showDisabledUsers ? 'Afficher' : 'Masquer' }}
                    les
                    <span class="count">{{ disabledUsersCount }}</span>
                    utilisateurs désactivés</span
                >
                <app-dates-selector
                    [(dateStart)]="startDate"
                    (dateStartChange)="onFiltersChange()"
                    [maxDateStart]="endDate"
                    [(dateEnd)]="endDate"
                    (dateEndChange)="onFiltersChange()"
                    [maxDateEnd]="today"
                ></app-dates-selector>
            </div>

            <div class="search-container">
                <input
                    type="search"
                    class="search"
                    [(ngModel)]="searchTerm"
                    placeholder="Chercher..."
                    (keyup.enter)="onFiltersChange()"
                />
                <span class="search-icons">
                    <span class="icon-search" (click)="onFiltersChange()"></span>
                    <span
                        class="icon-Fermerdetails"
                        *ngIf="searchTerm"
                        (click)="resetSearch()"
                    ></span>
                </span>
            </div>
            <div class="button-export-csv" (click)="exportCSV()">Exporter en CSV</div>
        </div>
    </div>

    <div
        class="history-component_main"
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="nextPageUsers()"
    >
        <div
            id="users-list"
            *ngIf="structureSelected()"
            [ngClass]="{
                'show-disabled-users': showDisabledUsers
            }"
        >
            <div
                class="user"
                *ngFor="let user of users"
                [ngClass]="{
                    'user-disabled': !user.enabled
                }"
            >
                <!-- GAUCHE -->
                <div class="user-description">
                    <ng-container>
                        <span
                            *ngFor="let role of getUserRoles(user)"
                            [ngClass]="role.icon"
                            [tooltip]="role.name"
                            tooltipPosition="right"
                        ></span>
                    </ng-container>
                    {{ user.lastname.toUpperCase() }}
                    {{ user.firstname }}
                </div>

                <!-- MILIEU -->
                <div class="user-last-access">
                    Dernière connexion le
                    <span class="bold">{{ user.lastAccess | date: "dd/MM/y à HH'h'mm" }}</span>
                </div>

                <!-- DROITE -->
                <div class="user-icons">
                    <div class="dynamic-icons">
                        <span
                            class="icon-FicheUser"
                            *ngIf="!hasIncompleteEvents(user)"
                            (click)="openUserDialog(user)"
                            tooltip="Voir la fiche"
                        ></span>
                        <span
                            class="icon-Suividesconnexions"
                            tooltip="Suivi des connexions"
                            (click)="openUserHistory(user)"
                        ></span>
                    </div>
                    <div class="fix-icons">
                        <span
                            class="icon-FicheUser with-warning"
                            *ngIf="hasIncompleteEvents(user)"
                            (click)="openUserDialog(user)"
                            tooltip="Activation du compte non finalisée : consulter la fiche pour plus d'information"
                        ></span>
                        <span
                            class="icon-DesactiverUser"
                            *ngIf="!user.enabled"
                            tooltip="Utilisateur désactivé"
                        ></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="empty-structure" *ngIf="!structureSelected()">
            <p>Veuillez sélectionner une structure.</p>
            <div>
                <img src="assets/img/library-default.png" />
            </div>
        </div>
    </div>
    <app-loading *ngIf="isLoading('historyNextPage')"></app-loading>
</div>

<app-loading *ngIf="isLoading('historyMainView')"></app-loading>
