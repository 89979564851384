<div id="cgu" [ngClass]="{ 'white-mode': !isDarkTheme() }">
    <div class="button-previous" (click)="goToLogin()" *ngIf="!isValidationMode()">
        <div class="text">
            <span class="icon icon-back"></span>
            Retour
        </div>
    </div>
    <div class="tab-container">
        <h2
            (click)="switchCurrentTab('cgu')"
            [ngClass]="{
                selected: isCurrentTab('cgu')
            }"
        >
            Conditions générales d'utilisation
        </h2>
        <h2
            (click)="switchCurrentTab('ml')"
            [ngClass]="{
                selected: isCurrentTab('ml')
            }"
        >
            Mentions légales
        </h2>
        <h2
            (click)="switchCurrentTab('pdc')"
            [ngClass]="{
                selected: isCurrentTab('pdc')
            }"
        >
            Politique de confidentialité
        </h2>
    </div>
    <div *ngIf="isCurrentTab('cgu')" class="content" id="content_cgu" [innerHTML]="cguHtml"></div>

    <div *ngIf="isCurrentTab('ml')" class="content" [innerHTML]="mlHtml"></div>

    <div *ngIf="isCurrentTab('pdc')" class="content" [innerHTML]="pdcHtml"></div>

    <div class="footer" *ngIf="isValidationMode() && isCurrentTab('cgu')">
        <div class="button-refuse" (click)="refuseCGU()">
            <div class="text">Refuser</div>
        </div>

        <div class="button-accept" (click)="acceptCGU()">
            <div class="text">J'ai lu et j'accepte les conditions générales d'utilisation</div>
        </div>
    </div>
    <div class="space-bottom"></div>
</div>
