import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ConfigService } from '../../../services/config.service';
import { DialogService } from '../../../services/dialog.service';
import { LoginService } from '../../../services/login.service';
import { UsersService } from '../../../services/users.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-update-email',
    templateUrl: './update-email.component.html',
    styleUrls: ['./update-email.component.scss']
})
export class UpdateEmailComponent implements OnInit, OnDestroy {
    constructor(
        private formBuilder: UntypedFormBuilder,
        private configService: ConfigService,
        private dialogService: DialogService,
        private loginService: LoginService,
        private usersService: UsersService,
        private router: Router,
        private cookieService: CookieService
    ) {}

    subscriptions: Subscription = new Subscription();

    setEmailForm: UntypedFormGroup;
    errorMessage: string;
    successMessage: string;

    userid: number;

    ngOnInit() {
        this.userid = this.loginService.getUser().id;
        this.setEmailForm = this.formBuilder.group({
            email: ['', Validators.required],
            confirmation_email: ['', Validators.required]
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    isDarkTheme() {
        return this.cookieService.get('dark') === 'true';
    }

    getHeaderText() {
        return this.configService.getConfig().header_text;
    }

    getImageTop() {
        return this.configService.getConfig().left_image_top_dev;
    }

    getImageBottom() {
        return this.configService.getConfig().left_image_bot;
    }

    getVersion() {
        return this.configService.getConfig().version;
    }

    getRightSidePanelBackground(): any {
        return {
            'background-image': `url(${this.configService.getConfig().right_image})`
        };
    }

    updateEmail(): void {
        if (this.setEmailForm.value.email === this.setEmailForm.value.confirmation_email) {
            this.subscriptions.add(
                this.usersService.updateEmail(this.userid, this.setEmailForm.value.email).subscribe(
                    (data: any) => {
                        this.successMessage =
                            "Un email contenant le lien d'activation vient de vous être envoyé. Vous allez être déconnecté dans 5 secondes.";
                        setTimeout(() => {
                            this.loginService.clearExceptionAndNavigate();
                        }, 5000);
                    },
                    (error: HttpErrorResponse) => {
                        this.errorMessage = error.error.userMessage;
                    }
                )
            );
        } else {
            this.errorMessage = 'Les adresses email que vous avez rentrées ne sont pas identiques.';
        }
    }

    goToCGU() {
        this.router.navigate(['cgu']);
    }

    openDiagnostic() {
        this.dialogService.openDiagnostic(false);
    }

    getAnchoredLogoURL() {
        return this.configService.getConfig().anchored_logo_url;
    }
}
