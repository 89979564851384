<div *ngIf="!isLoading()">
    <b>{{ getPublishedNewsNumber() }}</b> news publiées
    <div class="news-container">
        <mat-expansion-panel class="news" *ngFor="let news of newsList">
            <mat-expansion-panel-header
                class="news-header"
                [collapsedHeight]="'50px'"
                [expandedHeight]="'50px'"
                #actualClickedNews
            >
                <div class="icon-News"></div>
                <div class="author">
                    {{ news.author_fullname }}
                </div>

                <div class="summary-news">
                    {{ news.title }}
                    <span class="structures-list" *ngIf="isNationalAdmin()">{{
                        displayNewsStructures(news)
                    }}</span>
                </div>

                <div class="date">
                    {{ getNewsDate(news) }}
                </div>
                <div class="icons">
                    <div
                        class="icon-Editer"
                        (click)="editNews($event, news, actualClickedNews._isExpanded())"
                    ></div>
                    <div class="icon-bin" (click)="deleteNews($event, news)"></div>
                </div>
            </mat-expansion-panel-header>
            <div class="news-content">
                <p>
                    <b>{{ news.title }}</b>
                </p>
                <br />

                <p [innerHTML]="news.description"></p>

                <div *ngIf="news.media">
                    <img [src]="news.media" />
                </div>
            </div>
        </mat-expansion-panel>
    </div>

    <div class="button-add-news" (click)="addNews()" [tooltip]="'Créer une nouvelle news'">
        <span class="icon-plus"></span>
    </div>
</div>

<app-loading *ngIf="isLoading()"></app-loading>
