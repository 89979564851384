<div class="login-screen" [ngClass]="{ 'white-mode': !isDarkTheme() }">
    <div class="left-panel">
        <div class="login-header-container">
            <div class="header-welcome-text">
                {{ getHeaderText() }}
            </div>
            <div class="logo-container">
                <img [src]="getImageTop()" alt="logo-easi" class="logo-easi" />
            </div>
            @if (showPlatformWarning()) {
                <div class="warning-pilot">CECI EST LA PLATEFORME DE DÉVELOPPEMENT EASi</div>
            }
        </div>
        <div class="login-form-container">
            <form class="form" [formGroup]="loginForm" (ngSubmit)="checkLogin()" autocomplete="off">
                <input
                    type="text"
                    class="login input"
                    placeholder="Identifiant ou adresse email"
                    autocomplete="off"
                    required
                    id="username"
                    formControlName="username"
                />

                <div class="password-container">
                    <input
                        type="{{ getPasswordInputType() }}"
                        class="password input"
                        id="password"
                        placeholder="Mot de passe"
                        formControlName="password"
                        autocomplete="off"
                        required
                    />
                    @if (canViewPassword()) {
                        <span
                            class="icon-voir"
                            (mouseup)="togglePasswordPreview()"
                            (mousedown)="togglePasswordPreview()"
                            (touchstart)="togglePasswordPreview()"
                            (touchend)="togglePasswordPreview()"
                        ></span>
                    }

                    <span
                        class="icon-Aide"
                        tooltip="Il peut s'agir de votre mot de passe<br/> ou d'un mot de passe temporaire."
                        tooltipColor="#199fe2"
                        tooltipPosition="left"
                    >
                    </span>
                    <div class="forgot-password">
                        <span class="text" (click)="resendPassword()">Mot de passe oublié ?</span>
                    </div>
                </div>

                @if (showStructuresList()) {
                    <lib-uimm-dropdown
                        [(items)]="structures"
                        (itemsChange)="checkStructureSelected()"
                        [label]="'Sélectionnez une structure...'"
                        [headers]="{
                            search: true
                        }"
                        [labelMinWidth]="'100%'"
                        [uniformWidth]="true"
                        [labelColor]="isDarkTheme() ? '#f1f2f4' : '#045f7d'"
                    ></lib-uimm-dropdown>
                }

                <div class="login-button-container">
                    <button style="display: none" type="submit"></button>
                    <button class="login-button" type="button" (click)="checkLogin()">
                        Connexion
                        @if (showLoginWarning()) {
                            <div
                                class="icon-Warning"
                                [tooltipPosition]="'below'"
                                [tooltip]="
                                    'Votre configuration n\'est pas recommandée pour l\'utilisation de la plateforme easi.<br>Veuillez consulter la page de diagnostic de connexion en cliquant sur le lien en bas de votre page'
                                "
                            ></div>
                        }
                    </button>
                </div>
            </form>
            <div class="message-container">
                @if (errorMessage) {
                    <div class="error-message-container">
                        <div class="icon-Warning"></div>
                        <div class="error-message-value" [innerHTML]="errorMessage"></div>
                    </div>
                }
                @if (!errorMessage && getLoginInfoMessage()) {
                    <div class="orange-message-container" [innerHTML]="getLoginInfoMessage()"></div>
                }
            </div>
            <div class="other-login-container">
                @if (RPList.length) {
                    <div class="legend">Utiliser un autre système d'authentification</div>
                    @for (rp of RPList; track rp.id) {
                        <div class="login-button-container">
                            <div class="login-button">
                                <div class="login-link" (click)="redirectForRP(rp)">
                                    <img class="login-image" [src]="rp.logo" />
                                </div>
                            </div>
                        </div>
                    }
                }
                @if (isAzureEnabled()) {
                    <div class="login-button-container">
                        <div class="login-button azure">
                            <div class="login-link" (click)="openAzureModal()">
                                <img class="login-image" [src]="getMSLogo()" />
                                <div class="login-text">Se connecter avec Microsoft</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>

        <div class="login-footer-container">
            @if (!RPList.length) {
                <div class="uimm-logo-container">
                    <img [src]="getImageBottom()" alt="logo uimm" class="logo-uimm" />
                </div>
            }
            <div class="footer-links">
                <span class="link" (click)="openDiagnostic()">Diagnostic de connexion</span>
                <span class="separator">|</span>
                <span class="link" (click)="goToCGU()">Informations légales</span>
                <span class="separator">|</span>
                <span class="version">{{ getVersion() }}</span>
            </div>
        </div>
    </div>

    <div class="right-panel" [ngStyle]="getRightSidePanelBackground()">
        <img class="anchored-logo" [src]="getAnchoredLogoURL()" />
    </div>
</div>
