<div class="resend-password-screen" [ngClass]="{ 'white-mode': !isDarkTheme() }">
    <div class="left-panel">
        <div class="resend-password-header-container">
            <div class="back-to-login" (click)="goToLogin()">
                <span class="icon-back"></span>
            </div>
            <div class="header-welcome-text">Mot de passe oublié</div>
            <div class="logo-container">
                <span class="question">?</span>
            </div>
        </div>
        <div class="resend-password-form-container">
            <form class="form" [formGroup]="resendPasswordForm" autocomplete="off">
                <input
                    type="text"
                    class="resend-password input"
                    placeholder="Identifiant ou adresse email"
                    autocomplete="off"
                    required
                    id="username"
                    formControlName="username"
                />

                <lib-uimm-dropdown
                    [(items)]="structures"
                    [label]="'Sélectionnez une structure...'"
                    [headers]="{
                        search: true
                    }"
                    [uniformWidth]="true"
                    [labelColor]="isDarkTheme() ? '#f1f2f4' : '#045f7d'"
                    *ngIf="showDropdownStructure()"
                ></lib-uimm-dropdown>

                <div class="resend-password-button-container">
                    <button
                        class="resend-password-submit"
                        type="button"
                        class="resend-password-button"
                        (click)="checkResendPassword()"
                    >
                        Valider
                    </button>
                </div>
            </form>
            <div class="message-container">
                <div class="error-message-container" *ngIf="errorMessage">
                    <div class="icon icon-Warning"></div>
                    <div class="error-message-value">
                        {{ errorMessage }}
                    </div>
                </div>
                <div class="success-message-container" *ngIf="successMessage">
                    <div class="icon icon-Valider"></div>
                    <div class="success-message-value">
                        {{ successMessage }}
                    </div>
                </div>
                <div class="information-message-container" *ngIf="!successMessage && !errorMessage">
                    <div class="information-message-value" [innerHtml]="informationMessage"></div>
                </div>
            </div>
        </div>
        <div class="resend-password-footer-container">
            <div class="uimm-logo-container">
                <img [src]="getImageBottom()" alt="logo uimm" class="logo-uimm" />
            </div>
            <div class="footer-links">
                <span class="link" (click)="openDiagnostic()">Diagnostic de connexion</span>
                <span class="separator">|</span>
                <span class="link" (click)="goToCGU()">Informations légales</span>
                <span class="separator">|</span>
                <span class="version">{{ getVersion() }}</span>
            </div>
        </div>
    </div>
    <div class="right-panel" [ngStyle]="getRightSidePanelBackground()">
        <img class="anchored-logo" [src]="getAnchoredLogoURL()" />
    </div>
</div>
