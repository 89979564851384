<div id="confirmation-component">
    <div class="header">
        <div class="title" [innerHTML]="getTitle()"></div>
    </div>
    <mat-dialog-content class="content" [innerHTML]="getMainMessage()"> </mat-dialog-content>
    <div class="footer">
        <div class="confirm button" (click)="action()">{{ getYesButtonValue() }}</div>
        <div class="cancel button" (click)="closeDialog()">{{ getNoButtonValue() }}</div>
    </div>
</div>
