import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActivityReportService } from 'src/app/services/activity-report.service';

import * as FileSaver from 'file-saver';
import { FlashMessageService } from 'src/app/services/flash-message.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-queued-report',
    templateUrl: './queued-report.component.html',
    styleUrls: ['./queued-report.component.scss']
})
export class QueuedReportComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private activityReportService: ActivityReportService,
        private flashMessageService: FlashMessageService
    ) {}

    id: number;
    status$: Observable<'created' | 'processing' | 'succeeded'>;
    readyToDownload = false;
    isInProgress;

    ngOnInit(): void {
        this.id = Number(this.route.snapshot.paramMap.get('id'));
        if (!isNaN(this.id)) {
            this.status$ = this.activityReportService.getPDFReportTaskStatus(this.id);
        } else {
            console.error('Routing failure, you should not be here');
        }
    }

    downloadReport(): any {
        this.flashMessageService.flash('Votre téléchargement est en cours, veuillez patienter...');
        this.activityReportService.downloadZIPMultiReport(this.id).subscribe((zipFile: any) => {
            const blob = new Blob([zipFile.body], {
                type: 'application/octet-stream'
            });
            FileSaver.saveAs(
                blob,
                zipFile.headers.get('Content-Disposition').match(/filename="(.+)"/)[1]
            );

            this.flashMessageService.flash('Le téléchargement du fichier est terminé');
        });
    }
}
