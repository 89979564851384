import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, Subject } from 'rxjs';

import { LoginService } from './login.service';

import { Group } from '../structures/group';
import { User } from '../structures/user';
import { log } from './decorators/log.decorator';

@Injectable({
    providedIn: 'root'
})
export class GroupsService {
    constructor(private http: HttpClient, private loginService: LoginService) {}

    clonedRoleGroup: Subject<Group> = new Subject();
    deletedRoleGroup: Subject<number> = new Subject();
    editedRoleGroup: Subject<void> = new Subject();

    @log() createGroup(group: Group): Observable<Group> {
        return this.http.post<Group>(`/groups`, group);
    }

    @log() updateGroup(group: Group): Observable<Group> {
        return this.http.put<Group>(`/groups/${group.id}`, group);
    }

    @log() getGroups(params): Observable<Group[]> {
        return this.http.get<Group[]>('/groups', { params });
    }

    @log() getGroupsCount(params): Observable<{ count: number }> {
        return this.http.get<{
            count: number;
        }>('/groups', { params: { count: true, ...params } });
    }

    @log() getGroup(groupId: number): Observable<Group> {
        const params = {
            events: 'true'
        };

        return this.http.get<Group>(`/groups/${groupId}`, { params });
    }

    @log() addUserToGroup(userId: number, groupId: number): Observable<any> {
        return this.http.post(`/groups/${groupId}/user/${userId}`, {});
    }

    @log() addUsersToGroup(usersList: User[], groupId: number): Observable<any[]> {
        const requests = usersList.map((user) => this.addUserToGroup(user.id, groupId));
        return forkJoin(requests);
    }

    @log() getUnusedGroups(): Observable<any> {
        return this.http.get(
            `/structures/${this.loginService.getUser().structureid}/groups/unused`
        );
    }

    @log() deleteAllUnusedGroups(): Observable<any> {
        return this.http.delete(
            `/structures/${this.loginService.getUser().structureid}/groups/unused`
        );
    }

    @log() deleteUnusedGroup(id: number): Observable<any> {
        return this.http.delete(`/groups/${id}`);
    }

    @log() getRoleGroups(params): Observable<Group[]> {
        return this.http.get<Group[]>('/rolegroup/getall', { params });
    }

    @log() createRoleGroup(roleGroup: Group): Observable<Group> {
        return this.http.post<Group>(`/rolegroup/create`, roleGroup);
    }

    @log() updateRoleGroup(roleGroup: Group): Observable<Group> {
        return this.http.put<Group>(`/rolegroup/update/${roleGroup.id}`, roleGroup);
    }

    @log() cloneRoleGroup(roleGroupId: number): Observable<Group> {
        return this.http.post<Group>(`/rolegroup/clone/${roleGroupId}`, {});
    }

    @log() deleteRoleGroup(roleGroupId: number): Observable<Group> {
        return this.http.delete<Group>(`/rolegroup/delete/${roleGroupId}`);
    }

    @log() sendEmailReminder(groupId: number) {
        const body = {
            groupid: groupId
        };
        return this.http.post<boolean>(`/groups/send_mails_to_group`, body);
    }

    emitClonedRoleGroup(roleGroup) {
        this.clonedRoleGroup.next(roleGroup);
    }

    emitDeletedRoleGroup(roleGroupId) {
        this.deletedRoleGroup.next(roleGroupId);
    }

    emitEditedRoleGroup() {
        this.editedRoleGroup.next();
    }
}
