<div id="dashboard-component">
    <div class="dashboard-component_header">
        <lib-uimm-dropdown
            [label]="'Structure'"
            [labelWidth]="'220px'"
            [(items)]="structures"
            (itemsChange)="onFiltersChange()"
            [headers]="{
                search: true,
                selectAll: false,
                count: false
            }"
            *ngIf="isNationalAdmin()"
        ></lib-uimm-dropdown>

        <app-dates-selector
            [(dateStart)]="startDate"
            (dateStartChange)="onFiltersChange()"
            [maxDateStart]="endDate"
            [(dateEnd)]="endDate"
            (dateEndChange)="onFiltersChange()"
            [maxDateEnd]="yesterday"
        ></app-dates-selector>

        <a [href]="iframePDFURL" *ngIf="structureSelected() && iframePDFURL">
            <div id="button-pdf" class="button">Télécharger le PDF</div>
        </a>
    </div>
    <div class="dashboard-component_body">
        <iframe
            *ngIf="structureSelected() && iframeURL"
            id="nat-stat-container"
            [src]="iframeURL"
        ></iframe>
    </div>
</div>
