<div class="graphs-dashboard-container">
    <div class="graphs-dashboard-container_title">
        <lib-uimm-dropdown
            [label]="'Choix du graphique'"
            [labelWidth]="'300px'"
            [(items)]="graphTypes"
            (itemsChange)="onGraphTypeChange($event)"
            [uniformWidth]="true"
        ></lib-uimm-dropdown>
    </div>
    <div class="graphs-dashbord-container_selectors">
        <div class="sites-selector">
            <lib-uimm-dropdown
                [label]="!isStructureSelected() ? 'Toutes les structures' : 'Structures'"
                [labelWidth]="'180px'"
                [(items)]="structures"
                (itemsChange)="onStructureChange($event)"
                [multiselect]="true"
                [headers]="{
                    search: true,
                    selectAll: true,
                    count: true
                }"
                *ngIf="showStructuresDropDown()"
            ></lib-uimm-dropdown>
            <lib-uimm-dropdown
                [label]="isSiteSelected() ? 'Sites' : 'Tous les sites'"
                [labelWidth]="'150px'"
                [(items)]="sites"
                [headers]="{
                    search: true,
                    selectAll: true,
                    count: true
                }"
                [multiselect]="true"
                [disabled]="structuresSelectedArray().length !== 1"
                (itemsChange)="onUpdateParameters()"
            ></lib-uimm-dropdown>
            <lib-uimm-dropdown
                *ngIf="
                    graphTypeSelected?.key === 'activities' ||
                    graphTypeSelected?.key === 'assignations'
                "
                [label]="'Domaines'"
                [labelWidth]="'120px'"
                [(items)]="domains"
                [headers]="{
                    search: true,
                    selectAll: true,
                    count: true
                }"
                [multiselect]="true"
                (itemsChange)="onUpdateParameters()"
            ></lib-uimm-dropdown>
            <lib-uimm-dropdown
                *ngIf="graphTypeSelected?.key === 'connexions'"
                [label]="'Connexions'"
                [labelWidth]="'170px'"
                [(items)]="connexionsTypes"
                (itemsChange)="onConnexionTypesChange()"
            ></lib-uimm-dropdown>
            <lib-uimm-dropdown
                *ngIf="graphTypeSelected?.key === 'activities'"
                [label]="'Types de contenus'"
                [labelWidth]="'225px'"
                [(items)]="structuresTypes"
                (itemsChange)="onUpdateParameters()"
            ></lib-uimm-dropdown>
            <lib-uimm-dropdown
                *ngIf="graphTypeSelected?.key === 'assignations'"
                [label]="'Prix'"
                [labelWidth]="'245px'"
                [(items)]="assignationsPrice"
                (itemsChange)="onUpdateParameters()"
            ></lib-uimm-dropdown>

            <app-dates-selector
                [(dateStart)]="dateStart"
                [(dateEnd)]="dateEnd"
                [minDateStart]="'2016-08-01T20:00:00.000Z'"
                [minDateEnd]="getMinDateEnd()"
                [maxDateStart]="getMaxDateStart()"
                [maxDateEnd]="today"
            ></app-dates-selector>
            <lib-uimm-dropdown
                *ngIf="graphTypeSelected?.key === 'active-users'"
                [label]="'Nombre d\'actions'"
                [labelWidth]="'190px'"
                [(items)]="actionsCount"
                (itemsChange)="onUpdateParameters()"
            ></lib-uimm-dropdown>
            <lib-uimm-dropdown
                *ngIf="
                    graphTypeSelected?.key === 'connexions' ||
                    graphTypeSelected?.key === 'active-users'
                "
                [label]="'Type d\'utilisateur'"
                [labelWidth]="'160px'"
                [(items)]="userTypes"
                (itemsChange)="onUpdateParameters()"
                [headers]="{
                    selectAll: true,
                    count: true
                }"
                [multiselect]="true"
            ></lib-uimm-dropdown>
            <lib-uimm-dropdown
                *ngIf="graphTypeSelected?.key === 'activities'"
                [label]="'Indicateurs d\'activités'"
                [labelWidth]="'220px'"
                [(items)]="activityIndicators"
                (itemsChange)="onUpdateParameters()"
            ></lib-uimm-dropdown>
        </div>
    </div>
</div>
