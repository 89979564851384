// Internal dependencies
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

// External dependencies

// Modules
import { SharedModule } from '@/modules/shared.module';

// Components

// Services

// Interfaces
import { PreviewImportYpareo } from '@/structures/preview-import-ypareo';

// Pipes

@Component({
    selector: 'app-preview-import-ypareo',
    templateUrl: './preview-import-ypareo.component.html',
    styleUrls: ['./preview-import-ypareo.component.scss'],
    imports: [SharedModule, MatButtonModule, MatIconModule]
})
export class PreviewImportYpareoComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public previewImport: PreviewImportYpareo,
        public dialogRef: MatDialogRef<PreviewImportYpareoComponent>
    ) {}

    ngOnInit() {}

    // #region Getters

    getGroupsNames(): string {
        return this.previewImport.groupnames_to_create.join(' ; ');
    }

    getSitesNames(): string {
        return this.previewImport.sitenames_to_create.join(' ; ');
    }

    // #endregion Getters

    // #region Handlers

    handleCloseDialog(confirmed: boolean): void {
        this.dialogRef.close(confirmed);
    }

    // #endregion Handlers

    // #region Internals

    // #endregion Internals
}
