import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';

import { browserName, fullBrowserVersion, browserVersion } from 'mobile-device-detect';

@Injectable({
    providedIn: 'root'
})
export class DiagnosticService {
    browserName: string;
    popupBlocked: string;
    cookieEnabled: string;

    constructor(private configService: ConfigService) {
        this.browserName = browserName + ' ' + browserVersion;
        this.cookieEnabled = navigator.cookieEnabled ? 'OUI' : 'NON';
        let result = false;
        if (this.configService.getConfig().showLoginWarning) {
            const newWin = window.open('about:blank', '', 'height=100,width=100');
            result = !(!newWin || newWin.closed || typeof newWin.closed === 'undefined');

            if (result) {
                newWin.close();
                this.popupBlocked = 'NON';
            } else {
                this.popupBlocked = 'OUI';
            }
        } else {
            this.popupBlocked = 'NON';
        }
    }

    getBrowserName(): string {
        return this.browserName;
    }

    getPopupBlocked(): string {
        return this.popupBlocked;
    }

    getCookieEnabled(): string {
        return this.cookieEnabled;
    }

    isBrowserOK(): boolean {
        switch (browserName.toLowerCase()) {
            case 'chrome':
                return +fullBrowserVersion >= 22;
            case 'firefox':
                return +fullBrowserVersion >= 11;
            case 'safari':
                return +fullBrowserVersion >= 5;
            case 'internet explorer':
                return +fullBrowserVersion >= 11;
            case 'opera':
                return +fullBrowserVersion >= 36;
            case 'edge':
                return true;
            default:
                return false;
        }
    }

    isPopupOK(): boolean {
        return this.popupBlocked === 'NON';
    }

    isCookieOK(): boolean {
        return this.cookieEnabled === 'OUI';
    }
}
