import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { FlashMessageService } from 'src/app/services/flash-message.service';
import { UsersService } from 'src/app/services/users.service';
import * as FileSaver from 'file-saver';
import { Subject } from 'rxjs';
import { PreviewImportUsersComponent } from './preview-import-users/preview-import-users.component';

@Component({
    selector: 'app-csv-import-users',
    templateUrl: './csv-import-users.component.html',
    styleUrls: ['./csv-import-users.component.scss']
})
export class CsvImportUsersComponent implements OnInit {
    file: File = null;
    @ViewChild('uploadInput', { static: false }) fileInput;

    openError: Subject<any> = new Subject();

    constructor(
        public dialogRef: MatDialogRef<CsvImportUsersComponent>,
        private usersService: UsersService,
        private flashMessageService: FlashMessageService,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        private structureid: number
    ) {}

    ngOnInit(): void {}

    getImportButtonText(): string {
        if (this.file) {
            return `Modifier le fichier : ${this.file.name}`;
        }
        return 'Choisir le CSV à importer';
    }

    downloadReferenceFile(): void {
        const csvContent =
            'ypareo_id;local_structure_id;user_type;title;name;firstname;login;password;email;site;corporation;group;student_status;enabled\nidentifiantypareo;nomstructure;student;M.;nom;prenom;identifiant;;adresse@mail.com;site1|site2;entreprise1|entreprise2;groupe1|groupe2;FI;1\nidentifiantypareo;nomstructure;prospect;Mme;nom;prenom;identifiant;;adresse@mail.com;site1|site2;entreprise1|entreprise2;groupe1|groupe2;FC;1\nidentifiantypareo;nomstructure;teacher|accountManager|contentManager|validator;M.;nom;prenom;identifiant;;adresse@mail.com;site1|site2;entreprise1|entreprise2;;;1\nidentifiantypareo;nomstructure;corporationTeacher;Mme;nom;prenom;identifiant;;adresse@mail.com;site1|site2;entreprise1|entreprise2;;;1\nidentifiantypareo;nomstructure;externalTeacher;M.;nom;prenom;identifiant;;adresse@mail.com;site1|site2;entreprise1|entreprise2;;;1\nidentifiantypareo;nomstructure;tutor;Mme;nom;prenom;identifiant;;adresse@mail.com;;;;;1\n';
        const blob = new Blob(['\ufeff', csvContent], {
            type: 'text/plain;charset=UTF-8;'
        });
        FileSaver.saveAs(blob, 'users_reference.csv');
    }

    fileChangeEvent(event: any): void {
        this.file = event.target.files[0];
    }

    previewImport(): void {
        if (!this.file) {
            return;
        }
        this.openImportCSVPreview(this.file).subscribe((confirmation: boolean) => {
            if (confirmation) {
                this.usersService.importCSV(this.structureid, this.file).subscribe(
                    () => {
                        this.flashMessageService.flash('Le fichier a été importé');
                        this.dialogRef.close(true);
                    },
                    (error: HttpErrorResponse) => {
                        if (!error.error.developperMessage) {
                            this.openError.next(error.error.userMessage);
                        } else {
                            this.openError.next(
                                `<strong>Import annulé :</strong><br /><br />${JSON.parse(
                                    error.error.developperMessage
                                ).join('<br />')}`
                            );
                        }
                    }
                );
            } else {
                this.file = undefined;
                (this.fileInput.nativeElement as HTMLInputElement).value = '';
            }
        });
    }

    openImportCSVPreview(file: File) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '80vw';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = file;

        const ref: MatDialogRef<PreviewImportUsersComponent> = this.dialog.open(
            PreviewImportUsersComponent,
            dialogConfig
        );

        return ref.afterClosed();
    }

    closeDialog(): void {
        this.dialogRef.close(false);
    }
}
