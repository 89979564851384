<app-graphs-dashboard
    [graphTypes]="graphTypes"
    [graphTypeSelected]="graphTypeSelected"
    (graphTypeSelectedChange)="onGraphTypeSelectedChange($event)"
></app-graphs-dashboard>
<div class="main-container">
    <div class="graph-container" [ngSwitch]="graphTypeSelected?.key">
        <app-graphs-connexions *ngSwitchCase="'connexions'"></app-graphs-connexions>
        <app-graphs-top-activities *ngSwitchCase="'activities'"></app-graphs-top-activities>
        <app-graphs-assignations *ngSwitchCase="'assignations'"></app-graphs-assignations>
        <app-graphs-active-users *ngSwitchCase="'active-users'"></app-graphs-active-users>
        <div *ngSwithcDefault>default</div>
        <app-loading *ngIf="graphTypeSelected && (isLoading$ | async)"></app-loading>
    </div>
    <!-- <div class="graph-container">
    </div> -->
    <div class="exports-container">
        <div class="export-button" (click)="exportToCSV()" *ngIf="graphTypeSelected">
            Exporter en CSV
        </div>
        <div class="export-button" (click)="exportToPdf()" *ngIf="graphTypeSelected">
            Exporter en PDF
        </div>
    </div>
</div>
