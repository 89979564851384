<div id="rights-exceptions">
    <div class="rights-exceptions_header">
        <span class="title">Exceptions concernant les accès à {{ data.application.name }}</span>
        <span class="icon-close" (click)="closeDialog()"></span>
    </div>

    <div class="rights-exceptions_body">
        <div class="count-and-search">
            <div class="count">
                <strong>{{ usersList.length }}</strong>
                exceptions
            </div>
            <div class="search-container">
                <input
                    type="search"
                    class="search"
                    [(ngModel)]="searchTerm"
                    placeholder="Chercher..."
                />
                <span class="search-icons">
                    <span class="icon-search"></span>
                    <span
                        class="icon-Fermerdetails"
                        *ngIf="searchTerm"
                        (click)="searchTerm = ''"
                    ></span>
                </span>
            </div>
        </div>
        <div class="exceptions-list">
            <div
                class="user"
                *ngFor="let user of usersList | search: searchTerm:'firstname':'lastname'"
            >
                <div class="name">
                    <span [ngClass]="getUserIcon(user)"></span>
                    {{ user.lastname.toUpperCase() }} {{ user.firstname }}
                </div>
                <div class="exception-text">
                    <em
                        >Accès
                        <strong>{{ user.allow ? 'autorisé' : 'refusé' }}</strong>
                        (forcé par l'administrateur)</em
                    >
                </div>
                <div class="icon-FicheUser" (click)="openUserDialog(user)"></div>
            </div>
        </div>
    </div>

    <div class="rights-exceptions_footer">
        <div class="button-valider" (click)="closeDialog()">Valider</div>
    </div>
</div>
