import {
    Component,
    OnInit,
    AfterViewInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    HostListener,
    QueryList,
    ViewChildren,
    ViewEncapsulation,
    Renderer2,
    AfterContentChecked
} from '@angular/core';

import { ListKeyManager } from '@angular/cdk/a11y';
import { UP_ARROW, DOWN_ARROW, ENTER, ESCAPE } from '@angular/cdk/keycodes';

import { ElementComponent } from './element/element.component';
import { slideToggle } from './slideToggle.animation';

@Component({
    selector: 'lib-uimm-dropdown',
    templateUrl: './uimm-dropdown.component.html',
    styleUrls: ['./uimm-dropdown.component.scss'],
    animations: [slideToggle],
    encapsulation: ViewEncapsulation.Emulated
})
export class DropdownComponent implements OnInit, AfterViewInit, AfterContentChecked {
    keyboardEventsManager!: ListKeyManager<any>;
    filters!: Array<any>;
    @ViewChildren(ElementComponent) listItems!: QueryList<ElementComponent>;

    @ViewChild('search') searchElement!: ElementRef;
    @ViewChild('dropdownContent') dropdownContentContainer!: ElementRef;

    @Input() disabled = false;
    @Input() multiselect = false;
    @Input() customClass = '';
    @Input() design!: string;

    @Input() label!: string;
    @Input() labelWidth!: string;
    @Input() labelMinWidth!: string;
    @Input() labelColor!: string;
    @Input() labelDefaultColor!: string;

    @Input() maxWidth!: number;
    @Input() maxHeight!: number;
    @Input() uniformWidth!: boolean;

    @Input() headers!: {
        search: false;
        selectAll: false;
        count: false;
    };
    @Input() set items(val: Array<any>) {
        this.filters = val;
        this.refreshFilterSearch();
    }
    @Output() itemsChange: EventEmitter<Array<any>> = new EventEmitter();

    searchTerm = '';
    searchChanged!: boolean;
    filteredItems!: Array<any>; // le tableau contenant les élément affichés dans le cas d'une recherche
    style!: any;
    opened!: boolean;
    customClassUpdated = '';

    constructor(private element: ElementRef, private renderer: Renderer2) {}

    @HostListener('document:click', ['$event']) clickout(event: Event) {
        if (!this.element.nativeElement.contains(event.target)) {
            if (this.opened) {
                this.opened = false;
                // $(this.element.nativeElement).children('.dropdown-content').slideUp(300);
            }
        }
    }

    ngOnInit() {
        if (!this.design) {
            this.design = 'default';
        }
        if (!this.labelMinWidth) {
            this.labelMinWidth = '150px';
        }
        if (!this.maxWidth) {
            this.maxWidth = 600;
        }
        if (!this.maxHeight) {
            this.maxHeight = 300;
        }
        this.searchTerm = '';
        this.searchChanged = false;
        this.refreshFilterSearch();
    }

    ngAfterViewInit() {
        this.keyboardEventsManager = new ListKeyManager<any>(this.listItems);
        this.keyboardEventsManager.change.subscribe((activeIndex) => {
            this.listItems.map((item, index) => {
                item.setActive(activeIndex === index);
                return item;
            });
        });
    }

    ngAfterContentChecked() {
        if (this.searchChanged && this.listItems) {
            this.listItems.map((item, index) => {
                item.setActive(0 === index);
                return item;
            });
            this.searchChanged = false;
        }
    }

    // ********GETTERS *********** /

    /**
     * Retourne le titre qui doit apparaître dans le false select
     */
    getLabelText(): string {
        if (!this.multiselect && this.getSelectionCount() === 1) {
            return this.filters.filter((item) => item.selected === true)[0].title;
        } else {
            return this.label;
        }
    }

    getLabelIcon(): string {
        if (!this.multiselect && this.getSelectionCount() === 1) {
            return this.filters.filter((item) => item.selected === true)[0].icon;
        } else {
            return '';
        }
    }

    getLabelColor(): string {
        if (this.getSelectionCount() === 0) {
            return this.labelDefaultColor;
        }
        if (!this.multiselect && this.getSelectionCount() === 1) {
            return this.filters.filter((item) => item.selected === true)[0].color;
        } else {
            return '';
        }
    }

    /**
     * Retourne le nombre d'éléments sélectionnés
     */
    getSelectionCount(): number {
        if (this.filters) {
            return this.filters.filter((item) => item.selected === true).length;
        }
        return 0;
    }

    // renvoie le nombre d'items, à l'exception des séparateurs
    getTotalItemCount(): number {
        if (this.filters) {
            return this.filters.filter((item) => !item.separator).length;
        }
        return 0;
    }

    // renvoie le nombre d'items, à l'exception des séparateurs et des items désactivés
    getTotalItemNonDisabledCount(): number {
        if (this.filters) {
            return this.filters.filter((item) => !item.separator && !item.disabled).length;
        }
        return 0;
    }

    isSelected(item: any): boolean {
        return item.selected ? item.selected : false;
    }

    isAllSelected(): boolean {
        return this.getSelectionCount() === this.getTotalItemNonDisabledCount();
    }

    itemSelectedInSingleselect(clickedItem: any): void {
        const isSelectedItem =
            this.filters.filter((item) => {
                return item.selected && item.key === clickedItem.key;
            }).length > 0;
        this.filters.filter((item) => !item.separator).forEach((element) => (element.selected = false));
        if (!isSelectedItem) {
            this.filters = this.filters.map((item) => {
                if (item.key === clickedItem.key) {
                    item.selected = true;
                }
                return item;
            });
        }
        this.itemsChange.emit(this.filters);
        this.closeDropdown();
    }

    /**
     * Sélectionne l'élément cliqué
     * @param clickedItem L'élement cliqué
     */
    itemSelectedInMultiselect(clickedItem: any): void {
        this.filters = this.filters.map((item) => {
            if (item.key === clickedItem.key) {
                item.selected = !item.selected;
            }
            return item;
        });
        this.itemsChange.emit(this.filters);
    }

    selectAll(): void {
        this.filters
            .filter((item) => !item.separator && !item.disabled)
            .forEach((element) => (element.selected = true));
        this.itemsChange.emit(this.filters);
    }

    unselectAll(): void {
        this.filters.filter((item) => !item.separator).forEach((element) => (element.selected = false));
        this.itemsChange.emit(this.filters);
    }
    // ********INTERFACE *********** /

    resetSearchTerm($event: Event) {
        $event.stopImmediatePropagation();
        this.searchTerm = '';
        this.refreshFilterSearch();
    }
    /**
     * toggles on/off the display of the modal for selecting tests
     */
    toggleDropdown($event: any): void {
        $event.stopImmediatePropagation();
        $event.preventDefault();
        if (this.opened) {
            this.closeDropdown();
        } else {
            this.openDropdown();
        }
    }
    handleFocusEvent($event: Event): void {
        $event.stopImmediatePropagation();
        this.openDropdown();
    }

    openDropdown() {
        if (this.customClass === 'right') {
            if (
                this.element.nativeElement.getBoundingClientRect().left <
                this.element.nativeElement.getBoundingClientRect().width
            ) {
                this.customClassUpdated = '';
            } else {
                this.customClassUpdated = 'dropdown-content__right-0';
            }
        }
        if (!this.disabled) {
            this.opened = true;
            if (this.searchElement) {
                setTimeout(() => {
                    this.searchElement.nativeElement.focus();
                });
            }
            if (this.opened) {
                this.renderer.setStyle(this.dropdownContentContainer.nativeElement, 'right', 'auto');
                setTimeout(() => {
                    const el: DOMRect = this.dropdownContentContainer.nativeElement.getBoundingClientRect();
                    if (el.left + el.width > window.innerWidth) {
                        this.renderer.setStyle(
                            this.dropdownContentContainer.nativeElement,
                            'transform',
                            `translateX(-${el.left + el.width - window.innerWidth}px)`
                        );
                    }
                });
            }
        }
    }

    handleBlur() {
        setTimeout(() => {
            if (!this.element.nativeElement.contains(document.activeElement)) {
                this.closeDropdown();
            }
        });
    }

    closeDropdown() {
        if (!this.disabled) {
            this.opened = false;
            // setTimeout(() => {
            //     // $(this.element.nativeElement).children('.dropdown-content').slideUp(300);
            // }, 0);
        }
    }

    /**
     * refreshes the list of items to be displayed by the modal according to the search input value
     */
    refreshFilterSearch(): void {
        if (this.searchTerm === '') {
            this.filteredItems = this.filters;
        } else {
            this.filteredItems = this.filters
                .filter((item) => !item.separator)
                .filter((element) => element.title.toLowerCase().includes(this.searchTerm.toLowerCase()));
        }
        this.searchChanged = true;
    }

    handleKeyUp(event: KeyboardEvent) {
        event.stopImmediatePropagation();
        if (this.keyboardEventsManager) {
            if (event.keyCode === DOWN_ARROW || event.keyCode === UP_ARROW) {
                this.keyboardEventsManager.onKeydown(event);
                return false;
            } else if (event.keyCode === ENTER) {
                this.listItems.filter((item) => item.active)[0].setActive(true);
                this.listItems.filter((item) => item.active)[0].selectItem();
                return false;
            } else if (event.keyCode === ESCAPE) {
                this.closeDropdown();
                return false;
            } else {
                return false;
            }
        }
        return false;
    }
}
