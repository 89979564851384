import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'lib-element',
    templateUrl: './element.component.html',
    styleUrls: ['./element.component.scss']
})
export class ElementComponent implements OnInit {
    @Input() item!: any;
    @Input() design!: string;
    @Input() maxWidth!: number;
    @Input() multiselect!: boolean;
    @Output() itemSelectedInMultiselect: EventEmitter<any> = new EventEmitter();
    @Output() itemSelectedInSingleselect: EventEmitter<any> = new EventEmitter();

    active!: boolean;

    constructor() {}

    ngOnInit() {
        if (!this.design) {
            this.design = 'default';
        }
        if (!this.maxWidth) {
            this.maxWidth = 500;
        }
        if (this.item.selected && this.item.disabled) {
            this.item.selected = false;
        }
    }

    isSelected(): boolean {
        return this.item.selected;
    }

    isActive() {
        return this.active;
    }

    isDisabled(): boolean {
        return this.item.disabled;
    }

    setActive(val: boolean) {
        this.active = val;
    }

    /**
     * Sélectionne l'élément cliqué
     * @param clickedItem L'élement cliqué
     */
    selectItem(): void {
        if (!this.item.disabled) {
            if (!this.multiselect) {
                this.itemSelectedInSingleselect.emit(this.item);
            } else {
                this.itemSelectedInMultiselect.emit(this.item);
            }
        }
    }
}
