// Internal dependencies
import { Component, OnInit, OnChanges, input, model } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

// External dependencies

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services
import { YpareoService } from '@/services/ypareo.service';
import { LoginService } from '@/services/login.service';
import { FlashMessageService } from '@/services/flash-message.service';

// Interfaces

// Pipes

@Component({
    selector: 'app-configuration-ypareo',
    templateUrl: './configuration-ypareo.component.html',
    styleUrls: ['./configuration-ypareo.component.scss'],
    imports: [
        SharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatInputModule,
        MatButtonModule,
        MatExpansionModule,
        MatIconModule
    ]
})
export class ConfigurationYpareoComponent implements OnInit {
    settings = model<any>();
    collapsed = input<boolean>();

    processing: boolean = false;
    success: true | false | null = null;
    error: string = '';

    constructor(
        private loginService: LoginService,
        private ypareoService: YpareoService,
        private flashMessageService: FlashMessageService
    ) {}

    ngOnInit() {}

    // #region Getters

    isTestProcessing(): boolean {
        return this.processing;
    }

    isTestValid(): boolean {
        return this.success === true;
    }

    isTestInvalid(): boolean {
        return this.success === false;
    }

    // #endregion Getters

    // #region Handlers

    testYpareoImport(event: Event): void {
        this.processing = true;
        this.success = null;
        this.error = '';

        this.ypareoService
            .testNewSettings({
                url: this.settings().ypareo_base_url,
                token: this.settings().ypareo_token
            })
            .subscribe(
                (data) => {
                    this.success = data?.success === true;
                    if (!this.success) {
                        this.error = data?.error ?? 'Une erreur est survenue (1)';
                    }
                    this.processing = false;
                    this.ypareoService
                        .saveSettings(this.loginService.getUser().structureid, {
                            ypareo_base_url: this.settings().ypareo_base_url,
                            ypareo_token: this.settings().ypareo_token
                        })
                        .subscribe({
                            next: () => {
                                this.flashMessageService.flash(
                                    'Les modifications ont été enregistrées avec succès.'
                                );
                            }
                        });
                },
                (error) => {
                    this.success = false;
                    this.error = error?.message ?? 'Une erreur est survenue (2)';
                    this.processing = false;
                }
            );
    }

    resetTest(): void {
        this.success = null;
    }

    // #endregion Handlers

    // #region Internals

    // #endregion Internals
}
