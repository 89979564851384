// Internal dependencies
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { ScrollingModule } from '@angular/cdk/scrolling';

// External dependencies
import * as FileSaver from 'file-saver';

// Modules
import { SharedModule } from '@/modules/shared.module';

// Components
import { UserYpareoComponent } from './user-ypareo/user-ypareo.component';

// Services
import { UsersService } from '@/services/users.service';
import { LoginService } from '@/services/login.service';
import { YpareoService } from '@/services/ypareo.service';
import { LoadingService } from '@/services/loading.service';
import { DialogService } from '@/services/dialog.service';
import { FlashMessageService } from '@/services/flash-message.service';

// Interfaces
import { Role } from '@/structures/role';
import { YpareoUser } from '@/structures/ypareo-user';
import { PreviewImportYpareo } from '@/structures/preview-import-ypareo';

interface Filters {
    search: string;
    display_status: string;
    user_type: string;
}

// Pipes

@Component({
    selector: 'app-users-ypareo',
    templateUrl: './users-ypareo.component.html',
    styleUrls: ['./users-ypareo.component.scss'],
    imports: [
        SharedModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatCheckboxModule,
        ScrollingModule,
        UserYpareoComponent
    ]
})
export class UsersYpareoComponent implements OnInit {
    roles: Array<Role>;
    users: Array<YpareoUser> = [];

    filters: Filters = {
        search: '',
        display_status: '',
        user_type: ''
    };

    constructor(
        private loginService: LoginService,
        private dialogService: DialogService,
        private ypareoService: YpareoService,
        private loadingService: LoadingService,
        private flashMessageService: FlashMessageService
    ) {}

    ngOnInit() {
        this.roles = [
            {
                shortname: 'internalTeacher',
                name: 'Formateur',
                icon: 'icon-formateurinterne',
                title: 'Formateur',
                key: 'internalTeacher'
            },
            {
                id: 19,
                shortname: 'externalTeacher',
                name: 'Intervenant',
                icon: 'icon-formateurexterne',
                title: 'Intervenant',
                key: 'externalTeacher'
            },
            {
                id: 17,
                shortname: 'learner',
                name: 'Apprenant',
                icon: 'icon-apprenant',
                title: 'Apprenant',
                key: 'learner'
            }
        ];
        this.getUsers();
    }

    // #region Getters

    getUsers(): void {
        this.loadingService.startLoading('getUsers');
        this.users = [];
        this.ypareoService
            .searchUsers(this.loginService.getUser().structureid, this.filters)
            .subscribe((data) => {
                this.users = data;
                this.loadingService.stopLoading('getUsers');
            });
    }

    getSelectedUsersCount(): number {
        if (this.users) {
            return this.users.filter((user: YpareoUser) => user.selected).length;
        }
        return 0;
    }

    getCheckboxTooltip() {
        if (this.users.length === 0) {
            return 'Aucun utilisateur à sélectionner';
        }
        if (this.users.length > 100) {
            return "Trop d'utilisateurs à sélectionner, veuillez affiner votre recherche (Max. 100 utilisateurs)";
        }
    }

    isLoading(view: string) {
        return this.loadingService.isLoading(view);
    }

    // #endregion Getters

    // #region Handlers

    handleToggleUsers($event: MatCheckboxChange) {
        this.users.map((user: YpareoUser) => {
            if (!user.to_synchronize && user.display_status !== 'up_to_date') {
                user.selected = $event.checked;
            }
        });
    }

    handleClickResetSearch() {
        this.filters.search = '';
        this.getUsers();
    }

    handleClickImportSelection() {
        this.ypareoService
            .getImportPreview(
                this.users
                    .filter((user: YpareoUser) => user.selected)
                    .map((user: YpareoUser) => user.ypareo_id)
                    .join('|')
            )
            .subscribe((data: PreviewImportYpareo) => {
                if (data.groupnames_to_create.length > 0 || data.sitenames_to_create.length > 0) {
                    this.dialogService
                        .openPreviewImportYpareo(data)
                        .subscribe((confirmed: boolean) => {
                            if (confirmed) {
                                this.setUsersToSynchronize();
                            }
                        });
                } else {
                    this.setUsersToSynchronize();
                }
            });
    }

    handleClickExportCSV() {
        this.ypareoService
            .getUsersCSV(
                this.loginService.getUser().structureid,
                this.users
                    .filter((user: YpareoUser) => user.selected)
                    .map((user: YpareoUser) => user.ypareo_id)
                    .join('|')
            )
            .subscribe((data: Blob) => {
                FileSaver.saveAs(
                    new Blob(['\ufeff', data], {
                        type: 'text/csv;charset=UTF-8;'
                    }),
                    'ypareo_export.csv'
                );
            });
    }

    handleUserTypeChange($event: MatSelectChange) {
        this.filters.user_type = $event.value;
        this.getUsers();
    }

    handleDisplayStatusChange($event: MatSelectChange) {
        this.filters.display_status = $event.value;
        this.getUsers();
    }

    // #endregion Handlers

    // #region Internals

    private setUsersToSynchronize() {
        this.ypareoService
            .setUsersToSynchronize(
                this.users
                    .filter((user: YpareoUser) => user.selected)
                    .map((user: YpareoUser) => user.ypareo_id)
                    .join('|')
            )
            .subscribe(() => {
                this.users = this.users.map((user: YpareoUser) => {
                    if (user.selected) {
                        user = {
                            ...user,
                            selected: false,
                            to_synchronize: 1
                        };
                    }
                    return user;
                });
                this.flashMessageService.flash('La synchronisation des utilisateurs est en compte');
            });
    }

    // #endregion Internals
}
