<div class="header">
    <div class="icon-UserYPareo"></div>
    <div class="label">Imports d'utilisateurs</div>
    <button mat-icon-button (click)="handleCloseDialog(false)">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="container">
    @if (previewImport.groupnames_to_create.length) {
        <div>
            <div class="label">Les groupes suivants vont êtres créés :</div>
            <div class="data">{{ getGroupsNames() }}</div>
        </div>
    }
    @if (previewImport.sitenames_to_create.length) {
        <div>
            <div class="label">Les sites suivants vont êtres créés :</div>
            <div class="data">{{ getSitesNames() }}</div>
        </div>
    }
</div>
<div class="footer">
    <button class="button" mat-button (click)="handleCloseDialog(true)">Importer</button>
    <button class="button" mat-button (click)="handleCloseDialog(false)">Annuler</button>
</div>
