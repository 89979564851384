import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { Subscription } from 'rxjs';

import { LoginService } from '../../../services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-cgu',
    templateUrl: './cgu.component.html',
    styleUrls: ['./cgu.component.scss']
})
export class CGUComponent implements OnInit {
    userid: number;
    currentTab = 'cgu';
    cguHtml: SafeHtml;
    mlHtml: SafeHtml;
    pdcHtml: SafeHtml;

    subscriptions = new Subscription();

    constructor(
        private loginService: LoginService,
        private router: Router,
        private route: ActivatedRoute,
        private configService: ConfigService,
        private sanitizer: DomSanitizer,
        private location: Location,
        private cookieService: CookieService
    ) {}

    ngOnInit() {
        if (+this.route.snapshot.queryParamMap.get('userid')) {
            this.userid = +this.route.snapshot.queryParamMap.get('userid');
        } else {
            this.userid = -1;
        }
        this.cguHtml = this.sanitizer.bypassSecurityTrustHtml(this.configService.getConfig().cgu);
        this.mlHtml = this.sanitizer.bypassSecurityTrustHtml(this.configService.getConfig().ml);
        this.pdcHtml = this.sanitizer.bypassSecurityTrustHtml(this.configService.getConfig().pdc);
    }

    isDarkTheme() {
        return this.cookieService.get('dark') === 'true';
    }

    isValidationMode() {
        return this.userid > -1;
    }

    switchCurrentTab(tab: string) {
        this.currentTab = tab;
    }

    isCurrentTab(tab: string) {
        return this.currentTab === tab;
    }

    acceptCGU() {
        this.subscriptions.add(
            this.loginService.acceptCGU(this.userid).subscribe(() => {
                this.loginService.clearExceptionAndNavigate();
            })
        );
    }

    refuseCGU() {
        this.subscriptions.add(
            this.loginService.logout().subscribe(() => {
                this.goToLogin();
            })
        );
    }

    goToLogin() {
        this.location.back();
    }
}
