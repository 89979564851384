import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { User } from '../structures/user';
import { Group } from '../structures/group';
import { Observable } from 'rxjs';
import { DialogService } from './dialog.service';
import { log } from './decorators/log.decorator';

@Injectable({
    providedIn: 'root'
})
export class ActivityReportService {
    constructor(private http: HttpClient, private dialogService: DialogService) {}

    @log() checkDownload(target: any) {
        const params: any = {};

        if (Array.isArray(target)) {
            params.userids = target.map((usr) => usr.id).join('|');
        } else {
            params.groupid = target.id;
        }
        return this.http.get(`/statistics/pdf/report/check_download`, { params });
    }

    @log() getPDFReport(target, overviewdates, activitydates): Observable<any> {
        const params: any = {};

        if (Array.isArray(target)) {
            params.userids = target.map((usr) => usr.id).join('|');
        } else {
            params.groupid = target.id;
        }

        if (overviewdates) {
            params.overviewdatestart = overviewdates.start.toISOString();
            params.overviewdateend = overviewdates.end.toISOString();
        }

        if (activitydates) {
            params.activitydatestart = activitydates.start.toISOString();
            params.activitydateend = activitydates.end.toISOString();
        }

        const config: any = {
            responseType: 'blob',
            observe: 'response',
            params
        };

        return this.http.get(`/statistics/pdf/report`, config);
    }

    /**
     * vérifie l'état d'avancement de la tâche de génération du zip multi-rapports
     * @param taskiId l'id (reçu par mail)
     */
    @log() getPDFReportTaskStatus(taskiId: number): Observable<any> {
        return this.http
            .get(`/statistics/pdf/report/${taskiId}/status`, { responseType: 'text' as 'json' })
            .pipe(
                tap(
                    (data: any) => {},
                    (error: HttpErrorResponse) => {
                        if (JSON.parse(error.error).userMessage) {
                            this.dialogService.openError(JSON.parse(error.error).userMessage);
                        } else {
                            this.dialogService.openError('Une erreur est survenue.');
                        }
                    }
                )
            );
    }

    /**
     * Télécharge le ZIP multiRapports
     */
    @log() downloadZIPMultiReport(taskId: number): Observable<any> {
        return this.http
            .get(`/statistics/pdf/report/${taskId}/download`, {
                responseType: 'blob',
                observe: 'response'
            })
            .pipe(tap((data: any) => {}));
    }
}
