<div class="tm-flex tm-flex-column tm-height-100">
    @if (settings) {
        @if (displayNoConfigurationWarning()) {
            <p class="tm-text-center tm-color-grey">
                <em
                    >Aucune configuration des webservices ypaero trouvée.<br />
                    Veuillez vous rapprocher de l'administration local d'easi.</em
                >
            </p>
        }
        @if (isConfigurationVisible()) {
            <app-configuration-ypareo
                [(settings)]="settings"
                [collapsed]="isConfigurationCollapsed()"
            ></app-configuration-ypareo>
        }
        @if (isUsersVisible()) {
            <div class="content tm-flex-wrap tm-display-flex">
                <app-users-ypareo></app-users-ypareo>
            </div>
        }
    } @else {
        <app-loading></app-loading>
    }
</div>
