export const connexionsTypes = [
    {
        title: 'Connexions uniques',
        key: 'uniques',
        selected: true
    },
    {
        title: 'Connexions totales',
        key: 'total',
        selected: false
    }
];

export const userTypes = [
    {
        title: 'Apprenants',
        key: 'learner',
        selected: true
    },
    {
        title: 'Formateurs internes',
        key: 'internalTeacher',
        selected: true
    },
    {
        title: 'Formateurs externes',
        key: 'externalTeacher',
        selected: true
    },
    {
        title: 'Tuteurs en entreprise',
        key: 'tutor',
        selected: true
    },
    {
        title: 'Administrateurs locaux',
        key: 'localAdmin',
        selected: true
    },
    {
        title: 'Administrateurs nationaux',
        key: 'nationalAdmin',
        selected: true
    }
];

export const activityIndicators = [
    {
        title: "Nombre d'assignations",
        key: 'assignment',
        selected: true
    },
    {
        title: "Nombre d'exécutions",
        key: 'executions',
        selected: false
    }
    // HIDDEN FOR THE MOMENT, WAITING FOR BACKEND
    // {
    //   title: 'Ratio exécutions / assignations',
    //   key: 'ratio',
    //   selected: false,
    // },
];

export const assignationsPrice = [
    {
        title: 'Contenus payants',
        key: '1',
        selected: false
    },
    {
        title: 'Contenus non payants',
        key: '0',
        selected: false
    },
    {
        title: 'Contenus payants et non payants',
        key: 'both',
        selected: true
    }
];

export const structuresTypes = [
    {
        title: 'Contenus nationaux',
        key: 'National',
        selected: true
    },
    {
        title: 'Contenus locaux',
        key: 'local',
        selected: false
    },
    {
        title: 'Contenus nationaux et locaux',
        key: '*',
        selected: false
    }
];
