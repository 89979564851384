import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { LoginService } from '../services/login.service';

import { User } from '../structures/user';

@Injectable({
    providedIn: 'root'
})
export class DefaultGuard {
    constructor(private loginService: LoginService, private router: Router) {}

    canActivate(): any {
        if (this.loginService.getUser()) {
            if (this.loginService.getUser().refreshException) {
                return new Observable((observer) => {
                    this.loginService.getCurrentUser().subscribe((data: User) => {
                        this.loginService.markExceptionAsRefreshed();
                        if (!data.exception) {
                            observer.next(this.router.createUrlTree(['/']));
                            return;
                        } else {
                            if (data.exception.code === 'PASSWORDTEMP_USED') {
                                observer.next(
                                    this.router.createUrlTree(['/setPassword'], {
                                        queryParams: JSON.parse(data.exception.developpermessage)
                                    })
                                );
                            } else if (data.exception.code === 'PASSWORD_NOT_VERIFIED') {
                                observer.next(
                                    this.router.createUrlTree(['/setPassword'], {
                                        queryParams: JSON.parse(data.exception.developpermessage)
                                    })
                                );
                            } else if (data.exception.code === 'EMAIL_NOT_SET') {
                                observer.next(
                                    this.router.createUrlTree(['/setEmail'], {
                                        queryParams: JSON.parse(data.exception.developpermessage)
                                    })
                                );
                            } else if (data.exception.code === 'EMAIL_NOT_VERIFIED') {
                                observer.next(
                                    this.router.createUrlTree(['/validateEmail'], {
                                        queryParams: JSON.parse(data.exception.developpermessage)
                                    })
                                );
                            } else if (data.exception.code === 'CGU_NOT_ACCEPTED') {
                                observer.next(
                                    this.router.createUrlTree(['/cgu'], {
                                        queryParams: JSON.parse(data.exception.developpermessage)
                                    })
                                );
                            } else if (data.exception.code === 'AUTH_TOKEN_NEEDED') {
                                observer.next(this.router.createUrlTree(['/token']));
                            } else {
                                observer.next(true);
                            }
                        }
                        observer.complete();
                    });
                });
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
}
