<div
    class="user"
    [ngClass]="{
        'user-disabled': !user.enabled
    }"
>
    <div class="user-description">
        <span [ngClass]="getUserIcon(user)"></span>{{ user.lastname.toUpperCase() }}
        {{ user.firstname }}
    </div>
    <div class="user-icons">
        <div class="dynamic-icons">
            <span
                class="icon-FicheUser"
                *ngIf="!hasIncompleteEvents(user)"
                (click)="openUserDialog(user)"
                tooltip="Voir la fiche"
            ></span>
            <ng-container [ngSwitch]="user.enabled">
                <span
                    class="icon-DesactiverUser"
                    *ngSwitchCase="true"
                    tooltip="Désactiver cet utilisateur"
                    (click)="disableUser(user)"
                ></span>
                <span
                    class="icon-Reactiver"
                    *ngSwitchCase="false"
                    tooltip="Activer cet utilisateur"
                    (click)="enableUser(user)"
                ></span>
            </ng-container>
        </div>
        <div class="fix-icons">
            <span
                class="icon-FicheUser with-warning"
                *ngIf="hasIncompleteEvents(user)"
                (click)="openUserDialog(user)"
                tooltip="Activation du compte non finalisée : consulter la fiche pour plus d'information"
            ></span>
            <span
                class="icon-DesactiverUser"
                *ngIf="!user.enabled"
                tooltip="Utilisateur désactivé"
            ></span>
        </div>
    </div>
</div>
