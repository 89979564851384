<div id="warning-component">
    <div class="header">
        <span class="icon-Warning"></span>
        <span class="label">{{ getHeader() }}</span>
        <span class="icon-close" (click)="closeDialog()"></span>
    </div>
    <mat-dialog-content class="content" [innerHTML]="getMainMessage()"> </mat-dialog-content>
    <div class="footer">
        <div class="create-button" (click)="closeDialog()">
            {{ getFooter() }}
        </div>
    </div>
</div>
