import { Component, OnInit, ViewChild, ViewContainerRef, OnDestroy } from '@angular/core';

import { GraphsService } from 'src/app/services/graphs.service';
import { Subscription, Observable } from 'rxjs';

@Component({
    selector: 'app-graphs-top-activities',
    templateUrl: './graphs-top-activities.component.html',
    styleUrls: ['./graphs-top-activities.component.scss']
})
export class GraphsTopActivitiesComponent implements OnInit, OnDestroy {
    @ViewChild('graphs', {
        read: ViewContainerRef
    })
    viewRef: ViewContainerRef;

    single: Array<any>;
    view: any[] = [800, 500];
    subscriptions: Subscription = new Subscription();

    // options
    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = false;
    showXAxisLabel = false;
    yAxisLabel = 'Activité';
    showYAxisLabel = false;
    xAxisLabel = 'Nombre';
    customColors: any;
    opacity = 0.001;
    Zindex = -1;

    isLoading$: Observable<boolean>;

    colorScheme = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
    };

    constructor(private graphsService: GraphsService) {
        this.isLoading$ = this.graphsService.isFetchingData$.asObservable();
    }

    ngOnInit() {
        this.subscriptions.add(
            this.graphsService.getTopActivitiesData().subscribe((data) => {
                if (data && data !== this.single) {
                    this.single = data;
                    this.formatLabelsInTheDOM(data);
                    this.customColors = this.customColorsCallback();
                } else {
                    this.single = undefined;
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onSelect(data): void {}

    onActivate(data): void {}

    onDeactivate(data): void {}

    /**
     * customise les couleurs des barres en fonction de la catégorie
     */
    customColorsCallback() {
        const result: any[] = [];
        if (!this.single) {
            return;
        }
        for (let i = 0; i < this.single.length; i++) {
            if (this.single[i].category === 'ressource') {
                result.push({
                    name: this.single[i].name,
                    value: '#199fe2'
                });
            } else if (this.single[i].category === 'evaluation') {
                result.push({
                    name: this.single[i].name,
                    value: '#007A73'
                });
            } else if (
                this.single[i].category === 'miseenapplication' ||
                this.single[i].category === 'travail'
            ) {
                result.push({
                    name: this.single[i].name,
                    value: '#19BAE2'
                });
            } else if (this.single[i].category === 'corrige') {
                result.push({
                    name: this.single[i].name,
                    value: '#1f94ce'
                });
            } else if (this.single[i].category === 'devoir') {
                result.push({
                    name: this.single[i].name,
                    value: '#199fe2'
                });
            } else if (this.single[i].category === 'positionnement') {
                result.push({
                    name: this.single[i].name,
                    value: '#007a73'
                });
            } else if (this.single[i].category === 'preparation') {
                result.push({
                    name: this.single[i].name,
                    value: '#199fe2'
                });
            } else if (this.single[i].category === 'presentiel') {
                result.push({
                    name: this.single[i].name,
                    value: '#01a59b'
                });
            } else if (this.single[i].category === 'tp') {
                result.push({
                    name: this.single[i].name,
                    value: '#01a59b'
                });
            } else if (this.single[i].category === 'url') {
                result.push({
                    name: this.single[i].name,
                    value: '#01a59b'
                });
            } else {
                result.push({
                    name: this.single[i].name,
                    value: 'grey'
                });
            }
        }
        return result;
    }

    isCategoryInData(categoryName: string) {
        if (this.single) {
            return this.single.some((activity) => activity.category === categoryName);
        }
        return false;
    }

    /**
     * limite la longueur de la légende, et y ajoute l'id
     * @param value la valeur reçue des données
     */
    yAxisTickFormatting(value: any): string {
        let newValue = value.split('$$$')[0];
        const id = value.split('$$$')[1];
        if (newValue.length > 32) {
            newValue = newValue.substring(0, 29) + '...';
        }
        newValue += ` (ID ${id})`;
        return newValue;
    }

    /**
     * méthode peu propre qui corrige à la main les étiquettes de données pour y ajouter exécutions/assignations
     * @param data données reçues du service
     */
    formatLabelsInTheDOM(data): void {
        setTimeout(() => {
            const array = document.querySelectorAll('.textDataLabel');
            array.forEach((element, index) => {
                if (!isNaN(data[index].extra.executions)) {
                    element.innerHTML = `${data[index].value} assignation${
                        data[index].value > 1 ? 's' : ''
                    } (${data[index].extra.executions} exécution${
                        data[index].extra.executions > 1 ? 's' : ''
                    })`;
                } else if (!isNaN(data[index].extra.assignment)) {
                    element.innerHTML = `${data[index].value} execution${
                        data[index].value > 1 ? 's' : ''
                    } (${data[index].extra.assignment} assignation${
                        data[index].extra.assignment > 1 ? 's' : ''
                    })`;
                }
            });
            this.Zindex = 0;
            this.opacity = 1;
        }, 0);
    }

    /**
     * formate la ligne supplémentaire du tooltip pour y ajouter les exécs/assigns
     * @param model l'objet de donnée
     */
    getExtraTooltipData(model): string {
        if (!isNaN(model.extra.executions)) {
            return ` assignations (${model.extra.executions} exécution${
                model.extra.executions > 1 ? 's' : ''
            })`;
        } else if (model.extra.assignment) {
            return ` exécutions (${model.extra.assignment} assignation${
                model.extra.assignment > 1 ? 's' : ''
            })`;
        }
    }
}
