<div
    class="dates-filter"
    [ngClass]="{
        opened: datesFilterOpened
    }"
    (clickOutside)="onClickOutsideDatesDropdown($event)"
>
    <div class="label" (click)="datesDropdownOpened = !datesDropdownOpened">
        <span
            >Entre le
            <span class="blue-date">{{ dateStart | date: 'dd/MM/yyyy' }}</span>
            et le
            <span class="blue-date">{{ dateEnd | date: 'dd/MM/yyyy' }}</span></span
        >
        <div class="carrot">
            <span class="icon-deroulant"></span>
        </div>
    </div>
    <div
        class="dropdown-content"
        [ngClass]="{
            opened: datesDropdownOpened
        }"
    >
        <div class="dates-filter-container">
            <div class="dates-selection">
                <div class="row">
                    Entre le
                    <input
                        [matDatepicker]="dateStartInput"
                        [(ngModel)]="dateStart"
                        class="datepicker"
                        placeholder=" - "
                        (focus)="dateStartInput.open()"
                        (ngModelChange)="dateStartChange.emit(dateStart)"
                        min="2016-08-01T20:00:00.000Z"
                        [max]="maxDateStart"
                    />
                </div>
                <mat-datepicker #dateStartInput></mat-datepicker>

                <div class="row">
                    et le
                    <input
                        [matDatepicker]="dateEndInput"
                        [(ngModel)]="dateEnd"
                        class="datepicker"
                        placeholder=" - "
                        (focus)="dateEndInput.open()"
                        (ngModelChange)="dateEndChange.emit(dateEnd)"
                        [min]="minDateEnd"
                        [max]="maxDateEnd"
                    />
                </div>
                <mat-datepicker #dateEndInput></mat-datepicker>
            </div>
        </div>
    </div>
</div>
