import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { Role } from '../structures/role';
import { Structure } from '../structures/structure';
import { User } from '../structures/user';
import { ApplicationAccess } from '../structures/application';

import { CookieService } from 'ngx-cookie-service';
import { log } from './decorators/log.decorator';

/**
 * Service gérant l'utilisateur actuel
 */
@Injectable({
    providedIn: 'root'
})
export class LoginService {
    /**
     * L'utilisateur actuel
     */
    user: any;
    redirectURL: string;
    exception: any = {};
    showNoMailException = true;

    closeAllDialogs$: Subject<any> = new Subject();
    updateStructure: Subject<any> = new Subject();

    lastStructure: Structure = {};

    constructor(
        private http: HttpClient,
        private router: Router,
        private cookieService: CookieService
    ) {}

    getUser() {
        if (this.user) {
            return this.user;
        }
    }

    getRedirectURL() {
        return this.user.url_after_login;
    }

    setException() {
        if (this.user.exception) {
            if (this.user.exception.code === 'PASSWORDTEMP_USED') {
                this.exception.name = ['setPassword'];
                this.exception.params = JSON.parse(this.user.exception.developpermessage);
            } else if (this.user.exception.code === 'EMAIL_NOT_SET') {
                this.exception.name = ['setEmail'];
                this.exception.params = JSON.parse(this.user.exception.developpermessage);
            } else if (this.user.exception.code === 'EMAIL_NOT_VERIFIED') {
                this.exception.name = ['validateEmail', 'setEmail'];
                this.exception.params = JSON.parse(this.user.exception.developpermessage);
            } else if (this.user.exception.code === 'PASSWORD_NOT_VERIFIED') {
                this.exception.name = ['setPassword'];
                this.exception.params = JSON.parse(this.user.exception.developpermessage);
            } else if (this.user.exception.code === 'CGU_NOT_ACCEPTED') {
                this.exception.name = ['cgu'];
                this.exception.params = JSON.parse(this.user.exception.developpermessage);
            } else if (this.user.exception.code === 'AUTH_TOKEN_NEEDED') {
                this.exception.name = ['token'];
                this.exception.params = {};
            }
        }
    }

    getException() {
        if (this.exception.name && this.exception.name.length) {
            return this.exception;
        }
        return false;
    }

    clearExceptionAndNavigate() {
        this.exception = {};
        this.user = {
            ...this.user,
            refreshException: true
        };
        this.router.navigate(['/']);
    }

    forceNoEmail() {
        this.showNoMailException = true;
        this.exception = {};
        this.user = {
            ...this.user,
            refreshException: true
        };
        this.router.navigate(['/admin/users']);
    }

    forceRefreshException() {
        if (this.user.exception) {
            this.user.refreshException = true;
        }
    }

    markExceptionAsRefreshed() {
        this.user = {
            ...this.user,
            refreshException: false
        };
    }

    /**
     * @returns {any} la derniere structure selectionnee par l'utilisateur.
     */
    getLastStructure(): any {
        return this.lastStructure;
    }

    /**
     * @description modifie l'id de la nom de la derniere structure qui avait ete selectionee par l'utilisateur.
     * @param {any} structure recue via un cookie.
     * @returns {void}
     */
    setLastStructure(structure: any): void {
        if (structure.structureid) {
            this.lastStructure.id = structure.structureid;
            this.lastStructure.name = structure.structurename;
        } else if (structure.id) {
            this.lastStructure.id = structure.id;
            this.lastStructure.name = structure.name;
        }
        this.updateStructure.next(this.lastStructure);
    }

    /**
     * @description update la structure et poste le message a easi-shared.
     * @returns {void}
     */
    updateLastStructure(structure: any): void {
        const iframe: any = document.getElementById('header-container');

        this.setLastStructure(structure);
        iframe.contentWindow.postMessage(
            { setCookies: { structureid: structure.id, structurename: structure.name } },
            '*'
        );
    }

    @log() login(
        username: string,
        password: string,
        structureid: number,
        returnTo?: string
    ): Observable<User> {
        return this.http
            .post<User>(`/users/login`, { username, password, structureid, returnTo })
            .pipe(
                tap(
                    (data) => {
                        this.showNoMailException = false;
                        this.user = data;
                    },
                    (error: HttpErrorResponse) => {
                        this.isAuthenticated(error);
                    }
                )
            );
    }

    @log() loginWithHub3E(user_id: number, token: string): Observable<any> {
        return this.http.get(`/hub3e/login/${user_id}/${token}`);
    }

    @log() displayAzureForm() {
        return this.http.get(`/users/get_azure_form`);
    }

    @log() removeAzureForm() {
        return this.http.put(`/users/deny_azure_form`, {});
    }

    @log() loginAs(user: User): Observable<User> {
        return this.http
            .post<User>(`/users/login_as`, { id: user.id })
            .pipe(
                tap(
                    (data) => {
                        this.user = undefined;
                        this.user = data;
                    },
                    (error: HttpErrorResponse) => {
                        this.isAuthenticated(error);
                    }
                )
            );
    }

    @log() logout(): Observable<any> {
        return this.http.post('/users/logout', undefined).pipe(
            tap(
                (data) => {
                    this.user = undefined;
                },
                (error) => {
                    this.isAuthenticated(error);
                }
            )
        );
    }

    @log() getCurrentUser(): Observable<any> {
        const params: any = {};

        if (this.showNoMailException) {
            params.bypassoptional = true;
        }

        return this.http.get('/users/current', { params }).pipe(
            tap(
                (data) => {
                    if (this.cookieService.check('redirect-url')) {
                        const redirectUrl = this.cookieService.get('redirect-url');
                        this.cookieService.delete('redirect-url', '/');
                        window.location.href = redirectUrl;
                        window.location.reload();
                    }
                    this.user = data;
                    this.setException();
                },
                (error) => {
                    const expires = new Date();
                    expires.setFullYear(expires.getFullYear() + 1);
                    this.cookieService.set('redirect-url', window.location.href, expires, '/');
                    this.isAuthenticated(error);
                }
            )
        );
    }

    @log() acceptCGU(userId: number): Observable<any> {
        return this.http.post(`/users/${userId}/event/cgu`, undefined);
    }

    @log() getRPList(structureid: number): Observable<any[]> {
        return this.http.get<any[]>(`/oicrp/structure/${structureid}`);
    }

    @log() postCookies() {
        return this.http.post('/setTestCookie', undefined);
    }

    @log() getCookies() {
        return this.http.get('/getTestCookie');
    }

    initCheckConnectedInterval() {
        setInterval(() => {
            this.http.get<boolean>(`/is_still_connected.php`).subscribe((data: boolean) => {
                if (data === false) {
                    const iframe: HTMLIFrameElement = document.getElementById(
                        'header-container'
                    ) as HTMLIFrameElement;
                    iframe.contentWindow.postMessage('userDisconnected', '*');
                    this.closeAllDialogs$.next(true);
                }
            });
        }, 5 * 60 * 1000);
    }

    /**
     * @param { HttpErrorResponse } error L'erreur renvoyé par les web-services
     * Redirige l'utilisateur sur easi-connect s'il n'est pas connecté
     */
    isAuthenticated(error: HttpErrorResponse) {
        if (
            error.error.errorCode &&
            (error.error.errorCode === 'USER_NOT_AUTH' || error.error.errorCode === 400301)
        ) {
            window.location.href = '/openidc/auth-redirect';
        }
    }
}
