import { Component, OnInit } from '@angular/core';
import { GraphsService } from './../../../../services/graphs.service';

@Component({
    selector: 'app-graphs-active-users',
    templateUrl: './graphs-active-users.component.html',
    styleUrls: ['./graphs-active-users.component.scss']
})
export class GraphsActiveUsersComponent implements OnInit {
    data: any;
    view: any[] = [900, 400];
    // view: any[] = undefined;
    showLabels = true;
    animations = true;
    xAxisLabel = 'Période';
    yAxisLabel = 'Assignations';
    timeline = false;
    activeEntries = [];

    colorScheme = {
        domain: [
            '#146187',
            '#e60000',
            ' #01a59b',
            '#ab80fb',
            ' #199fe2',
            '#fda804',
            '#ff3366',
            '#424242',
            '#ff7200'
        ]
    };

    constructor(private graphsService: GraphsService) {}

    ngOnInit(): void {
        this.graphsService.getActiveUsers().subscribe((data: any) => {
            this.data = data;
        });
    }

    getAppInfo(app) {
        switch (app.name) {
            case 'Training':
                return `L'utilisateur a :
        - placé un contenu en favori,
        - consulté un contenu,
        - assigné un contenu,
        - enregistré une fiche descriptive,
        - ouvert une page d'assignation.`;
                break;
            case 'Média':
                return `L'utilisateur a cliqué sur le bouton enregistrer`;
                break;
            case 'Forum':
                return `L'utilisateur a posté au moins un message sur un forum`;
                break;
            case 'Call':
                return `L'utilisateur a rejoint au moins un call`;
                break;
            case 'Chat':
                return `L'utilisateur a envoyé au moins un message sur un canal chat`;
                break;
            case 'Cloud':
                return `L'utilisateur a au moins déposé ou consulté/téléchargé un document`;
                break;
            case 'Vue Apprenant':
                return `L'utilisateur a cliqué sur le bouton lancer`;
                break;
            default:
                break;
        }
    }

    onSelect(event) {}
    onActivate(event) {}
    onDeactivate(event) {}
}
