<div class="contact" [ngClass]="{ disabled: contact.hidden }">
    <div class="contact-description">
        <div class="icon-userinconnu-gros"></div>
        {{ contact.name }}
    </div>
    <div class="contact-icons">
        <div class="dynamic-icons">
            <span class="icon-Editer" (click)="editContact()" tooltip="Modifier le contact"></span>
            <span
                class="icon-Cloner"
                (click)="cloneContact()"
                tooltip="Dupliquer le contact"
            ></span>
            <span
                class="icon-DesactiverUser"
                (click)="hideContact()"
                *ngIf="!contact.hidden"
                tooltip="Masquer le contact"
            ></span>
            <span
                class="icon-Reactiver"
                (click)="showContact()"
                *ngIf="contact.hidden"
                tooltip="Réafficher le contact"
            ></span>
            <span class="icon-bin" (click)="deleteContact()" tooltip="Supprimer le contact"></span>
        </div>
    </div>
</div>
