import { Component, OnInit } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';

import { DialogService } from '../../../services/dialog.service';
import { LoadingService } from '../../../services/loading.service';
import { StructuresService } from '../../../services/structures.service';
import { SiteService } from '../../../services/site.service';
import { UsersService } from '../../../services/users.service';

import { Site } from '../../../structures/site';
import { Structure } from '../../../structures/structure';

@Component({
    selector: 'app-structures',
    templateUrl: './structures.component.html',
    styleUrls: ['./structures.component.scss']
})
export class StructuresComponent implements OnInit {
    constructor(
        private dialogService: DialogService,
        private loadingService: LoadingService,
        private siteService: SiteService,
        private structureService: StructuresService,
        private usersService: UsersService
    ) {}

    subscriptions: Subscription = new Subscription();

    structures: Array<Structure>;
    searchTerm: string;

    ngOnInit() {
        this.structures = [];
        this.searchTerm = '';
        this.loadingService.startLoading('structures', 'getStructures');
        this.subscriptions.add(
            this.structureService.structures$.subscribe((data: Array<Structure>) => {
                this.loadingService.stopLoading('structures', 'getStructures');
                this.structures = data;
            })
        );
    }

    getStructuresCount() {
        return this.getSearchedStructures().length;
    }

    getSearchedStructures() {
        if (this.searchTerm === '') {
            return this.structures;
        } else {
            return this.structures.filter((structure) =>
                structure.name.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
        }
    }

    clearSearch() {
        this.searchTerm = '';
    }

    getStructureLabel(structure: Structure) {
        if (!structure.sites) {
            return structure.name;
        } else {
            return `${structure.name} (${structure.sites.length} sites, ${structure.corporations.length} entreprises, ${structure.usersCount} utilisateurs)`;
        }
    }

    getStructure(structure: Structure) {
        if (!structure.sites) {
            structure.sites = [];
            structure.corporations = [];
            const queryList = [];
            queryList.push(
                this.siteService.getSites({
                    structureid: structure.id
                })
            );
            queryList.push(
                this.usersService.getUsersCount({
                    structureid: structure.id
                })
            );
            forkJoin(queryList).subscribe((data: Array<any>) => {
                structure.sites = data[0].filter((site) => !site.isCorporation);
                structure.corporations = data[0].filter((corporation) => corporation.isCorporation);
                structure.usersCount = data[1].count;
            });
        }
    }

    openUpdateStructure($event, structure) {
        $event.stopImmediatePropagation();
        this.dialogService.openCreateStructure(structure);
    }

    openCreateStructure() {
        this.dialogService.openCreateStructure();
    }

    openCreateSite(event: Event, structure: Structure) {
        event.stopPropagation();
        const site = {
            structureid: structure.id,
            users: [],
            isCorporation: false
        };
        this.dialogService.openCreateSite(site).subscribe((data: Site) => {
            if (!data.isCorporation && structure.sites) {
                structure.sites.push(data);
            } else if (data.isCorporation && structure.corporations) {
                structure.corporations.push(data);
            }
        });
    }

    openEditSite(event: Event, site: Site) {
        event.stopPropagation();
        this.subscriptions.add(
            this.dialogService.openCreateSite(site).subscribe((data: Site) => {
                if (data) {
                    site.name = data.name;
                }
            })
        );
    }

    isLoading() {
        return this.loadingService.isLoading('structures');
    }
}
