import { Injectable, Inject } from '@angular/core';

import { LogService } from './log.service';

/**
 * Service gérant l'affichage de messages temporaires'
 */
@Injectable({
    providedIn: 'root'
})
export class FlashMessageService {
    constructor(private logService: LogService) {}

    flashing = false;
    flashMessage: string;
    timeout: any;

    flash(flashMessage: string) {
        this.flashing = true;
        this.flashMessage = flashMessage;
        this.logService.success('FlashMessageService : flash', this.flashMessage);
        this.timeout = setTimeout(() => {
            this.stopFlashing();
        }, 5000);
    }

    stopFlashing() {
        this.flashing = false;
        this.flashMessage = '';
        clearTimeout(this.timeout);
        this.logService.success('FlashMessageService : stopFlashing', this.flashMessage);
    }

    getFlashMessage() {
        return this.flashMessage;
    }

    isFlashing() {
        return this.flashing;
    }
}
