<div class="header">
    <span class="label">Authentification via Microsoft</span>
    <span class="icon-close" (click)="closeDialog(false)"></span>
</div>
<div class="content">
    <a class="button" (click)="openAzureLogin()"
        ><img class="login-image" [src]="getMSLogo()" />
        <div class="login-text">Lier mon compte EASi à mon compte Microsoft</div></a
    >
    <div class="legend">
        Si un compte Microsoft est mis à votre disposition par votre organisme de formation, merci
        de vous authentifier avec les identifiants fournis pour lier votre compte NetYpareo à votre
        compte Microsoft
    </div>
    <div class="link" (click)="removeAzureForm()">Ne plus me demander</div>
</div>
