import { Injectable } from '@angular/core';

/**
 * Service gérant la configuration de la plateforme
 */
@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    config: any;

    setConfig(config: any) {
        this.config = config;
    }

    getConfig(): any {
        return this.config;
    }

    getEndPoint(): string {
        return this.config.endpoint.connect.backend;
    }

    getTrainingEndPoint(): string {
        return this.config.endpoint.training.backend;
    }

    getTrainingFrontEndPoint(): string {
        return this.config.endpoint.training.front;
    }

    getLearningFrontEndPoint(): string {
        return this.config.endpoint.training.student;
    }

    getEasiSharedEndPoint(): string {
        return this.config.endpoint.shared.front;
    }

    getFaviconURL(): string {
        if (this.config.favicon) {
            return this.config.favicon;
        } else {
            return '../../favicon.ico';
        }
    }
}
