<mat-expansion-panel class="group" [hideToggle]="true" (click)="getUsers()">
    <mat-expansion-panel-header
        class="group-header"
        [collapsedHeight]="'50px'"
        [expandedHeight]="'50px'"
    >
        <div class="group-header-content">
            <div class="group-description">
                <span [ngClass]="getGroupIcon()"></span>
                {{ group.name }}
                <span class="group-counter" *ngIf="group.users">{{ getGroupCounter() }}</span>
            </div>
            <div class="icons">
                <div class="hover-icons">
                    <div
                        class="icon-message"
                        tooltip="Renvoyer l'email de validation aux membres du groupe n'ayant pas encore validé leur compte"
                        tooltipColor="#199fe2"
                        (click)="sendReminder($event)"
                    ></div>
                    <div
                        class="icon-Editer"
                        tooltip="Modifier ce groupe"
                        tooltipColor="#199fe2"
                        (click)="editGroup($event)"
                    ></div>
                </div>
                <div class="default-icons">
                    <div
                        *ngIf="group.name.length > 64"
                        class="icon-Warning"
                        tooltip="Synchronisation impossible avec le cloud.<br>Le nom du groupe contient plus de 64 caractères."
                    ></div>
                    <div class="icon-down"></div>
                </div>
            </div>
        </div>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <div class="users-container">
            <!-- <div class="user" *ngFor="let user of group.users">
                <div class="description">
                    <div class="icon" [ngClass]="getUserIcon(user)"></div>
                    <div class="name">{{ user.firstname }} {{ user.lastname.toUpperCase() }}</div>
                </div>
                <div class="icons">
                    <div class="icon icon-FicheUser" tooltip="Voir la fiche" tooltipColor="#199fe2"></div>
                    <div class="icon icon-DesactiverUser" tooltip="Désactiver cet utilisateur" tooltipColor="#199fe2"></div>
                </div>
            </div> -->
            <app-group-user-entry
                *ngFor="let user of group.users"
                [user]="user"
            ></app-group-user-entry>
        </div>
    </ng-template>
</mat-expansion-panel>
