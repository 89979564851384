<div id="preview-import-users-dialog">
    <div class="preview-import-users-dialog_header">
        <span class="title">Prévisualisation avant import</span>
        <span class="icon-close" (click)="closeDialog()"></span>
    </div>
    <app-loading *ngIf="isLoading"></app-loading>
    <div class="preview-import-users-dialog_body" *ngIf="!isLoading">
        <div class="info_container">Les espaces sont surlignés en orange</div>

        <div class="table_container" *ngIf="csvData">
            <table class="preview_table">
                <tr
                    *ngFor="let line of csvData; index as i; first as isFirstLine"
                    [ngClass]="{
                        bold: isFirstLine
                    }"
                    [ngSwitch]="line === 'error'"
                >
                    <td class="error-line" *ngSwitchCase="true" colspan="100%">
                        Cette ligne contient un nombre incorrect de colonnes, elle ne peut pas être
                        prévisualisée
                    </td>
                    <ng-container *ngSwitchCase="false">
                        <td
                            *ngFor="let cell of line; index as j"
                            [ngClass]="{
                                'error-cell': !isValidInput(line, j)
                            }"
                        >
                            <span class="warning-empty" *ngIf="!cell">(vide)</span>
                            <ng-container *ngFor="let word of getFormatedCellContent(cell)">
                                <span
                                    [ngClass]="{
                                        'warning-space': word === ' '
                                    }"
                                    >{{ word }}</span
                                >
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
            </table>
        </div>
    </div>
    <div class="preview-import-users-dialog_footer">
        <div class="button import-button" (click)="import()">Importer</div>
        <div class="button cancel-button" (click)="closeDialog()">Annuler</div>
    </div>
</div>
