import { Component, OnInit } from '@angular/core';

import { LoginService } from '../../services/login.service';
import { DialogService } from '../../services/dialog.service';

import { Role } from '../../structures/role';

import { isMobile } from 'mobile-device-detect';

@Component({
    selector: 'app-administrator',
    templateUrl: './administrator.component.html',
    styleUrls: ['./administrator.component.scss']
})
export class AdministratorComponent implements OnInit {
    constructor(private loginService: LoginService, private dialogService: DialogService) {}

    permissions = {
        rights: ['nationalAdmin', 'localAdmin'],
        users: ['nationalAdmin', 'localAdmin', 'accountManager'],
        groups: ['nationalAdmin', 'localAdmin', 'accountManager'],
        roleGroups: ['localAdmin', 'accountManager'],
        contacts: ['nationalAdmin', 'localAdmin'],
        structures: ['nationalAdmin'],
        sites: ['localAdmin'],
        history: ['nationalAdmin', 'localAdmin', 'accountManager'],
        unusedElements: ['localAdmin'],
        nationalAdministration: ['nationalAdmin'],
        dashboard: ['nationalAdmin', 'localAdmin'],
        nationalStatistics: ['nationalAdmin'],
        news: ['nationalAdmin', 'localAdmin'],
        activityReport: ['nationalAdmin', 'localAdmin', 'accountManager']
    };

    roles: Array<Role> = [];

    ngOnInit() {
        if (isMobile) {
            this.dialogService.openError(
                "Ce site n'est pas optimisé pour un usage mobile, préférez une navigation sur ordinateur"
            );
        }
    }

    show(view: string) {
        for (const role of this.permissions[view]) {
            if (
                this.loginService.getUser().roles[role] ||
                this.loginService.getUser().additionalRoles[role]
            ) {
                return true;
            }
        }
        return false;
    }
}
