<div class="set-email-screen" [ngClass]="{ 'white-mode': !isDarkTheme() }">
    <div class="left-panel">
        <div class="set-email-header-container">
            <div class="header-welcome-text">Renseigner votre adresse e-mail</div>
            <div class="logo-container">
                <div class="icon-message"></div>
            </div>
        </div>
        <div class="set-email-form-container">
            <form
                class="form"
                [formGroup]="setEmailForm"
                (ngSubmit)="setEmail()"
                autocomplete="off"
            >
                <input
                    type="text"
                    class="input"
                    placeholder="Saisissez votre adresse email"
                    autocomplete="off"
                    required
                    id="email"
                    formControlName="email"
                />
                <input
                    type="text"
                    class="input"
                    placeholder="Confirmer votre adresse email"
                    autocomplete="off"
                    required
                    id="confirmation_email"
                    formControlName="confirmation_email"
                />

                <div class="set-email-button-container">
                    <button class="set-email-button" type="submit">Valider</button>
                </div>
            </form>
            <div class="message-container">
                <div class="error-message-container" *ngIf="errorMessage">
                    <div class="icon-Warning"></div>
                    <div class="error-message-value">
                        {{ errorMessage }}
                    </div>
                </div>
                <div class="success-message-container" *ngIf="successMessage">
                    <div class="icon-Valider"></div>
                    <div class="success-message-value">
                        {{ successMessage }}
                    </div>
                </div>
                <div class="information-message-container" *ngIf="!errorMessage && !successMessage">
                    <div class="information-message-value">
                        <br />
                        Un message de confirmation va être envoyé sur votre adresse email.<br />
                        A la réception de ce message, vous devrez cliquer sur le lien de validation
                        pour activer votre email.<br /><br />

                        Bien que facultative, cette adresse e-mail vous permettra de récupérer votre
                        mot de passe si vous le perdez.<br /><br />

                        Cette page vous sera affichée à chaque connexion jusqu'à validation de votre
                        adresse email.
                    </div>
                </div>
            </div>

            <div class="link-container" (click)="forceNoEmail()">
                Je ne veux pas renseigner d'adresse email
            </div>
        </div>

        <div class="set-email-footer-container">
            <div class="uimm-logo-container">
                <img [src]="getImageBottom()" alt="logo uimm" class="logo-uimm" />
            </div>
            <div class="footer-links">
                <span class="link" (click)="openDiagnostic()">Diagnostic de connexion</span>
                <span class="separator">|</span>
                <span class="link" (click)="goToCGU()">Informations légales</span>
                <span class="separator">|</span>
                <span class="version">{{ getVersion() }}</span>
            </div>
        </div>
    </div>

    <div class="right-panel" [ngStyle]="getRightSidePanelBackground()">
        <img class="anchored-logo" [src]="getAnchoredLogoURL()" />
    </div>
</div>
