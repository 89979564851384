<div class="tm-flex tm-flex-column tm-height-100">
    @if (isViewVisible('no_configuration_found').visible) {
        <p class="tm-text-center tm-color-grey">
            <em
                >Aucune configuration des webservices ypaero trouvée.<br />
                Veuillez vous rapprocher de l'administration local d'easi.</em
            >
        </p>
    }
    @if (isViewVisible('test_webservices_ypareo').visible) {
        <app-configuration-ypareo
            [settings]="settings"
            [collapsed]="isViewVisible('test_webservices_ypareo').collapsed"
        ></app-configuration-ypareo>
    }
    @if (isViewVisible('import_users').visible) {
        <div class="content tm-flex-wrap tm-display-flex">
            <app-users-ypareo></app-users-ypareo>
        </div>
    }
</div>
