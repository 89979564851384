import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { log } from './decorators/log.decorator';
/**
 * Service gérant la configuration de la plateforme
 */
@Injectable({
    providedIn: 'root'
})
export class TokenService {
    constructor(private http: HttpClient) {}

    @log() getTokenList() {
        return this.http.get('/token/list');
    }
    @log() sendToken(tokenid: number, code: string) {
        return this.http.post('/token/user/login', { code, id: tokenid });
    }
}
