import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { Subscription, Observable } from 'rxjs';

import { DialogService } from 'src/app/services/dialog.service';
import { UsersService } from 'src/app/services/users.service';
import { FlashMessageService } from 'src/app/services/flash-message.service';
import { LoginService } from 'src/app/services/login.service';

import { User } from 'src/app/structures/user';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-group-user-entry',
    templateUrl: './group-user-entry.component.html',
    styleUrls: ['./group-user-entry.component.scss']
})
export class GroupUserEntryComponent implements OnDestroy {
    constructor(
        private usersService: UsersService,
        private loginService: LoginService,
        private dialogService: DialogService,
        private flashMessageService: FlashMessageService
    ) {}

    @Input()
    user: User;
    subscriptions$: Subscription = new Subscription();

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    getUserIcon(user: User): string {
        if (user.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (user.roles.learner) {
            return 'icon-apprenant';
        } else if (user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (user.roles.siteTeacher) {
            return 'icon-formateur-site';
        } else if (user.roles.nationalTeacher) {
            return 'icon-auteurnational';
        } else if (user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (user.roles.tutor) {
            return 'icon-tuteurentreprise';
        } else if (user.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (user.roles.nationalAdmin) {
            return 'icon-adminnational';
        } else if (user.roles.externalCallManager) {
            return 'icon-Telephone';
        }
    }

    hasIncompleteEvents(user: User): boolean {
        return !user.events.CGU || !user.events.emailverified || !user.events.passwordverified;
    }

    openUserDialog(user: User): void {
        const iframe: HTMLIFrameElement = document.getElementById(
            'header-container'
        ) as HTMLIFrameElement;
        iframe.contentWindow.postMessage(
            {
                viewUser: user.id
            },
            '*'
        );
    }

    enableUser(user: User) {
        this.dialogService
            .openConfirmationDialog(
                `Activer l\'utilisateur`,
                `Êtes-vous sûr de vouloir activer l'utilisateur ${user.lastname.toUpperCase()} ${
                    user.firstname
                }?`
            )
            .subscribe((data: boolean) => {
                if (data) {
                    this.usersService.enableUser(user).subscribe(() => {
                        user.enabled = true;
                        this.flashMessageService.flash(
                            `<strong>${user.lastname.toUpperCase()} ${
                                user.firstname
                            }</strong> a été activé.`
                        );
                    });
                }
            });
    }

    disableUser(user: User) {
        this.dialogService
            .openConfirmationDialog(
                `Désactiver l\'utilisateur`,
                `Êtes-vous sûr de vouloir désactiver l'utilisateur ${user.lastname.toUpperCase()} ${
                    user.firstname
                }?`
            )
            .subscribe((data: boolean) => {
                if (data) {
                    this.usersService.disableUser(user).subscribe(() => {
                        user.enabled = false;
                        this.flashMessageService.flash(
                            `<strong>${user.lastname.toUpperCase()} ${
                                user.firstname
                            }</strong> a été désactivé.`
                        );
                    });
                }
            });
    }

    loginAs(user: User) {
        this.dialogService
            .openConfirmationDialog(
                `S'identifier en tant que`,
                `Êtes-vous sûr de vouloir vous identifier en tant que <strong>${user.lastname.toUpperCase()} ${
                    user.firstname
                }</strong>?`
            )
            .subscribe((data: any) => {
                if (data) {
                    this.loginService.loginAs(user).subscribe();
                }
            });
    }
}
