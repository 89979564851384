<div id="csv-import-users-dialog">
    <div class="csv-import-users-dialog_header">
        <span class="title">Importer des utilisateurs</span>
        <span class="icon-close" (click)="closeDialog()"></span>
    </div>
    <div class="csv-import-users-dialog_body">
        <div class="description">
            Attention,
            <strong>seuls les modifications et les ajouts</strong>
            d'utilisateurs sont pris en compte.<br />
            <em
                >L'unicité des utilisateurs est garantie par le champ
                <strong>identifiant</strong>.</em
            >
        </div>

        <div class="import-button button" (click)="uploadInput.click()">
            {{ getImportButtonText() }}
            <input
                hidden
                #uploadInput
                type="file"
                accept=".csv"
                (change)="fileChangeEvent($event)"
            />
        </div>

        <div
            class="preview-button button"
            [ngClass]="{
                disabled: !file
            }"
            (click)="previewImport()"
        >
            Prévisualiser et importer ces utilisateurs
        </div>

        <div class="description">Attention à bien respecter les contraintes de format du CSV</div>

        <div class="reference-csv-button button" *ngIf="!file" (click)="downloadReferenceFile()">
            <span class="icon-download"></span>
            Télécharger un csv de référence
        </div>
    </div>
</div>
