import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ConfigService } from '../../../services/config.service';
import { DialogService } from '../../../services/dialog.service';
import { LoginService } from '../../../services/login.service';
import { TokenService } from '../../../services/token.service';

import { Token } from '../../../structures/token';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-token',
    templateUrl: './token.component.html',
    styleUrls: ['./token.component.scss']
})
export class TokenComponent implements OnInit, OnDestroy {
    constructor(
        private configService: ConfigService,
        private dialogService: DialogService,
        private loginService: LoginService,
        private tokenService: TokenService,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private renderer: Renderer2,
        private cookieService: CookieService
    ) {}

    subscriptions: Subscription = new Subscription();

    tokenForm: UntypedFormGroup;

    tokenList: Array<Token>;

    errorMessage: string;

    ngOnInit() {
        this.tokenForm = this.formBuilder.group({
            code: ['', Validators.required]
        });
        this.subscriptions.add(
            this.tokenService.getTokenList().subscribe((data: Array<Token>) => {
                this.tokenList = data.map((token: Token) => {
                    return {
                        key: token.id,
                        title:
                            token.type +
                            ' (ajouté le ' +
                            new Date(token.timecreated).toLocaleString() +
                            ')',
                        selected: data.indexOf(token) === 0
                    };
                });
                if (this.tokenList.length <= 1) {
                    document.getElementById('password').focus();
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    isDarkTheme() {
        return this.cookieService.get('dark') === 'true';
    }

    getImageTop() {
        return this.configService.getConfig().left_image_top_dev;
    }

    getImageBottom() {
        return this.configService.getConfig().left_image_bot;
    }

    getVersion() {
        return this.configService.getConfig().version;
    }

    getRightSidePanelBackground(): any {
        return {
            'background-image': `url(${this.configService.getConfig().right_image})`
        };
    }

    sendToken() {
        this.subscriptions.add(
            this.tokenService
                .sendToken(
                    this.tokenList.find((token) => token.selected).key,
                    this.tokenForm.get('code').value
                )
                .subscribe(
                    () => {
                        this.loginService.clearExceptionAndNavigate();
                    },
                    (error: HttpErrorResponse) => {
                        this.errorMessage = error.error.userMessage;
                    }
                )
        );
    }

    goToCGU() {
        this.router.navigate(['cgu']);
    }

    openDiagnostic() {
        this.dialogService.openDiagnostic(false);
    }

    getAnchoredLogoURL() {
        return this.configService.getConfig().anchored_logo_url;
    }
}
