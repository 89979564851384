import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import Encoding from 'encoding-japanese';

@Component({
    selector: 'app-preview-import-users',
    templateUrl: './preview-import-users.component.html',
    styleUrls: ['./preview-import-users.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewImportUsersComponent implements OnInit {
    /************************************************
     * !! Change Detection ONPUSH (pas automatique) *
     ************************************************/

    fileReader: FileReader;
    fileReader2: FileReader;
    csvData: any;
    isLoading = true;

    encoding: string;

    constructor(
        private cdr: ChangeDetectorRef,
        public dialogRef: MatDialogRef<PreviewImportUsersComponent>,
        @Inject(MAT_DIALOG_DATA)
        private file: File
    ) {
        this.fileReader = new FileReader();
        this.fileReader2 = new FileReader();
    }

    ngOnInit(): void {
        this.detectFileEncoding();
    }

    detectFileEncoding() {
        this.fileReader2.onloadend = (event: any) => {
            this.parseCSVData(event.target.result);
        };
        this.fileReader.onload = (event: any) => {
            this.encoding = Encoding.detect(new Uint8Array(event.target.result as ArrayBuffer));
            if (this.encoding === 'UTF8') {
                this.fileReader2.readAsText(this.file, 'UTF8');
            } else if (this.encoding === 'UNICODE') {
                this.fileReader2.readAsText(this.file, 'ISO-8859-1');
            } else {
                this.fileReader2.readAsText(this.file, this.encoding);
            }
            this.cdr.detectChanges();
        };
        this.fileReader.readAsArrayBuffer(this.file);
    }

    parseCSVData(csv) {
        const allTextLines = csv.split(/\r\n|\n/);
        const headers = allTextLines[0].split(';');
        const lines = [];

        for (let i = 0; i < allTextLines.length; i++) {
            const data = allTextLines[i].split(';');
            if (data.length === headers.length) {
                const tarr = [];
                for (let j = 0; j < headers.length; j++) {
                    if (data[j].charAt(0) === '"' && data[j].charAt(data[j].length - 1) === '"') {
                        tarr.push(data[j].slice(1, data[j].length - 1));
                    } else {
                        tarr.push(data[j]);
                    }
                }
                lines.push(tarr);
            } else if (data.length > 1) {
                lines.push('error');
            }
        }
        this.csvData = lines;
        this.isLoading = false;
        this.cdr.detectChanges();
    }

    /**
     * sépare la string à mettre dans une cellule en un tableau pour marquer les espaces en orange
     * @param str le contenu de la cellule
     */
    getFormatedCellContent(str: string): string[] {
        return str.replace(/\s/g, 'µ$£ µ$£').split('µ$£');
    }

    isValidInput(line, index) {
        if (line[index] === '' && !this.canBeEmpty(line, index)) {
            return false;
        } else if (line[index] !== '' && !this.canBeFilled(line, index)) {
            return false;
        } else {
            return true;
        }
    }

    canBeEmpty(line, index) {
        const user_type = line[this.getIndexForElement('user_type')];
        const ypareo_id = line[this.getIndexForElement('ypareo_id')];
        const email = line[this.getIndexForElement('email')];

        switch (this.csvData[0][index]) {
            case 'ypareo_id':
            case 'title':
            case 'email':
            case 'corporation':
            case 'group':
            case 'enable':
                return true;
            case 'local_structure_id':
            case 'user_type':
            case 'name':
            case 'firstname':
            case 'login':
                return false;
            case 'password':
                if (ypareo_id.length === 0 && email.length === 0) {
                    return false;
                }
                return true;
            case 'site':
                if (user_type.localeCompare('student') === 0) {
                    return false;
                }
                return true;
            case 'student_status':
                if (user_type.localeCompare('student') === 0) {
                    return false;
                }
                return true;
            default:
                return true;
        }
    }

    canBeFilled(line, index) {
        const user_type = line[this.getIndexForElement('user_type')];

        switch (this.csvData[0][index]) {
            case 'ypareo_id':
            case 'local_structure_id':
            case 'user_type':
            case 'title':
            case 'name':
            case 'firstname':
            case 'login':
            case 'password':
            case 'email':
            case 'corporation':
            case 'enable':
                return true;
            case 'site':
                if (user_type.localeCompare('tutor') === 0) {
                    return false;
                }
                return true;
            case 'group':
                if (user_type.localeCompare('tutor') === 0) {
                    return false;
                }
                return true;
            case 'student_status':
                if (
                    user_type.localeCompare('student') !== 0 &&
                    user_type.localeCompare('prospect') !== 0 &&
                    user_type.localeCompare('user_type') !== 0
                ) {
                    return false;
                }
                return true;
            default:
                return true;
        }
    }

    getIndexForElement(element) {
        for (const i in this.csvData[0]) {
            if (this.csvData[0][i].localeCompare(element) === 0) {
                return i;
            }
        }
        return -1;
    }

    import() {
        this.dialogRef.close(true);
    }

    closeDialog(): void {
        this.csvData = [];
        this.dialogRef.close(false);
    }
}
