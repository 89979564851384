import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { shareReplay, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Structure } from '../structures/structure';
import { log } from './decorators/log.decorator';

@Injectable({
    providedIn: 'root'
})
export class StructuresService {
    public structures$: Observable<Structure[]>;

    constructor(private http: HttpClient) {
        this.structures$ = this.getStructures().pipe(shareReplay(1));
    }

    @log() getStructures(): Observable<Array<Structure>> {
        return this.http
            .get<Structure[]>('/structures')
            .pipe(map(this.structuresCallBackForDropdowns));
    }

    @log() createStructure(structure): Observable<any> {
        return this.http.post<Structure[]>('/structures', structure);
    }

    @log() updateStructure(structure): Observable<any> {
        return this.http.put<Structure[]>(`/structures/${structure.id}`, structure);
    }

    structuresCallBackForDropdowns(structuresList: Structure[]): Structure[] {
        return structuresList.map((structure) => ({
            ...structure,
            key: structure.id,
            title: structure.name
        }));
    }
}
