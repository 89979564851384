import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { LoginService } from './services/login.service';

/**
 * Composant principal de l'application
 */
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    subscriptions = new Subscription();

    currentForum: number;
    mouseMoved: boolean;
    isPanelOpen = true;
    loading = true;

    constructor(private loginService: LoginService, private router: Router) {}

    @HostListener('window:message', ['$event']) onMessage(event: any) {
        if (event.data.logout) {
            window.location.href = event.data.logout;
        } else if (event.data.structureid) {
            this.loginService.setLastStructure(event.data);
        } else if (event.data === 'getURL') {
            this.subscriptions.add(
                this.loginService.getCurrentUser().subscribe((data: any) => {
                    if (data === 0) {
                        this.router.navigate(['login']);
                    } else {
                        this.loading = false;
                    }
                })
            );
        }
    }

    @HostListener('window:mousemove', ['$event']) onMouseMoved(event: any) {
        this.mouseMoved = true;
    }

    ngOnInit() {
        this.mouseMoved = false;
        this.pingGetCurrent();
        this.loginService.initCheckConnectedInterval();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    isLoginView() {
        return !this.router.url.includes('admin');
    }

    /**
     * Effectue un appel à getCurrentUser toute les 20 minutes, si l'utilisateur a déplacé au moins une fois sa souris dans les dernières 20 minutes.
     * Cela permet de s'assurer que l'utilisateur ne sera pas déconnecté du serveur pour inactivité.
     */
    pingGetCurrent() {
        setInterval(() => {
            if (this.mouseMoved) {
                this.subscriptions.add(
                    this.loginService.getCurrentUser().subscribe((data: any) => {
                        this.mouseMoved = false;
                    })
                );
            }
        }, 1000 * 20 * 60);
    }
}
