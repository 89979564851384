import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { News } from '../structures/news';
import { log } from './decorators/log.decorator';

@Injectable({
    providedIn: 'root'
})
export class NewsService {
    constructor(private http: HttpClient) {}

    @log() getSentNews(): Observable<any[]> {
        return this.http.get<News[]>(`/news/mine`, { params: { type: 'author' } });
    }

    createEmptyNews(): Observable<number> {
        return this.createNews({
            title: ''
        });
    }

    @log() createNews(news: any): Observable<any> {
        return this.http.post(`/news`, news);
    }

    @log() updateNews(newsToUpdate: any): Observable<any> {
        return this.http.put(`/news/${newsToUpdate.id}`, newsToUpdate);
    }

    @log() deleteNews(news: News): Observable<0 | 1> {
        return this.http.delete<0 | 1>(`/news/${news.id}`);
    }
}
