<div class="container" *ngIf="!isLoading('contactsMainView')">
    <!-- ##### HEADER ##### -->
    <div class="contacts-header">
        <div class="filters-dropdowns-container">
            <lib-uimm-dropdown
                [label]="'Structure'"
                [labelWidth]="'220px'"
                [(items)]="structures"
                (itemsChange)="onFiltersChange()"
                [headers]="{
                    search: true,
                    selectAll: false,
                    count: true
                }"
                *ngIf="isNationalAdmin()"
            ></lib-uimm-dropdown>
            <div class="count-container" *ngIf="structureSelected() !== undefined">
                <div class="count" *ngIf="contacts">
                    <strong>{{ contacts.length }}</strong> contacts
                </div>
            </div>
        </div>
    </div>
    <!-- ##### END HEADER ##### -->

    <div class="contacts-list" *ngIf="structureSelected() !== undefined">
        <app-contact-entry *ngFor="let contact of contacts" [contact]="contact"></app-contact-entry>
    </div>

    <div class="message_empty_structure" *ngIf="structureSelected() === undefined">
        <p>Veuillez sélectionner une structure.</p>
        <div>
            <img src="assets/img/library-default.png" />
        </div>
    </div>
</div>

<div
    class="add-contact-button"
    *ngIf="structureSelected() !== undefined"
    (click)="openCreateContact()"
>
    <div class="icon icon-apprenant" tooltip="Créer un nouveau contact"></div>
</div>

<app-loading *ngIf="isLoading('contactsMainView')"></app-loading>
