import { trigger, transition, animate, style, state, keyframes } from '@angular/animations';

export const slideToggle = trigger('slideToggle', [
    state(
        'open',
        style({
            height: '*'
        })
    ),
    state(
        'closed',
        style({
            height: 0,
            display: 'none'
        })
    ),
    transition('open => closed', [animate('.3s')]),
    transition('closed => open', [
        animate(
            '.3s',
            keyframes([style({ display: '*', offset: 0 }), style({ height: '*', offset: 1 })])
        )
    ])
]);
