<div class="header">
    <span class="label">Liaison microsoft opérationnelle</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<div class="content">
    <div class="message">
        Votre compte Microsoft est maintenant lié à votre compte EASi.<br /><br />
        Nous vous recommandons d'utiliser systématiquement votre compte Microsoft pour vous
        connecter à EASi.<br /><br />
        Vous pourrez modifier ces informations dans votre profil.
    </div>
    <div class="link" (click)="closeDialog()">Continuer</div>
</div>
