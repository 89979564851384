import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';

import { DiagnosticService } from '../../services/diagnostic.service';
import { FlashMessageService } from '../../services/flash-message.service';

import { osName, osVersion } from 'mobile-device-detect';
import * as FileSaver from 'file-saver';
import { EasiHttpParams } from 'src/app/interceptors/easi-http-params';

/**
 * Composant gérant l'affichage de la fenêtre d'erreur
 */
@Component({
    selector: 'app-diagnostic',
    templateUrl: './diagnostic.component.html',
    styleUrls: ['./diagnostic.component.scss']
})
export class DiagnosticComponent implements OnInit {
    browserName: string;
    cookieEnabled: string;
    popupBlocked: string;
    screenResolution: string;
    OSName: string;
    IPAdress: string;

    openHelpUnauthenticated: Subject<any> = new Subject();

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public fromLogin: any,
        public dialogRef: MatDialogRef<DiagnosticComponent>,
        private http: HttpClient,
        private flashMessageService: FlashMessageService,
        private diagnosticService: DiagnosticService
    ) {}

    ngOnInit() {
        this.browserName = this.diagnosticService.getBrowserName();
        this.cookieEnabled = this.diagnosticService.getCookieEnabled();
        this.popupBlocked = this.diagnosticService.getPopupBlocked();
        this.OSName = osName + ' ' + osVersion;
        this.screenResolution =
            window.screen.width * window.devicePixelRatio +
            'x' +
            window.screen.height * window.devicePixelRatio;
        this.http
            .get('https://ip.cblue.be/?raw', {
                responseType: 'text' as 'json',
                params: new EasiHttpParams({ overrideBaseUrl: true, noCredentials: true })
            })
            .subscribe(
                (IPAdress: any) => {
                    this.IPAdress = IPAdress;
                },
                (error: HttpErrorResponse) => {
                    console.error(error);
                }
            );
    }

    openRecommandedConfiguration() {
        this.openHelpUnauthenticated.next();
    }

    isBrowserOK() {
        return this.diagnosticService.isBrowserOK();
    }

    isPopupOK() {
        return this.diagnosticService.isPopupOK();
    }

    isCookieOK() {
        return this.diagnosticService.isCookieOK();
    }

    exportDiagnostic() {
        const dateTime = new Date().toLocaleDateString('fr-FR', {
            formatMatcher: 'best fit'
        });
        const reportText =
            'DIAGNOSTIC DU ' +
            dateTime +
            '\r\n' +
            (!this.isBrowserOK() ? '[WARN] ' : '') +
            'Version du navigateur: ' +
            this.browserName +
            '\r\n' +
            (!this.isPopupOK() ? '[WARN] ' : '') +
            'Bloqueur de pop-up: ' +
            this.popupBlocked +
            '\r\n' +
            (!this.isCookieOK() ? '[WARN] ' : '') +
            'Cookies: ' +
            this.cookieEnabled +
            '\r\n' +
            'Affichage: ' +
            this.screenResolution +
            '\r\n' +
            'Système d’exploitation: ' +
            this.OSName +
            '\r\n' +
            'Adresse IP: ' +
            this.IPAdress;
        const csvName = 'diagnostic_' + dateTime + '.txt';
        const blob = new Blob([reportText], {
            type: 'text/plain;charset=utf-8;'
        });
        FileSaver.saveAs(blob, csvName);

        this.flashMessageService.flash("L'export du diagnostic est terminé");
    }

    openEasiCallCheckConfig() {
        if (window.location.href.indexOf('dev.connect.easi-training') > -1) {
            window.open('https://dev.call.easi-training.fr/checkconfig.html');
        } else if (window.location.href.indexOf('pilot.connect.easi-training') > -1) {
            window.open('https://pilot.call.easi-training.fr/checkconfig.html');
        } else if (window.location.href.indexOf('connect.easi-training') > -1) {
            window.open('https://call.easi-training.fr/checkconfig.html');
        } else if (window.location.href.indexOf('localhost') > -1) {
            window.open('https://dev.call.easi-training.fr/checkconfig.html');
        }
    }

    startLogin() {
        this.dialogRef.close(true);
    }

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close(false);
    }
}
