<mat-accordion>
    <mat-expansion-panel [expanded]="!collapsed()" (opened)="handleOpenExpandPanel()">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <h1 class="tm-text-center">Configuration webservices ypareo</h1>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="tm-description tm-text-center">
            <p>Veuillez saisir l'adresse des webservices ypareo et la clé d'identification</p>
        </div>
        <div class="tm-form">
            <form (ngSubmit)="testYpareoImport()" #ypareoForm="ngForm">
                <div class="tm-flex tm-flex-row tm-justify-between tm-align-center tm-gap-2">
                    <div class="tm-flex tm-flex-column tm-gap">
                        <div class="tm-flex tm-flex-row tm-justify-between tm-align-center tm-gap">
                            <div class="tm-field-icon">
                                <span class="icon icon-UserYPareo tm-icon"></span>
                            </div>
                            <mat-form-field class="tm-form-field">
                                <mat-label>Url des webservices YPAREO</mat-label>
                                <input
                                    matInput
                                    type="text"
                                    name="ypareo_url_webservices"
                                    [(ngModel)]="settings().ypareo_base_url"
                                    (ngModelChange)="resetTest()"
                                />
                            </mat-form-field>
                            <div class="tm-field-icon">
                                <mat-icon
                                    [tooltip]="
                                        'Url d’accès à votre environnement NetYParéo (Contacter Ymag en cas de difficulté)'
                                    "
                                    [tooltipColor]="'red'"
                                    [tooltipPosition]="'above'"
                                >
                                    help
                                </mat-icon>
                            </div>
                        </div>
                        <div class="tm-flex tm-flex-row tm-justify-between tm-align-center tm-gap">
                            <div class="tm-field-icon">
                                <span class="icon icon-Restreindre tm-icon"></span>
                            </div>
                            <mat-form-field class="tm-form-field">
                                <mat-label>Jeton d'identification</mat-label>
                                <input
                                    matInput
                                    type="text"
                                    name="ypareo_token"
                                    [(ngModel)]="settings().ypareo_token"
                                    (ngModelChange)="resetTest()"
                                />
                            </mat-form-field>
                            <mat-icon
                                [tooltip]="
                                    'Vous pouvez générer ou retrouver ce jeton à n\'importe quel moment dans l\'interface du module \'Prestataire webservices REST\' de l\'administration Ypareo (Contacter Ymag en cas de difficulté)'
                                "
                                [tooltipColor]="'red'"
                                [tooltipPosition]="'above'"
                            >
                                help
                            </mat-icon>
                        </div>
                    </div>
                    <div class="tm-column-expand">
                        <div class="tm-flex tm-flex-row tm-align-center tm-gap">
                            <button
                                mat-flat-button
                                type="submit"
                                [ngClass]="{
                                    'tm-button--processing': isTestProcessing(),
                                    'tm-button--success': isTestValid(),
                                    'tm-button--error': isTestInvalid()
                                }"
                                [disabled]="
                                    isTestProcessing() || isTestValid() || !ypareoForm.valid
                                "
                            >
                                <span class="tm-button-text">Enregistrer</span>
                                @if (isTestProcessing()) {
                                    <span class="tm-loading-icon"></span>
                                }
                            </button>
                            <div id="tm-testYpareoImport-results">
                                @if (isTestValid()) {
                                    <button type="button" class="tm-button tm-color-success">
                                        <span class="icon icon-Valide tm-icon"></span>
                                    </button>
                                }
                                @if (isTestInvalid()) {
                                    <button type="button" class="tm-button tm-color-error">
                                        <span
                                            class="icon icon-Fermerdetails tm-icon"
                                            [tooltip]="error"
                                            [tooltipPosition]="'above'"
                                        ></span>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </mat-expansion-panel>
</mat-accordion>
