<div class="validation-email" [ngClass]="{ 'white-mode': !isDarkTheme() }">
    <div class="message-container validate-email-view">
        <div class="success-message-container success-message-mail" *ngIf="successMessage !== ''">
            <img
                src="https://cdn.easi-training.fr/assets/img/connect/icon_confirm_mail.png"
                alt="Logo de la confirmation d'email."
                class="img-wrap img-confirm-mail"
            />
            <div class="success-message-mail" [innerHTML]="successMessage"></div>
            <button class="button-redir-easi" type="submit" (click)="redirectOnEasi()">
                <strong>Poursuivre sur easi</strong>
            </button>
        </div>
        <div class="success-message-container success-message-mail" *ngIf="successMessage === ''">
            <button class="button-redir-easi" type="submit" (click)="logout()">
                <strong>Revenir sur la page de connexion</strong>
            </button>
        </div>
    </div>
</div>
