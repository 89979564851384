<div *ngIf="!isLoading()">
    <div class="national-administrators">
        <div class="national-admins-list">
            <div
                class="user"
                *ngFor="let user of administratorsList"
                [ngClass]="{
                    'user-disabled': !user.enabled
                }"
            >
                <div class="user-description">
                    <span
                        class="icon-adminnational"
                        tooltip="{{ getRoleLabel('nationalAdmin') }}"
                        tooltipPosition="right"
                    ></span
                    >{{ user.lastname.toUpperCase() }}
                    {{ user.firstname }}
                </div>
                <div class="user-icons">
                    <div class="dynamic-icons">
                        <span
                            class="icon-FicheUser"
                            *ngIf="!hasIncompleteEvents(user)"
                            (click)="openUserDialog(user)"
                            tooltip="Voir la fiche"
                        ></span>
                        <span
                            class="icon-DesactiverUser"
                            *ngIf="user.enabled"
                            tooltip="Désactiver cet utilisateur"
                            (click)="disableUser(user)"
                        ></span>
                        <span
                            class="icon-Reactiver"
                            *ngIf="!user.enabled"
                            tooltip="Activer cet utilisateur"
                            (click)="enableUser(user)"
                        ></span>
                    </div>
                    <div class="fix-icons">
                        <span
                            class="icon-FicheUser with-warning"
                            *ngIf="hasIncompleteEvents(user)"
                            (click)="openUserDialog(user)"
                            tooltip="Activation du compte non finalisée : consulter la fiche pour plus d'information"
                        ></span>
                        <span
                            class="icon-DesactiverUser"
                            *ngIf="!user.enabled"
                            tooltip="Utilisateur désactivé"
                        ></span>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="create-user-button"
            (click)="createUser($event)"
            tooltip="Créer un nouvel utilisateur"
        >
            <span class="icon-plus"></span>
        </div>
    </div>
</div>

<app-loading *ngIf="isLoading()"></app-loading>
