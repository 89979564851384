// Internal dependencies
import { Component, OnInit } from '@angular/core';

// External dependencies

// Modules
import { SharedModule } from '@/modules/shared.module';

// Components
import { ConfigurationYpareoComponent } from './configuration-ypareo/configuration-ypareo.component';
import { UsersYpareoComponent } from './users-ypareo/users-ypareo.component';

// Services
import { YpareoService } from '@/services/ypareo.service';
import { LoginService } from '@/services/login.service';

// Interfaces

// Pipes

interface Setting {
    key: string;
    value: string;
    description: string;
    setting_type: string;
    metadata: any;
}

interface ParsedSetting {
    [key: string]: string | boolean;
}

@Component({
    selector: 'app-import-ypareo',
    templateUrl: './import-ypareo.component.html',
    styleUrls: ['./import-ypareo.component.scss'],
    imports: [SharedModule, ConfigurationYpareoComponent, UsersYpareoComponent]
})
export class ImportYpareoComponent implements OnInit {
    search: string;
    settings: ParsedSetting;

    constructor(
        private loginService: LoginService,
        private ypareoService: YpareoService
    ) {}

    ngOnInit() {
        this.ypareoService
            .fetchSettings(this.loginService.getUser().structureid)
            .subscribe((data: Array<Setting>) => {
                this.settings = data.reduce((acc: ParsedSetting, item: Setting) => {
                    acc[item.key] = item.value;
                    return acc;
                }, {});
                if (this.settings.ypareo_base_url && this.settings.ypareo_token) {
                    this.settings.confirmed = true;
                }
            });
    }

    // #region Getters

    displayNoConfigurationWarning(): boolean {
        return (
            !(this.settings.ypareo_base_url && this.settings.ypareo_token) &&
            this.loginService.getUser().additionalRoles.accountManager
        );
    }

    isConfigurationVisible(): boolean {
        return this.loginService.getUser().roles.localAdmin;
    }

    isUsersVisible() {
        return (
            this.settings.ypareo_base_url && this.settings.ypareo_token && this.settings.confirmed
        );
    }

    isConfigurationCollapsed(): boolean {
        return (
            !(this.isConfigurationVisible() && !this.isUsersVisible()) && !!this.settings.confirmed
        );
    }

    // #endregion Getters

    // #region Handlers

    // #endregion Handlers

    // #region Internals

    // #endregion Internals
}
