import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DefaultGuard } from '../guards/default.guard';
import { AdminGuard } from '../guards/admin.guard';
import { LoginGuard } from '../guards/login.guard';

import { AdministratorComponent } from '../components/administrator/administrator.component';

import { ActivityReportComponent } from '../components/administrator/activity-report/activity-report.component';
import { GraphsComponent } from '../components/administrator/graphs/graphs.component';
import { GroupsComponent } from '../components/administrator/groups/groups.component';
import { RoleGroupsComponent } from '../components/administrator/role-groups/role-groups.component';

import { ContactsComponent } from '../components/administrator/contacts/contacts.component';
import { HistoryComponent } from '../components/administrator/history/history.component';
import { NationalAdministrationComponent } from '../components/administrator/national-administration/national-administration.component';
import { NationalDashboardComponent } from '../components/administrator/national-dashboard/national-dashboard.component';
import { NewsEditorComponent } from '../components/administrator/news-editor/news-editor.component';
import { RightsComponent } from '../components/administrator/rights/rights.component';
import { StructuresComponent } from '../components/administrator/structures/structures.component';
import { SitesComponent } from '../components/administrator/sites/sites.component';
import { UnusedElementsComponent } from '../components/administrator/unused-elements/unused-elements.component';
import { UsersComponent } from '../components/administrator/users/users.component';
import { LocalDashboardComponent } from '../components/administrator/local-dashboard/local-dashboard.component';

import { CGUComponent } from '../components/login/cgu/cgu.component';
import { LoginComponent } from '../components/login/login/login.component';
import { ResendPasswordComponent } from '../components/login/resend-password/resend-password.component';
import { SetEmailComponent } from '../components/login/set-email/set-email.component';
import { SetPasswordComponent } from '../components/login/set-password/set-password.component';
import { TokenComponent } from '../components/login/token/token.component';
import { UpdateEmailComponent } from '../components/login/update-email/update-email.component';
import { UpdatePasswordComponent } from '../components/login/update-password/update-password.component';
import { ValidateEmailComponent } from '../components/login/validate-email/validate-email.component';
import { QueuedReportComponent } from '../components/administrator/activity-report/queued-report/queued-report.component';

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [DefaultGuard, LoginGuard]
    },
    {
        path: 'setPassword',
        component: SetPasswordComponent,
        canActivate: [DefaultGuard, LoginGuard]
    },
    {
        path: 'updatePassword',
        component: UpdatePasswordComponent,
        canActivate: [DefaultGuard, LoginGuard]
    },
    {
        path: 'resendPassword',
        component: ResendPasswordComponent,
        canActivate: [DefaultGuard, LoginGuard]
    },
    {
        path: 'setEmail',
        component: SetEmailComponent,
        canActivate: [DefaultGuard, LoginGuard]
    },
    {
        path: 'updateEmail',
        component: UpdateEmailComponent,
        canActivate: [DefaultGuard, LoginGuard]
    },
    {
        path: 'validateEmail',
        component: ValidateEmailComponent,
        canActivate: [DefaultGuard, LoginGuard]
    },
    {
        path: 'token',
        component: TokenComponent,
        canActivate: [DefaultGuard, LoginGuard]
    },
    {
        path: 'cgu',
        component: CGUComponent,
        canActivate: [DefaultGuard, LoginGuard]
    },
    {
        path: 'admin',
        component: AdministratorComponent,
        canActivate: [DefaultGuard, AdminGuard],
        canActivateChild: [AdminGuard],
        children: [
            {
                path: 'rights',
                component: RightsComponent
            },
            {
                path: 'users',
                component: UsersComponent
            },
            {
                path: 'groups',
                component: GroupsComponent
            },
            {
                path: 'roleGroups',
                component: RoleGroupsComponent
            },
            {
                path: 'contacts',
                component: ContactsComponent
            },
            {
                path: 'structures',
                component: StructuresComponent
            },
            {
                path: 'unusedElements',
                component: UnusedElementsComponent
            },
            {
                path: 'sites',
                component: SitesComponent
            },
            {
                path: 'history',
                component: HistoryComponent
            },
            {
                path: 'news',
                component: NewsEditorComponent
            },
            {
                path: 'nationalAdministration',
                component: NationalAdministrationComponent
            },
            {
                path: 'nationalStatistics',
                component: NationalDashboardComponent
            },
            {
                path: 'dashboard',
                component: LocalDashboardComponent
            },
            {
                path: 'graphs',
                component: GraphsComponent
            },
            {
                path: 'activityReport',
                component: ActivityReportComponent
            },
            {
                path: 'activityReport/tasks/:id',
                component: QueuedReportComponent
            }
        ]
    },
    { path: '', redirectTo: '/admin/users', pathMatch: 'full' },
    { path: '**', redirectTo: '/admin/users', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class RoutingModule {}
