<div *ngIf="!isLoading()">
    <div class="header-unused-element">
        <div>
            <div
                class="header-container"
                (click)="changeCurrentView('sites')"
                [ngClass]="{
                    'not-active': !isCurrentView('sites')
                }"
            >
                <div class="icon-site"></div>
                <div class="label">
                    {{ sites.length }}
                    sites
                </div>
            </div>
            <div
                class="header-container"
                (click)="changeCurrentView('corporations')"
                [ngClass]="{
                    'not-active': !isCurrentView('corporations')
                }"
            >
                <div class="icon-corporation"></div>
                <div class="label">
                    {{ corporations.length }}
                    entreprises
                </div>
            </div>
            <div
                class="header-container"
                (click)="changeCurrentView('groups')"
                [ngClass]="{
                    'not-active': !isCurrentView('groups')
                }"
            >
                <div class="icon-groupe"></div>
                <div class="label">
                    {{ groups.length }}
                    groupes
                </div>
            </div>
        </div>
        <div class="delete-all" (click)="deleteAllElements()">
            {{ getDeleteAllLabel() }}
        </div>
    </div>
    <div
        class="container-unused-element"
        [ngClass]="{
            hidden: !isCurrentView('sites')
        }"
    >
        <div class="container" *ngFor="let site of sites">
            <div>
                <div class="icon-site"></div>
                <div class="label">
                    {{ site.name }}
                </div>
            </div>
            <div
                class="icon-Fermerdetails"
                (click)="deleteElement(site)"
                [tooltip]="'Supprimer ce site'"
            ></div>
        </div>
    </div>
    <div
        class="container-unused-element"
        [ngClass]="{
            hidden: !isCurrentView('corporations')
        }"
    >
        <div class="container" *ngFor="let corporation of corporations">
            <div>
                <div class="icon-corporation"></div>
                <div class="label">
                    {{ corporation.name }}
                </div>
            </div>
            <div
                class="icon-Fermerdetails"
                (click)="deleteElement(corporation)"
                [tooltip]="'Supprimer cette entreprise'"
            ></div>
        </div>
    </div>
    <div
        class="container-unused-element"
        [ngClass]="{
            hidden: !isCurrentView('groups')
        }"
    >
        <div class="container" *ngFor="let group of groups">
            <div>
                <div class="icon-groupe"></div>
                <div class="label">
                    {{ group.name }}
                </div>
            </div>
            <div
                class="icon-Fermerdetails"
                (click)="deleteElement(group)"
                [tooltip]="'Supprimer ce groupe'"
            ></div>
        </div>
    </div>
</div>

<app-loading *ngIf="isLoading()"></app-loading>
