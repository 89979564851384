<div class="container" *ngIf="!isLoading('roleGroupsMainView')">
    <div class="header">
        <div class="count">
            <strong>{{ roleGroups.length }}</strong> groupes
        </div>
        <div class="search-container">
            <input
                type="search"
                class="search"
                placeholder="Chercher..."
                [(ngModel)]="searchTerm"
                (keyup.enter)="getRoleGroups()"
            />
            <span class="icon-search" (click)="getRoleGroups()"></span>
        </div>
    </div>

    <div class="role-groups-list">
        <app-role-group-entry
            *ngFor="let roleGroup of roleGroups"
            [roleGroup]="roleGroup"
        ></app-role-group-entry>
    </div>

    <app-loading *ngIf="isLoading('roleGroupsNextPage')"></app-loading>
</div>

<div class="add-role-group-button" (click)="openCreateRoleGroup($event)">
    <div
        class="icon-porte-voix"
        tooltip="Créer un nouveau groupe de rôles"
        tooltipColor="#199fe2"
    ></div>
</div>

<app-loading *ngIf="isLoading('roleGroupsMainView')"></app-loading>
