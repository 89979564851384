<div class="tm-user tm-flex tm-gap-1">
    <div>
        <mat-checkbox
            (click)="handleSelectUser()"
            [checked]="user().selected"
            [disabled]="!!user().to_synchronize || user().display_status === 'up_to_date'"
        >
        </mat-checkbox>
    </div>

    <div class="icon-role">
        @if (user().user_type) {
            <span
                [ngClass]="getUserRole().icon"
                [tooltip]="getUserRole().name"
                tooltipPosition="right"
            ></span>
        }
    </div>
    <div>
        {{ user().lastname.toUpperCase() }}
        {{ user().firstname }}
    </div>
    <div>
        @if (user().display_status === 'new_user') {
            {{ user().group }}
        }
    </div>
    <div>
        @if (user().display_status !== 'new_user') {
            {{ user().group }}
        }
    </div>
    <div class="tm-flex tm-align-center tm-justify-end tm-gap">
        @if (user().display_status === 'up_to_date') {
            <div class="icon-logoeasi"></div>
        } @else {
            <button
                mat-flat-button
                class="tm-button--red"
                (click)="handleSynchronizeUser()"
                [disabled]="!!user().to_synchronize"
            >
                {{
                    user().to_synchronize
                        ? 'Synchronisation'
                        : user().display_status === 'needs_update'
                          ? 'Mettre à jour'
                          : 'Importer'
                }}
            </button>
            @if (user().display_status === 'needs_update') {
                <div class="icon-misajour"></div>
            } @else if (user().display_status === 'new_user') {
                <div class="icon-nouveau"></div>
            }
            @if (user().failed_to_synchronize) {
                <mat-icon
                    [tooltip]="'La synchronisation de l\'utilisateur a échoué'"
                    [color]="'red'"
                >
                    warning
                </mat-icon>
            }
        }
    </div>
</div>
