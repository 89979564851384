import { Component, OnInit, Input } from '@angular/core';
import { Subscription, Observable } from 'rxjs';

import { DialogService } from '../../../../services/dialog.service';
import { GroupsService } from '../../../../services/groups.service';
import { FlashMessageService } from '../../../../services/flash-message.service';

import { Group } from '../../../../structures/group';

@Component({
    selector: 'app-role-group-entry',
    templateUrl: './role-group-entry.component.html',
    styleUrls: ['./role-group-entry.component.scss']
})
export class RoleGroupEntryComponent {
    constructor(
        private dialogService: DialogService,
        private groupsService: GroupsService,
        private flashMessageService: FlashMessageService
    ) {}

    @Input() public roleGroup: Group;

    getRoleGroupCounter(): string {
        let string = '(';
        string += ` ${this.roleGroup.roles.length} roles`;
        if (this.roleGroup.sites.length) {
            string += `, ${this.roleGroup.sites.length} sites`;
        }
        string += ')';
        return string;
    }

    editRoleGroup($event: Event) {
        $event.stopImmediatePropagation();
        this.dialogService.openCreateRoleGroup(this.roleGroup).subscribe((roleGroup) => {
            if (roleGroup) {
                this.groupsService.emitEditedRoleGroup();
            }
        });
    }

    cloneRoleGroup($event: Event) {
        $event.stopImmediatePropagation();
        this.groupsService.cloneRoleGroup(this.roleGroup.id).subscribe((groupReceived: Group) => {
            groupReceived.sites = this.roleGroup.sites;
            groupReceived.roles = this.roleGroup.roles;
            this.groupsService.emitClonedRoleGroup(groupReceived);
        });
    }

    deleteRoleGroup($event: Event) {
        $event.stopImmediatePropagation();
        this.dialogService
            .openConfirmationDialog(
                'Supprimer le groupe de rôle',
                `Êtes vous sûr de vouloir supprimer le groupe de rôle ${this.roleGroup.name} ?`
            )
            .subscribe((confirmation) => {
                if (confirmation) {
                    this.groupsService.deleteRoleGroup(this.roleGroup.id).subscribe(() => {
                        this.groupsService.emitDeletedRoleGroup(this.roleGroup.id);
                        this.flashMessageService.flash('Le groupe de rôle a bien été supprimé');
                    });
                }
            });
    }
}
