import { Component, OnInit, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogConfig,
    MatDialog
} from '@angular/material/dialog';

import { tinyConfig } from '../../../assets/config/tiny-mce-config';
import { Subscription, Observable } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';
import { UsersService } from 'src/app/services/users.service';
import { NewsService } from 'src/app/services/news.service';
import { ConfigService } from 'src/app/services/config.service';

import { StructuresService } from 'src/app/services/structures.service';

import { UploadFileComponent } from '../upload-file/upload-file.component';

import { Role } from 'src/app/structures/role';
import { Structure } from 'src/app/structures/structure';
import { News } from '../../structures/news';

@Component({
    selector: 'app-add-news',
    templateUrl: './add-news.component.html',
    styleUrls: ['./add-news.component.scss']
})
export class AddNewsComponent implements OnInit {
    tinyConfig: any;
    roles: Role[];
    tags: any[];
    subscriptions$: Subscription = new Subscription();
    isCreationMode = false;
    structures: Structure[];

    constructor(
        public dialogRef: MatDialogRef<AddNewsComponent>,
        private uploadDialog: MatDialog,
        private loginService: LoginService,
        private usersService: UsersService,
        private newsService: NewsService,
        private configService: ConfigService,
        private structuresService: StructuresService,
        private sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA)
        public news?: News
    ) {
        this.tinyConfig = tinyConfig;
    }

    ngOnInit(): void {
        if (!this.news.id) {
            this.newsService.createEmptyNews().subscribe((newsCreatedID: number) => {
                this.news.id = newsCreatedID;
                this.news.created_at = new Date();
                this.news.description = '';
                this.news.targets = [];
                this.isCreationMode = true;
            });
        } else {
            this.news.description = this.news.clean_description;
        }
        const structuresIds: number[] = this.news.id
            ? this.news.structures
            : [this.loginService.getUser().structureid];
        this.subscriptions$.add(
            this.structuresService.getStructures().subscribe((list: Structure[]) => {
                this.structures = list.map((structure: Structure) => ({
                    ...structure,
                    key: structure.id,
                    title: structure.name,
                    selected: structuresIds.some((id) => id === structure.id)
                }));
            })
        );

        const importCallBack = (editor) => {
            this.openUploadFile().subscribe((url) => {
                editor.insertContent('<img src="' + url + '" />');
            });
        };
        this.tinyConfig = {
            ...this.tinyConfig,
            setup: function (editor) {
                this.editor = editor;
                editor.ui.registry.addButton('mybutton_format', {
                    tooltip: 'Supprimer la mise en forme',
                    icon: 'remove',
                    onAction: () => {
                        editor.execCommand('RemoveFormat');
                    }
                });
                editor.ui.registry.addButton('mybutton_image', {
                    tooltip: 'Importer une image',
                    icon: 'edit-image',
                    onAction: () => importCallBack(editor)
                });
            }
        };
        this.subscriptions$.add(
            this.usersService.getRolesAndTags().subscribe((data: Role[]) => {
                this.roles = data;
            })
        );
        this.subscriptions$.add(
            this.usersService.getTags().subscribe((data) => {
                this.tags = data;
            })
        );
    }

    isNationalAdmin(): boolean {
        return this.loginService.getUser().roles.nationalAdmin;
    }

    isSelected(type: string, name: string): boolean {
        for (const i in this.news.targets) {
            if (
                this.news.targets[i].type === type &&
                this.news.targets[i].instance_id === this.getRoleId(type, name)
            ) {
                return true;
            }
        }
        return false;
    }

    getRoleId(type: string, name: string): number {
        if (type === 'role') {
            for (const i in this.roles) {
                if (this.roles[i].shortname === name) {
                    return this.roles[i].id;
                }
            }
        } else {
            for (const j in this.tags) {
                if (this.tags[j].name === name) {
                    return this.tags[j].id;
                }
            }
        }
    }

    getRoleLabel(role: string) {
        for (const i in this.roles) {
            if (this.roles[i].shortname === role) {
                return this.roles[i].name;
            }
        }
    }

    toggleDiffusionList(type: string, value) {
        let found = -1;
        for (const i in this.news.targets) {
            if (
                this.news.targets[i].type === type &&
                this.news.targets[i].instance_id === this.getRoleId(type, value)
            ) {
                found = +i;
            }
        }

        if (found === -1) {
            this.news.targets.push({
                type: type,
                instance_id: this.getRoleId(type, value)
            });
        } else {
            this.news.targets.splice(found, 1);
        }
    }

    getNewsDate(): string {
        if (this.news.updated_at) {
            return new Date(this.news.updated_at).toLocaleString();
        } else {
            return new Date(this.news.created_at).toLocaleString();
        }
    }

    getSelectedStructures(): Structure[] {
        if (this.structures) {
            return this.structures.filter((el) => el.selected);
        }
        return [];
    }

    getDropdownLabel(): string {
        switch (this.getSelectedStructures().length) {
            case 1:
                return this.getSelectedStructures()[0].name;
            case 0:
                return 'Structure';
            default:
                return 'Structures';
        }
    }

    /**
     * Ouvre la popup de chargement de fichier, sans passer par le dialogService pour éviter l'injection de dépendances circulaire
     */
    openUploadFile(): Observable<any> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {
            ...this.news
        };

        const ref: MatDialogRef<UploadFileComponent> = this.uploadDialog.open(
            UploadFileComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    canPublish(): boolean {
        return !!this.news.title && !!this.news.description && !!this.news.id;
    }

    publish() {
        this.news.structures = [...this.getSelectedStructures().map((st) => st.id)];
        if (
            (this.news.structures.length === 0 && this.isNationalAdmin()) ||
            !this.structures.some((st) => !st.selected)
        ) {
            this.news.structures = [];
        }
        this.news.targets = this.news.targets;
        this.subscriptions$.add(
            this.newsService.updateNews(this.news).subscribe((data: any) => {
                this.news.description = this.sanitizer.bypassSecurityTrustHtml(
                    this.news.description
                );
                this.news.author_fullname = `${this.loginService
                    .getUser()
                    .lastname.toUpperCase()} ${this.loginService.getUser().firstname}`;
                this.news.author_id = this.loginService.getUser().id;
                this.dialogRef.close(this.news);
            })
        );
    }

    getAPIKey() {
        return this.configService.getConfig().tinyMCE_api_key;
    }

    exit() {
        if (this.isCreationMode) {
            this.newsService.deleteNews(this.news).subscribe((data) => {
                this.dialogRef.close(false);
            });
        } else {
            this.dialogRef.close(false);
        }
    }
}
