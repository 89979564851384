<div class="update-password-screen" [ngClass]="{ 'white-mode': !isDarkTheme() }">
    <div class="left-panel">
        <div class="update-password-header-container">
            <div class="header-welcome-text">Modifier le mot de passe de votre compte</div>
            <div class="logo-container">
                <div class="icon-cadenas"></div>
            </div>
        </div>
        <div class="update-password-form-container">
            <form
                class="form"
                [formGroup]="updatePasswordForm"
                (ngSubmit)="updatePassword()"
                autocomplete="off"
            >
                <input
                    type="password"
                    class="input"
                    placeholder="Ancien mot de passe"
                    autocomplete="off"
                    required
                    id="password"
                    formControlName="oldpassword"
                />
                <input
                    type="password"
                    class="input"
                    placeholder="Nouveau mot de passe"
                    autocomplete="off"
                    required
                    id="password"
                    formControlName="newpassword"
                />
                <input
                    type="password"
                    class="input"
                    placeholder="Entrez à nouveau votre mot de passe"
                    autocomplete="off"
                    required
                    id="confirmation_password"
                    formControlName="confirmation_newpassword"
                />
                <div class="update-password-button-container">
                    <button class="update-password-button" type="submit">Valider</button>
                </div>
            </form>
            <div class="message-container">
                <div class="error-message-container" *ngIf="errorMessage">
                    <div class="icon-Warning"></div>
                    <div class="error-message-value">
                        {{ errorMessage }}
                    </div>
                </div>
                <div class="success-message-container" *ngIf="successMessage">
                    <div class="icon-Valider"></div>
                    <div class="success-message-value">
                        {{ successMessage }}
                    </div>
                </div>
                <div class="information-message-container" *ngIf="!errorMessage && !successMessage">
                    <div class="information-message-value">
                        <br />
                        Votre mot de passe doit respecter les critères suivants :
                        <ul>
                            <li>8 caractères minimum</li>
                            <li>
                                Au moins 3 des 4 conditions suivantes :
                                <ul>
                                    <li>majuscules</li>
                                    <li>minuscules</li>
                                    <li>chiffres</li>
                                    <li>caractères spéciaux ($ / # &#64; £ ...)</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="update-password-footer-container">
            <div class="uimm-logo-container">
                <img [src]="getImageBottom()" alt="logo uimm" class="logo-uimm" />
            </div>
            <div class="footer-links">
                <span class="link" (click)="openDiagnostic()">Diagnostic de connexion</span>
                <span class="separator">|</span>
                <span class="link" (click)="goToCGU()">Informations légales</span>
                <span class="separator">|</span>
                <span class="version">{{ getVersion() }}</span>
            </div>
        </div>
    </div>

    <div class="right-panel" [ngStyle]="getRightSidePanelBackground()">
        <img class="anchored-logo" [src]="getAnchoredLogoURL()" />
    </div>
</div>
