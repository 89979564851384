<div class="screen-container" [ngClass]="{ 'white-mode': !isDarkTheme() }">
    <div class="left-panel">
        <div class="header-container">
            <div class="header-welcome-text">Saisie du token d'authentification</div>
            <div class="logo-container">
                <img [src]="getImageTop()" alt="logo-easi" class="logo-easi" />
            </div>
        </div>
        <div class="form-container">
            <form class="form" [formGroup]="tokenForm" (ngSubmit)="sendToken()" autocomplete="off">
                <lib-uimm-dropdown
                    [(items)]="tokenList"
                    [label]="'Sélectionnez votre token...'"
                    [uniformWidth]="true"
                    [labelColor]="isDarkTheme() ? '#f1f2f4' : '#045f7d'"
                ></lib-uimm-dropdown>
                <input
                    type="text"
                    class="input"
                    placeholder="Entrer votre code d'authentification"
                    autocomplete="off"
                    required
                    id="password"
                    formControlName="code"
                />
                <div class="button-container">
                    <button class="button" type="submit">Connexion</button>
                </div>
            </form>
            <div class="message-container">
                <div class="error-message-container" *ngIf="errorMessage">
                    <div class="icon-Warning"></div>
                    <div class="error-message-value">
                        {{ errorMessage }}
                    </div>
                </div>
                <div class="information-message-container" *ngIf="!errorMessage">
                    <div class="information-message-value">
                        <br />
                        Veuillez sélectionner votre type d'authentification à double facteur dans la
                        liste déroulante.<br />
                        Entrez ensuite votre code dans le champ juste en-dessous afin de vous
                        authentifier complétement.
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-container">
            <div class="uimm-logo-container">
                <img [src]="getImageBottom()" alt="logo uimm" class="logo-uimm" />
            </div>
            <div class="footer-links">
                <span class="link" (click)="openDiagnostic()">Diagnostic de connexion</span>
                <span class="separator">|</span>
                <span class="link" (click)="goToCGU()">Informations légales</span>
                <span class="separator">|</span>
                <span class="version">{{ getVersion() }}</span>
            </div>
        </div>
    </div>

    <div class="right-panel" [ngStyle]="getRightSidePanelBackground()">
        <img class="anchored-logo" [src]="getAnchoredLogoURL()" />
    </div>
</div>
