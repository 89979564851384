<div class="component-graph-container">
    <ngx-charts-line-chart
        *ngIf="(graphData | async) && (graphData | async)?.total.length > 0"
        #graphs
        [view]="view"
        [scheme]="colorScheme"
        [legend]="true"
        [legendTitle]="false"
        [showGridLines]="true"
        [legendPosition]="'below'"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [xAxisTickFormatting]="xAxisTickFormatting"
        [xAxisTicks]="(graphData | async)?.total.length > 0 ? xAxisTicks : []"
        [rotateXAxisTicks]="true"
        [roundDomains]="true"
        [timeline]="false"
        [results]="
            connexionType === 'uniques' ? (graphData | async)?.uniques : (graphData | async)?.total
        "
        (select)="onSelect($event)"
        (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)"
    >
        <ng-template #tooltipTemplate let-model="model">
            <div class="tooltip-content-date">
                <div class="date">
                    {{ model.fulldate }}
                </div>
                <div class="value">
                    Connexions :
                    {{ model.value }}
                </div>
            </div>
        </ng-template></ngx-charts-line-chart
    >
    <div class="no-data" *ngIf="!(graphData | async) && (isLoading$ | async) === false">
        Pas de données à afficher
    </div>
</div>
