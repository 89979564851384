<div class="header">
    <span class="label">Modifier la photo de profil</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="content">
    <div class="icon-userinconnu-gros" *ngIf="!croppedImage"></div>
    <div class="croppedImage">
        <img [src]="croppedImage" *ngIf="croppedImage" />
    </div>
    <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="1 / 1"
        [resizeToWidth]="200"
        [roundCropper]="true"
        format="png"
        (imageCropped)="imageCropped($event)"
    ></image-cropper>
    <span class="size-limit"
        >Les images de plus de 200 pixels de hauteur/largeur seront redimensionnées</span
    >
</mat-dialog-content>
<div class="footer">
    <div class="import-button" (click)="uploadInput.click()">
        <span class="icon-Importer" *ngIf="!croppedImage"></span
        >{{ croppedImage ? 'Importer une autre image' : 'Importer une image' }}
    </div>
    <div class="save-button" *ngIf="croppedImage" (click)="saveImage()">Enregistrer l'image</div>
    <input hidden #uploadInput type="file" (change)="fileChangeEvent($event)" />
</div>
