<div class="header">
    <div class="icon-Configuration"></div>
    <span class="label">Diagnostic</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<div class="content">
    <div class="configuration">
        <div class="title">Configuration</div>
        <div class="grid-container">
            <div>
                <div
                    class="icon"
                    [ngClass]="isBrowserOK() ? 'icon-selected' : 'icon-Supprimerdugroupe'"
                ></div>
                <div class="label">Navigateur</div>
                <div class="value">
                    {{ browserName }}
                </div>
            </div>
            <div>
                <div
                    class="icon"
                    [ngClass]="isPopupOK() ? 'icon-selected' : 'icon-Supprimerdugroupe'"
                ></div>
                <div class="label">Bloqueur de pop-up</div>
                <div class="value">
                    {{ popupBlocked }}
                </div>
            </div>
            <div>
                <div
                    class="icon"
                    [ngClass]="isCookieOK() ? 'icon-selected' : 'icon-Supprimerdugroupe'"
                ></div>
                <div class="label">Cookies</div>
                <div class="value">
                    {{ cookieEnabled }}
                </div>
                <div class="cookie-alert" *ngIf="!isCookieOK()">
                    Vous ne pouvez pas vous connecter si les cookies ne sont pas autorisés pour
                    easi-training.fr
                </div>
            </div>
        </div>
    </div>
    <div class="system">
        <div class="title">Système</div>
        <div class="grid-container">
            <div>
                <div class="label">Affichage</div>
                <div class="value">
                    {{ screenResolution }}
                </div>
            </div>
            <div>
                <div class="label">Système d'exploitation</div>
                <div class="value">
                    {{ OSName }}
                </div>
            </div>
            <div>
                <div class="label">Adresse IP</div>
                <div class="value">
                    {{ IPAdress }}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="footer">
    <div class="button" (click)="openRecommandedConfiguration()">
        Voir configuration recommandée
    </div>
    <div class="button" (click)="openEasiCallCheckConfig()">Tester ma configuration pour call</div>
    <div class="button" (click)="exportDiagnostic()">Exporter le diagnostic</div>

    <div class="text-warning" *ngIf="fromLogin && isCookieOK()">
        Votre configuration n'est pas recommandée pour l'utilisation de la plateforme easi.
    </div>
    <div
        class="button warning"
        *ngIf="fromLogin"
        [ngClass]="{ disabled: !isCookieOK() }"
        (click)="startLogin()"
    >
        Poursuivre malgré tout
    </div>
</div>
