import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { UsersService } from 'src/app/services/users.service';
import { GroupsService } from 'src/app/services/groups.service';
import { FlashMessageService } from 'src/app/services/flash-message.service';

import { User } from '../../structures/user';
import { Group } from '../../structures/group';

@Component({
    selector: 'app-create-group',
    templateUrl: './create-group.component.html',
    styleUrls: ['./create-group.component.scss']
})
export class CreateGroupComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public group: Group,
        public dialogRef: MatDialogRef<CreateGroupComponent>,
        private usersService: UsersService,
        private groupsService: GroupsService,
        private flashMessageService: FlashMessageService
    ) {}

    @Output()
    openErrorDialog: EventEmitter<string> = new EventEmitter();

    subscriptions: Subscription = new Subscription();

    potentialUsers: Array<User> = [];
    potentialUsersSearch = '';
    potentialUsersCurrentPage = 0;

    memberUsersSearch = '';

    ngOnInit() {
        if (this.group.id) {
            this.subscriptions.add(
                this.groupsService.getGroup(this.group.id).subscribe((data: Group) => {
                    this.group.users = data.users;
                    this.nextPage();
                })
            );
        } else {
            this.nextPage();
        }
    }

    getTitle() {
        return !this.group.id ? 'Créer un groupe' : 'Modifier le groupe';
    }

    displayWarning() {
        return !!(this.group.name && this.group.name.length > 64);
    }

    getGroupMembersCount(): number {
        if (this.group.users) {
            return this.group.users.length;
        }
    }

    searchPotentialUsers(): void {
        this.potentialUsers = [];
        this.potentialUsersCurrentPage = 0;
        this.nextPage();
    }

    nextPage() {
        const params = {
            structureid: this.group.structureid,
            search: this.potentialUsersSearch,
            limit: 30,
            offset: this.potentialUsersCurrentPage * 30,
            role:
                this.group.type === 'teacher'
                    ? 'nationalAdmin|localAdmin|corporationTeacher|externalTeacher|internalTeacher|siteTeacher|nationalTeacher|tutor'
                    : 'learner|prospect'
        };
        this.subscriptions.add(
            this.usersService.getUsers(params).subscribe((data: User[]) => {
                this.potentialUsers = [
                    ...this.potentialUsers,
                    ...data.filter(
                        (user: User) =>
                            this.group.users.findIndex(
                                (userInGroup) => userInGroup.id === user.id
                            ) === -1
                    )
                ];
            })
        );
        this.potentialUsersCurrentPage += 1;
    }

    getUserIcon(user: User): string {
        if (user.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (user.roles.learner) {
            return 'icon-apprenant';
        } else if (user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (user.roles.siteTeacher) {
            return 'icon-formateur-site';
        } else if (user.roles.nationalTeacher) {
            return 'icon-auteurnational';
        } else if (user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (user.roles.tutor) {
            return 'icon-tuteurentreprise';
        } else if (user.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (user.roles.nationalAdmin) {
            return 'icon-adminnational';
        } else if (user.roles.externalCallManager) {
            return 'icon-Telephone';
        }
    }

    addUserToGroup(user: User): void {
        if (this.potentialUsers.length <= 30) {
            this.nextPage();
        }
        this.group.users.push(user);
        this.group.users.sort((a: User, b: User) =>
            `${a.lastname.toUpperCase()} ${a.firstname}`.localeCompare(
                `${b.lastname.toUpperCase()} ${b.firstname}`
            )
        );
        this.potentialUsers = this.potentialUsers.filter((u) => u !== user);
    }

    removeUserFromGroup(user: User): void {
        this.potentialUsers.push(user);
        this.potentialUsers.sort((a: User, b: User) =>
            `${a.lastname.toUpperCase()} ${a.firstname}`.localeCompare(
                `${b.lastname.toUpperCase()} ${b.firstname}`
            )
        );
        this.group.users = this.group.users.filter((u) => u !== user);
    }

    createGroup() {
        if (!this.group.id) {
            this.subscriptions.add(
                this.groupsService.createGroup(this.group).subscribe((data: any) => {
                    this.flashMessageService.flash(`Le groupe ${this.group.name} a été créé`);
                    this.dialogRef.close(data);
                })
            );
        } else {
            this.subscriptions.add(
                this.groupsService.updateGroup(this.group).subscribe((data: any) => {
                    this.flashMessageService.flash(`Le groupe ${this.group.name} a été mis à jour`);
                    this.dialogRef.close(data);
                })
            );
        }
    }

    closeDialog(): void {
        this.dialogRef.close(false);
    }
}
