<div class="component-graph-container">
    <ngx-charts-line-chart
        #graphs
        [view]="view"
        [scheme]="colorScheme"
        [legend]="true"
        [legendTitle]="false"
        [legendPosition]="'below'"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [xAxisTickFormatting]="xAxisTickFormatting"
        [rotateXAxisTicks]="true"
        [roundDomains]="true"
        [timeline]="timeline"
        [results]="graphData | async"
        (select)="onSelect($event)"
        (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)"
        [activeEntries]="activeEntries"
    >
        <ng-template #tooltipTemplate let-model="model">
            <div class="tooltip-content">
                <div class="name">
                    {{ getName(model) }}
                </div>
                <div class="value">
                    {{ model.value }}
                </div>
            </div>
        </ng-template>
    </ngx-charts-line-chart>
</div>
