<div id="users-component" *ngIf="!isLoading('usersMainView')">
    <div class="users-component_header">
        <div class="dropdowns">
            <lib-uimm-dropdown
                [label]="'Structure'"
                [labelWidth]="'200px'"
                [(items)]="structures"
                (itemsChange)="onStructureChange()"
                [multiselect]="true"
                [headers]="{
                    search: true,
                    selectAll: true,
                    count: true
                }"
                *ngIf="isNationalAdmin()"
            ></lib-uimm-dropdown>
            <lib-uimm-dropdown
                [label]="'Site'"
                [labelWidth]="'200px'"
                [(items)]="sites"
                (itemsChange)="onFiltersChange()"
                [disabled]="structuresSelected().length !== 1"
                [headers]="{
                    search: true,
                    selectAll: false,
                    count: true
                }"
                *ngIf="structuresSelected()"
            ></lib-uimm-dropdown>
            <lib-uimm-dropdown
                [label]="'Entreprise'"
                [labelWidth]="'200px'"
                [(items)]="corporations"
                (itemsChange)="onFiltersChange()"
                [disabled]="structuresSelected().length !== 1"
                [headers]="{
                    search: true,
                    selectAll: false,
                    count: true
                }"
                *ngIf="structuresSelected()"
            ></lib-uimm-dropdown>
            <lib-uimm-dropdown
                [label]="'Type d\'utilisateur'"
                [labelWidth]="'200px'"
                [items]="getRolesItems()"
                (itemsChange)="onFiltersChange()"
                *ngIf="structuresSelected()"
            ></lib-uimm-dropdown>
            <lib-uimm-dropdown
                [label]="'Etat des utilisateurs'"
                [labelWidth]="'200px'"
                [items]="userStatus"
                (itemsChange)="onStatusChange()"
                [multiselect]="true"
                *ngIf="structuresSelected()"
            ></lib-uimm-dropdown>
            <lib-uimm-dropdown
                [label]="'Nombre de rappels'"
                [labelWidth]="'200px'"
                [items]="reminderCount"
                (itemsChange)="onFiltersChange()"
                *ngIf="structuresSelected()"
                [multiselect]="true"
            ></lib-uimm-dropdown>
            <div
                class="icon-Supprimerdugroupe"
                *ngIf="structuresSelected()"
                (click)="resetFilters()"
                tooltip="Annuler les filtres"
            ></div>
        </div>
        <div class="counter-and-search" *ngIf="structuresSelected()">
            <div class="counter" *ngIf="showCounter()">
                <span class="count">{{ usersCount }}</span>
                Utilisateur{{ usersCount > 1 ? 's' : '' }}
            </div>

            <div class="search-container">
                <input
                    type="search"
                    class="search"
                    [(ngModel)]="searchTerm"
                    placeholder="Chercher..."
                    (keyup.enter)="onFiltersChange()"
                />
                <span class="search-icons">
                    <span class="icon-search" (click)="onFiltersChange()"></span>
                    <span
                        class="icon-Fermerdetails"
                        *ngIf="searchTerm"
                        (click)="resetSearch()"
                    ></span>
                </span>
            </div>
            <div class="button-export-csv" (click)="exportCSV()">Exporter en CSV</div>
        </div>
    </div>
    <div
        class="users-component_main"
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="nextPageUsers()"
    >
        <div id="users-list" *ngIf="structuresSelected()">
            <app-user
                *ngFor="let user of users"
                [user]="user"
                [roles]="getUserRoles(user)"
                [additionnalRoles]="getUserAdditionalRoles(user)"
            ></app-user>
        </div>
        <div class="empty-structure" *ngIf="!structuresSelected()">
            <p>Veuillez sélectionner une structure.</p>
            <div>
                <img src="assets/img/library-default.png" />
            </div>
        </div>
    </div>
    <app-loading *ngIf="isLoading('usersNextPage')"></app-loading>

    <div class="users-component_aside">
        <div
            class="create-user-button"
            *ngIf="structuresSelected()"
            tooltip="Créer un nouvel utilisateur"
            tooltipPosition="left"
            tooltipColor="#199fe2"
            (click)="createUser()"
        >
            <span class="icon-plus"></span>
        </div>
        <div
            class="import-users-csv"
            (click)="openCSVImportDialog()"
            *ngIf="structuresSelected() && structuresSelected().length === 1"
            tooltip="Importer des utilisateurs (via un CSV)"
            tooltipPosition="left"
            tooltipColor="#199fe2"
        >
            <span class="icon-gestionnairedecomptes"></span>
        </div>
    </div>
</div>

<app-loading *ngIf="isLoading('usersMainView')"></app-loading>
