<div class="active-users-chart-container" #containerRef *ngIf="data">
    <ngx-charts-bar-vertical-2d
        [view]="view"
        [scheme]="colorScheme"
        [results]="data"
        barPadding="0"
        [roundEdges]="false"
        [showDataLabel]="true"
        [rotateXAxisTicks]="false"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="true"
        legendPosition="below"
        [showXAxisLabel]="false"
        [showYAxisLabel]="false"
        [noBarWhenZero]="true"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        (select)="onSelect($event)"
        (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)"
    >
    </ngx-charts-bar-vertical-2d>
    <div class="graph-informations" *ngIf="data">
        <div class="empty"></div>
        <div class="app" *ngFor="let app of data">
            <div class="name">
                {{ app.name }}
            </div>
            <div class="app-info">{{ getAppInfo(app) }}</div>
        </div>
        <div class="empty2"></div>
    </div>
</div>
