import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import * as $ from 'jquery';

@Component({
    selector: 'app-dates-selector',
    templateUrl: './dates-selector.component.html',
    styleUrls: ['./dates-selector.component.scss']
})
export class DatesSelectorComponent implements OnInit {
    @Input() dateStart: Date;
    @Input() maxDateStart: Date;
    @Input() minDateStart: Date;
    @Input() dateEnd: Date;
    @Input() maxDateEnd: Date;
    @Input() minDateEnd: Date;
    @Output() dateStartChange: EventEmitter<Date> = new EventEmitter();
    @Output() dateEndChange: EventEmitter<Date> = new EventEmitter();

    datesDropdownOpened = false;
    datesFilterOpened = false;

    constructor() {}

    ngOnInit(): void {}

    onUpdateParameters() {}

    onClickOutsideDatesDropdown(event) {
        if (
            this.datesDropdownOpened &&
            event.target.nodeName !== 'BODY' &&
            $(event.target).parents('.mat-calendar-content').length === 0
        ) {
            this.datesDropdownOpened = !this.datesDropdownOpened;
        }
    }
}
