import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { DialogService } from './dialog.service';
import { log } from './decorators/log.decorator';

@Injectable({
    providedIn: 'root'
})
export class GraphsService {
    public connexionDataSubject: BehaviorSubject<any> = new BehaviorSubject({
        total: [],
        uniques: []
    });
    private assignationsDataSubject: BehaviorSubject<any> = new BehaviorSubject('');
    private topActivitiesData$: BehaviorSubject<any> = new BehaviorSubject(undefined);
    private activeUsersSubject: BehaviorSubject<any> = new BehaviorSubject(undefined);
    public connexionType$: BehaviorSubject<'uniques' | 'total'> = new BehaviorSubject('uniques');
    public isFetchingData$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private endFetchingDataObserver: Observer<any> = {
        next: () => {
            this.isFetchingData$.next(false);
        },
        error: () => {
            this.isFetchingData$.next(false);
        },
        complete: () => {
            this.isFetchingData$.next(false);
        }
    };
    public wantedInTop30: 'assignment' | 'executions';

    private connexionsSubscription$: Subscription;
    private assignationsSubscription$: Subscription;
    private topActivitiesSubscription$: Subscription;
    private activeUsersSubscription$: Subscription;
    constructor(private http: HttpClient, private dialogService: DialogService) {}

    /*#################################################################
    * #              NOMBRE DE CONNEXIONS MENSUELLES                  #
    ###################################################################*/

    /**
     * retourne une observable des nombres de connexions mensuelles
     */
    @log() getConnexionData(): Observable<any> {
        return this.connexionDataSubject
            .asObservable()
            .pipe(map(this.connexionDataMappingCallback));
    }

    @log() updateConnexionData(parameters: any): void {
        this.isFetchingData$.next(true);
        const start = new Date(parameters.start);
        const end = new Date(parameters.end);

        for (const prop in parameters) {
            if (parameters.hasOwnProperty(prop)) {
                const element = parameters[prop];
                if (element === '*' || (Array.isArray(element) && element.length === 0)) {
                    delete parameters[prop];
                }
            }
        }

        const body = {
            application: 'connect',
            action: 'login',
            arole: parameters.arole ? parameters.arole : 'nationalAdmin',
            ...parameters,
            start: start.toISOString(),
            end: end.toISOString()
        };

        if (this.connexionsSubscription$) {
            this.connexionsSubscription$.unsubscribe();
        }

        this.connexionsSubscription$ = this.http
            .post<Observable<any>>('/elastic/users/connections', body)
            .pipe(tap(this.endFetchingDataObserver))
            .subscribe((data) => this.connexionDataSubject.next(data));
    }

    /**
     * maps the data received to a correct format for the GraphsConnexionsComponent to use
     * @param data the data received from the ws
     */
    connexionDataMappingCallback(
        data: any
    ): {
        total: any[];
        uniques: any[];
    } {
        return {
            total: data.total.map((dataSerie) => ({
                name: dataSerie.year.toString(),
                series: [
                    ...dataSerie.series.map((element) => ({
                        name: +element.month,
                        value: element.total,
                        fulldate: new Date(dataSerie.year + '-' + element.month).toLocaleDateString(
                            'fr-FR',
                            {
                                year: 'numeric',
                                month: 'long'
                            }
                        )
                    }))
                ].sort((a, b) => a.name - b.name)
            })),
            uniques: data.uniques.map((dataSerie) => ({
                name: dataSerie.year.toString(),
                series: [
                    ...dataSerie.series.map((element) => ({
                        name: +element.month,
                        value: element.uniques,
                        fulldate: new Date(dataSerie.year + '-' + element.month).toLocaleDateString(
                            'fr-FR',
                            {
                                year: 'numeric',
                                month: 'long'
                            }
                        )
                    }))
                ].sort((a, b) => a.name - b.name)
            }))
        };
    }

    @log() getConnexionsCSV(parameters: any): any {
        this.isFetchingData$.next(true);

        const start = new Date(parameters.start);
        const end = new Date(parameters.end);

        for (const prop in parameters) {
            if (parameters.hasOwnProperty(prop)) {
                const element = parameters[prop];
                if (element === '*' || (Array.isArray(element) && element.length === 0)) {
                    delete parameters[prop];
                }
            }
        }

        const body = {
            application: 'connect',
            action: 'login',
            arole: parameters.arole ? parameters.arole : 'nationalAdmin',
            ...parameters,
            start: start.toISOString(),
            end: end.toISOString()
        };

        return this.http
            .post<Observable<any>>('/elastic/users/connections/csv', body, {
                responseType: 'blob' as 'json'
            })
            .pipe(tap(this.endFetchingDataObserver));
    }

    setConnexionType(value: 'uniques' | 'total'): void {
        this.connexionType$.next(value);
    }

    @log() getConnexionType(): Observable<'uniques' | 'total'> {
        return this.connexionType$.asObservable();
    }

    /**#################################################################
   * #              NOMBRE D'ASSIGNATIONS  MENSUELLES                #
     #################################################################*/

    /**
     * retourn une observable des nombres d'assignations mensuelles
     */
    @log() getAssignationsData(): Observable<any> {
        return this.assignationsDataSubject
            .asObservable()
            .pipe(map(this.assignationDataMappingCallback));
    }

    @log() updateAssignationData(parameters: any): void {
        this.isFetchingData$.next(true);

        const start = new Date(parameters.start);
        const end = new Date(parameters.end);

        for (const prop in parameters) {
            if (parameters.hasOwnProperty(prop)) {
                const element = parameters[prop];
                if (element === '*' || (Array.isArray(element) && element.length === 0)) {
                    delete parameters[prop];
                }
            }
        }

        const body = {
            application: 'training',
            action: 'contentassignedtouser',
            ...parameters,
            start: start.toISOString(),
            end: end.toISOString()
        };

        if (this.assignationsSubscription$) {
            this.assignationsSubscription$.unsubscribe();
        }
        this.assignationsSubscription$ = this.http
            .post<Observable<any>>('/elastic/assignments/monthly', body)
            .pipe(tap(this.endFetchingDataObserver))
            .subscribe((data) => {
                this.assignationsDataSubject.next(data);
            });
    }

    /**
     * maps the data received to a correct format for the GraphsConnexionsComponent to use
     * @param data the data received from the ws
     */
    assignationDataMappingCallback(
        data: any
    ): Array<{
        name: string;
        series: Array<{
            name: string;
            value: number;
        }>;
    }> {
        if (data === '') {
            return;
        }
        let total = [];
        for (const year in data.Total) {
            if (true) {
                total = [
                    ...total,
                    ...data.Total[year].map((element) => ({
                        name: `${year}-${element.month}`,
                        value: element.total
                    }))
                ];
            }
        }
        let FI = [];
        for (const year in data.FI) {
            if (true) {
                FI = [
                    ...FI,
                    ...data.FI[year].map((element) => ({
                        name: `${year}-${element.month}`,
                        value: element.total
                    }))
                ];
            }
        }
        let FC = [];
        for (const year in data.FC) {
            if (true) {
                FC = [
                    ...FC,
                    ...data.FC[year].map((element) => ({
                        name: `${year}-${element.month}`,
                        value: element.total
                    }))
                ];
            }
        }
        return [
            {
                name: 'FC',
                series: [...FC]
            },
            {
                name: 'FI',
                series: [...FI]
            },
            {
                name: 'Total',
                series: [...total]
            }
        ];
    }

    @log() getAssignationsCSV(parameters: any): any {
        this.isFetchingData$.next(true);

        const start = new Date(parameters.start);
        const end = new Date(parameters.end);

        for (const prop in parameters) {
            if (parameters.hasOwnProperty(prop)) {
                const element = parameters[prop];
                if (element === '*' || (Array.isArray(element) && element.length === 0)) {
                    delete parameters[prop];
                }
            }
        }

        const body = {
            application: 'training',
            action: 'contentassignedtouser',
            ...parameters,
            start: start.toISOString(),
            end: end.toISOString()
        };

        return this.http
            .post<Observable<any>>('/elastic/assignments/monthly/csv', body, {
                responseType: 'blob' as 'json'
            })
            .pipe(tap(this.endFetchingDataObserver));
    }

    /**#################################################################
   * #                    TOP 30 DES ACTIVITES                       #
     #################################################################*/

    /**
     * retourne une observable des activités top 30
     */
    @log() getTopActivitiesData(): Observable<any> {
        return this.topActivitiesData$.asObservable();
    }

    updateTopActivitiesData(params: any): void {
        this.isFetchingData$.next(true);

        this.wantedInTop30 = params.wanted;
        for (const prop in params) {
            if (params.hasOwnProperty(prop)) {
                const element = params[prop];
                if (element === '*' || (Array.isArray(element) && element.length === 0)) {
                    delete params[prop];
                }
            }
        }

        const body = {
            ...params
        };

        if (this.topActivitiesSubscription$) {
            this.topActivitiesSubscription$.unsubscribe();
        }
        this.topActivitiesSubscription$ = this.http
            .post<Observable<any>>(`/elastic/activities/top`, body)
            .pipe(
                map(
                    params.wanted === 'assignment'
                        ? this.topActivitiesAssignedMappingCallBack
                        : this.topActivitiesExecutedMappingCallBack
                ),
                tap(this.endFetchingDataObserver)
            )
            .subscribe(
                (data) => {
                    if (data) {
                        this.topActivitiesData$.next(data);
                    } else {
                        this.topActivitiesData$.next(undefined);
                    }
                },
                (error) => {
                    this.dialogService.openError("Une erreur s'est produite.");
                    this.topActivitiesData$.next(undefined);
                }
            );
    }

    topActivitiesAssignedMappingCallBack(
        data: any
    ): Array<{
        name: string;
        series: Array<{
            name: string;
            value: number;
        }>;
    }> {
        if (data === undefined || data.length === 0) {
            return [];
        }

        return data.activities.map((activity) => ({
            ...activity,
            name: activity.title + '$$$' + activity.id,
            value: activity.assignment,
            extra: {
                executions: activity.executions
            }
        }));
    }
    topActivitiesExecutedMappingCallBack(
        data: any
    ): Array<{
        name: string;
        series: Array<{
            name: string;
            value: number;
        }>;
    }> {
        if (data === undefined || data.length === 0) {
            return [];
        }

        return data.activities.map((activity) => ({
            ...activity,
            name: activity.title + '$$$' + activity.id,
            value: activity.executions,
            extra: {
                assignment: activity.assignment
            }
        }));
    }

    @log() getActivitiesCSV(params: any): any {
        this.isFetchingData$.next(true);

        for (const prop in params) {
            if (params.hasOwnProperty(prop)) {
                const element = params[prop];
                if (element === '*' || (Array.isArray(element) && element.length === 0)) {
                    delete params[prop];
                }
            }
        }

        const body = {
            ...params
        };

        return this.http
            .post<Observable<any>>('/elastic/activities/top/csv', body, {
                responseType: 'blob' as 'json'
            })
            .pipe(tap(this.endFetchingDataObserver));
    }

    /****************************************************************************************
     *                             NOMBRE D'UTILISATEURS ACTIFS                             *
     ****************************************************************************************/

    @log() getActiveUsers(): Observable<any> {
        return this.activeUsersSubject.asObservable().pipe(map(this.removeUselessData));
    }

    removeUselessData(data: any[]): any[] {
        if (!data) {
            return;
        }
        return data.map((app) => {
            if (app.name === 'Vue Apprenant') {
                return {
                    ...app,
                    series: app.series.filter((serie) => serie.name === 'Apprenant')
                };
            } else {
                return app;
            }
        });
    }

    @log() updateActiveUsersData({ ...params }): void {
        this.isFetchingData$.next(true);

        if (this.activeUsersSubscription$) {
            this.activeUsersSubscription$.unsubscribe();
        }
        this.activeUsersSubscription$ = this.http
            .post<Observable<any>>(`/elastic/users/active`, params)
            .pipe(tap(this.endFetchingDataObserver))
            .subscribe(
                (data) => {
                    if (data) {
                        this.activeUsersSubject.next(data);
                    } else {
                        this.activeUsersSubject.next(undefined);
                    }
                },
                (error) => {
                    this.dialogService.openError("Une erreur s'est produite.");
                    this.activeUsersSubject.next(undefined);
                }
            );
    }

    @log() getActiveUsersCSV(params: any): any {
        this.isFetchingData$.next(true);

        for (const prop in params) {
            if (params.hasOwnProperty(prop)) {
                const element = params[prop];
                if (element === '*' || (Array.isArray(element) && element.length === 0)) {
                    delete params[prop];
                }
            }
        }

        const body = {
            ...params
        };

        return this.http
            .post<Observable<any>>('/elastic/users/active/csv', body, {
                responseType: 'blob' as 'json'
            })
            .pipe(tap(this.endFetchingDataObserver));
    }
}
