<div class="news">
    <div class="header">
        <div class="left-icons">
            <span class="icon-News"></span>
        </div>

        <div class="author-news">RÉDIGER UNE NEWS</div>

        <div class="news-date">
            {{ getNewsDate() }}
        </div>

        <div class="right-icons">
            <span class="icon-close" (click)="exit()"></span>
        </div>
    </div>
    <div class="add-news-form">
        <input
            class="news-title"
            type="text"
            [(ngModel)]="news.title"
            placeholder="Saisissez le titre de la news..."
        />

        <editor
            [(ngModel)]="news.description"
            [init]="tinyConfig"
            [ngModelOptions]="{
                standalone: true
            }"
            [apiKey]="getAPIKey()"
        ></editor>

        <div class="structures-container" *ngIf="isNationalAdmin() && structures">
            <lib-uimm-dropdown
                [label]="getDropdownLabel()"
                [labelWidth]="'220px'"
                [maxHeight]="200"
                [(items)]="structures"
                [multiselect]="true"
                [headers]="{
                    search: true,
                    selectAll: true,
                    count: true
                }"
            ></lib-uimm-dropdown>
        </div>

        <div class="destinataires-container">
            <div class="destinataires">Destinataires</div>

            <div class="recipients">
                <div
                    class="recipient"
                    [ngClass]="{
                        selected: isSelected('role', 'localAdmin')
                    }"
                    (click)="toggleDiffusionList('role', 'localAdmin')"
                >
                    <span
                        class="icon"
                        [ngClass]="{
                            'icon-select': !isSelected('role', 'localAdmin'),
                            'icon-selected': isSelected('role', 'localAdmin')
                        }"
                    ></span>
                    <div>
                        <span class="icon-adminlocal"></span>
                        {{ getRoleLabel('localAdmin') }}
                    </div>
                </div>

                <div
                    class="recipient"
                    [ngClass]="{
                        selected: isSelected('role', 'internalTeacher')
                    }"
                    (click)="toggleDiffusionList('role', 'internalTeacher')"
                >
                    <span
                        class="icon"
                        [ngClass]="{
                            'icon-select': !isSelected('role', 'internalTeacher'),
                            'icon-selected': isSelected('role', 'internalTeacher')
                        }"
                    ></span>
                    <div>
                        <span class="icon-formateurinterne"></span>
                        {{ getRoleLabel('internalTeacher') }}
                    </div>
                </div>

                <div
                    class="recipient"
                    [ngClass]="{
                        selected: isSelected('role', 'externalTeacher')
                    }"
                    (click)="toggleDiffusionList('role', 'externalTeacher')"
                >
                    <span
                        class="icon"
                        [ngClass]="{
                            'icon-select': !isSelected('role', 'externalTeacher'),
                            'icon-selected': isSelected('role', 'externalTeacher')
                        }"
                    ></span>
                    <div>
                        <span class="icon-formateurexterne"></span>
                        {{ getRoleLabel('externalTeacher') }}
                    </div>
                </div>
                <div
                    class="recipient"
                    *ngIf="isNationalAdmin()"
                    [ngClass]="{
                        selected: isSelected('role', 'siteTeacher')
                    }"
                    (click)="toggleDiffusionList('role', 'siteTeacher')"
                >
                    <span
                        class="icon"
                        [ngClass]="{
                            'icon-select': !isSelected('role', 'siteTeacher'),
                            'icon-selected': isSelected('role', 'siteTeacher')
                        }"
                    ></span>
                    <div>
                        <span class="icon-formateur-site"></span>
                        {{ getRoleLabel('siteTeacher') }}
                    </div>
                </div>
                <div
                    class="recipient"
                    [ngClass]="{
                        selected: isSelected('tag', 'Référent pédagogique')
                    }"
                    (click)="toggleDiffusionList('tag', 'Référent pédagogique')"
                    *ngIf="isNationalAdmin()"
                >
                    <span
                        class="icon"
                        [ngClass]="{
                            'icon-select': !isSelected('tag', 'Référent pédagogique'),
                            'icon-selected': isSelected('tag', 'Référent pédagogique')
                        }"
                    ></span>
                    <div>
                        <span class="icon-easi-training-line"></span>
                        Référent pédagogique
                    </div>
                </div>
                <div
                    class="recipient"
                    [ngClass]="{
                        selected: isSelected('role', 'learner')
                    }"
                    (click)="toggleDiffusionList('role', 'learner')"
                >
                    <span
                        class="icon"
                        [ngClass]="{
                            'icon-select': !isSelected('role', 'learner'),
                            'icon-selected': isSelected('role', 'learner')
                        }"
                    ></span>
                    <div>
                        <span class="icon-apprenant"></span>
                        {{ getRoleLabel('learner') }}
                    </div>
                </div>
            </div>
        </div>

        <div
            id="publish-button"
            [ngClass]="{
                disabled: !canPublish()
            }"
            (click)="publish()"
        >
            Publier
        </div>
    </div>
</div>
