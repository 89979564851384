<div class="header">
    <div class="icon-porte-voix"></div>
    <div class="label">{{ getTitle() }}</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<div class="container">
    <div class="role-group-name">Nom</div>
    <input
        class="name-input"
        type="text"
        placeholder="Nom du groupe"
        [(ngModel)]="roleGroup.name"
    />
    <div class="main-container">
        <div class="left-column">
            <div class="filters">
                <div class="title">
                    Rôle(s)<span class="count">({{ getRoleSelectedCount() }})</span>
                </div>
            </div>
            <div class="users-container">
                <div class="select-all" *ngIf="!isAllRolesSelected()" (click)="selectAllRoles()">
                    Tout sélectionner
                </div>
                <div class="select-all" *ngIf="isAllRolesSelected()" (click)="unselectAllRoles()">
                    Tout désélectionner
                </div>

                <div class="role" *ngFor="let role of roles">
                    <div>
                        <div class="icon" [ngClass]="role.icon"></div>
                        <div class="name">{{ role.name }}</div>
                    </div>
                    <div
                        [ngClass]="role.selected ? 'icon-selected' : 'icon-select'"
                        (click)="toggleRoleSelected(role)"
                    ></div>
                </div>
            </div>
        </div>
        <div class="right-column">
            <div class="filters">
                <div class="title">
                    Site(s)<span class="count">({{ getSiteSelectedCount() }})</span>
                </div>
                <div class="search-container">
                    <input
                        type="search"
                        class="search"
                        [(ngModel)]="siteSearch"
                        placeholder="Chercher"
                    />
                    <span class="icon-search"></span>
                </div>
            </div>
            <div class="users-container">
                <div class="select-all" *ngIf="!isAllSitesSelected()" (click)="selectAllSites()">
                    Tout sélectionner
                </div>
                <div class="select-all" *ngIf="isAllSitesSelected()" (click)="unselectAllSites()">
                    Tout désélectionner
                </div>
                <div class="site" *ngFor="let site of getFilteredSites()">
                    <div>
                        <div
                            [ngClass]="!site.isCorporation ? 'icon-site' : 'icon-corporation'"
                        ></div>
                        <div class="name">{{ site.title }}</div>
                    </div>
                    <div
                        [ngClass]="site.selected ? 'icon-selected' : 'icon-select'"
                        (click)="toggleSiteSelected(site)"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="footer">
    <div
        class="create-button"
        [ngClass]="{
            disabled: !roleGroup.name
        }"
        (click)="createRoleGroup()"
    >
        Enregistrer
    </div>
</div>
