<div id="upload-file">
    <div class="upload-file-header">
        <div class="title">Importer une image</div>
        <span class="icon-close" (click)="close()"></span>
    </div>

    <div class="upload-file-body">
        <div class="file-name">
            Fichier:
            <b>{{ getFileName() ? getFileName() : '...' }}</b>
        </div>
        <div class="progress-bar" *ngIf="loading">
            Upload
            <b>{{ getUploadProgress() }} %</b>
        </div>
    </div>

    <div class="upload-file-footer">
        <div class="button button-browse" (click)="uploadInput.click()">
            Choisir un fichier image
        </div>
        <div class="button button-upload" (click)="uploadFile()" *ngIf="enabledUploadFileButton()">
            Importer l'image
        </div>
    </div>
    <input hidden #uploadInput type="file" (change)="fileChangeEvent($event)" />
</div>
