import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';

import { LoginService } from '../services/login.service';
import { ConfigService } from '../services/config.service';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard {
    constructor(
        private configService: ConfigService,
        private loginService: LoginService,
        private router: Router
    ) {}

    canActivate() {
        if (!this.loginService.getUser() || !this.loginService.getUser().roles) {
            return this.router.parseUrl('/login' + window.location.search);
        } else {
            if (
                this.loginService.getUser().roles.nationalAdmin ||
                this.loginService.getUser().roles.localAdmin ||
                this.loginService.getUser().additionalRoles.accountManager
            ) {
                return true;
            } else if (
                this.loginService.getUser().roles.learner ||
                this.loginService.getUser().roles.prospect
            ) {
                if (!this.loginService.getUser().exception) {
                    window.location.href = this.configService.getLearningFrontEndPoint();
                }
                return true;
            } else {
                if (!this.loginService.getUser().exception) {
                    window.location.href = this.configService.getTrainingFrontEndPoint();
                }
                return true;
            }
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot) {
        switch (route.routeConfig.path) {
            case 'users':
            case 'groups':
            case 'contacts':
            case 'history':
            case 'activityReport': {
                return (
                    this.loginService.getUser().roles.nationalAdmin ||
                    this.loginService.getUser().roles.localAdmin ||
                    this.loginService.getUser().additionalRoles.accountManager
                );
            }
            case 'activityReport/tasks/:id': {
                if (isNaN(route.params.id)) {
                    return false;
                }
                return (
                    this.loginService.getUser().roles.nationalAdmin ||
                    this.loginService.getUser().roles.localAdmin ||
                    this.loginService.getUser().additionalRoles.accountManager
                );
            }
            case 'rights':
            case 'dashboard':
            case 'graphs':
            case 'news': {
                return (
                    this.loginService.getUser().roles.nationalAdmin ||
                    this.loginService.getUser().roles.localAdmin
                );
            }
            case 'structures':
            case 'nationalStatistics':
            case 'nationalAdministration': {
                return this.loginService.getUser().roles.nationalAdmin;
            }
            case 'sites':
            case 'unusedElements': {
                return this.loginService.getUser().roles.localAdmin;
            }
            case 'roleGroups': {
                return (
                    this.loginService.getUser().roles.localAdmin ||
                    this.loginService.getUser().additionalRoles.accountManager
                );
            }
            default: {
                return false;
            }
        }
    }
}
