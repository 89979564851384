<div class="header">
    <div class="icon-local"></div>
    <div class="label">{{ getTitle() }}</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<div class="container">
    <div class="legend">
        Pour des raisons de performances, la modification apporté ne sera effective que dans 5
        minutes
    </div>
    <input type="text" [(ngModel)]="structure.name" placeholder="Nom de la structure..." />

    <input
        type="text"
        [(ngModel)]="structure.shortname"
        placeholder="Nom abbrégé de la structure..."
    />
</div>
<div class="footer">
    <div
        class="create-button"
        [ngClass]="{
            disabled: !structure.name
        }"
        (click)="createStructure()"
    >
        {{ getTitle() }}
    </div>
</div>
