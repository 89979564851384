<div
    class="component-graph-container"
    [ngClass]="{
        compensate: !single
    }"
>
    <ngx-charts-bar-horizontal
        *ngIf="single && single.length > 0"
        #graphs
        [view]="view"
        [scheme]="colorScheme"
        [customColors]="customColors"
        [results]="single"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [legendPosition]="'below'"
        [animations]="false"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [trimYAxisTicks]="false"
        [showDataLabel]="true"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [ngStyle]="{
            opacity: opacity,
            'z-index': Zindex
        }"
        (select)="onSelect($event)"
        (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)"
    >
        <ng-template #tooltipTemplate let-model="model">
            <div class="tooltip-content">
                <div class="name">
                    {{ model.name.split('$$$')[0] }}
                    {{ '(ID: ' + model.name.split('$$$')[1] + ')' }}
                </div>
                <div class="value">{{ model.value }}{{ getExtraTooltipData(model) }}</div>
            </div>
        </ng-template>
    </ngx-charts-bar-horizontal>
    <div class="no-data" *ngIf="(!single || single.length === 0) && (isLoading$ | async) === false">
        Pas de données à afficher
    </div>
    <div
        class="top-activities-graph_legend chart-legend"
        id="legend"
        *ngIf="single && single.length > 0"
    >
        <div class="legend-item" *ngIf="isCategoryInData('ressource')">
            <div class="square legend-ressource"></div>
            Ressource
        </div>
        <div class="legend-item" *ngIf="isCategoryInData('evaluation')">
            <div class="square legend-evaluation"></div>
            Evaluation
        </div>
        <div class="legend-item" *ngIf="isCategoryInData('travail')">
            <div class="square legend-miseenapplication"></div>
            Mise en application
        </div>
        <div class="legend-item" *ngIf="isCategoryInData('corrige')">
            <div class="square legend-corrige"></div>
            Corrigé
        </div>
        <div class="legend-item" *ngIf="isCategoryInData('devoir')">
            <div class="square legend-devoir"></div>
            Devoir
        </div>
        <div class="legend-item" *ngIf="isCategoryInData('positionnement')">
            <div class="square legend-positionnement"></div>
            Positionnement
        </div>
        <div class="legend-item" *ngIf="isCategoryInData('preparation')">
            <div class="square legend-preparation"></div>
            Préparation
        </div>
        <div class="legend-item" *ngIf="isCategoryInData('presentiel')">
            <div class="square legend-presentiel"></div>
            Activité présentielle
        </div>
        <div class="legend-item" *ngIf="isCategoryInData('tp')">
            <div class="square legend-tp"></div>
            TP
        </div>
        <div class="legend-item" *ngIf="isCategoryInData('url')">
            <div class="square legend-url"></div>
            URL
        </div>
        <div class="legend-item" *ngIf="isCategoryInData('')">
            <div class="square legend-autre"></div>
            Autre
        </div>
    </div>
    <div id="executions-warning" *ngIf="single && single.length > 0">
        Seules les exécutions effectuées à partir de juin 2020 sont comptabilisées.
    </div>
</div>
