<div class="header">
    <div class="icon-group"></div>
    <div class="label">{{ getTitle() }}</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<div class="container">
    <div class="warning" *ngIf="displayWarning()">
        Le nom du groupe dépasse les 64 caractères, il ne sera donc pas synchronisé automatiquement
        dans le cloud.
    </div>
    <div class="name">Nom</div>
    <input class="name-input" type="text" placeholder="Nom du groupe" [(ngModel)]="group.name" />
    <div class="main-container">
        <div class="left-column">
            <div class="filters">
                <div class="title">Utilisateurs à ajouter</div>
                <div class="search-container">
                    <input
                        type="search"
                        class="search"
                        [(ngModel)]="potentialUsersSearch"
                        placeholder="Chercher"
                        (keyup.enter)="searchPotentialUsers()"
                    />
                    <span class="icon-search" (click)="searchPotentialUsers()"></span>
                </div>
            </div>
            <div
                class="users-container"
                infiniteScroll
                [infiniteScrollDistance]="1"
                [infiniteScrollThrottle]="50"
                [scrollWindow]="false"
                (scrolled)="nextPage()"
            >
                <div
                    class="user"
                    *ngFor="let user of potentialUsers"
                    [ngClass]="{
                        disabled: !user.enabled
                    }"
                >
                    <div class="user-info">
                        <div class="icon" [ngClass]="getUserIcon(user)"></div>
                        <div class="label">
                            {{ user.lastname.toUpperCase() }}
                            {{ user.firstname }}
                        </div>
                    </div>
                    <div class="icon-NoterPlus" (click)="addUserToGroup(user)"></div>
                </div>
            </div>
        </div>
        <div class="right-column">
            <div class="filters">
                <div class="title">
                    Utilisateurs du groupe<span class="group-count"
                        >({{ getGroupMembersCount() }})</span
                    >
                </div>
                <div class="search-container">
                    <input
                        type="search"
                        class="search"
                        [(ngModel)]="memberUsersSearch"
                        placeholder="Chercher"
                    />
                    <span class="icon-search"></span>
                </div>
            </div>
            <div class="users-container">
                <div class="user" *ngFor="let user of group.users | usersFilter: memberUsersSearch">
                    <div class="user-info">
                        <div class="icon" [ngClass]="getUserIcon(user)"></div>
                        <div class="label">
                            {{ user.lastname.toUpperCase() }}
                            {{ user.firstname }}
                        </div>
                    </div>
                    <div class="icon-NoterMoins" (click)="removeUserFromGroup(user)"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="footer">
    <div
        class="create-button"
        [ngClass]="{
            disabled: !group.name
        }"
        (click)="createGroup()"
    >
        Enregistrer
    </div>
</div>
