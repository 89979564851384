<div *ngIf="!isLoading()">
    <div class="header-structures">
        <div class="structures-count">
            <b>{{ getStructuresCount() }}</b>
            structures
        </div>
        <div class="search-container">
            <input
                type="search"
                class="search"
                [(ngModel)]="searchTerm"
                placeholder="Chercher..."
            />
            <span class="search-icons">
                <span class="icon-search"></span>
                <span class="icon-Fermerdetails" *ngIf="searchTerm" (click)="clearSearch()"></span>
            </span>
        </div>
    </div>
    <div class="container-structures">
        <mat-expansion-panel class="container" *ngFor="let structure of getSearchedStructures()">
            <mat-expansion-panel-header
                [collapsedHeight]="'50px'"
                [expandedHeight]="'50px'"
                (click)="getStructure(structure)"
            >
                <div class="title">
                    <div class="icon-local"></div>
                    <div class="label">
                        {{ getStructureLabel(structure) }}
                    </div>
                </div>

                <div>
                    <div
                        class="icon-Editer"
                        [tooltip]="'Modifer la structure'"
                        (click)="openUpdateStructure($event, structure)"
                    ></div>
                    <div
                        class="icon-NoterPlus"
                        [tooltip]="'Ajouter un site/entreprise'"
                        (click)="openCreateSite($event, structure)"
                    ></div>
                </div>
            </mat-expansion-panel-header>
            <div>
                <div class="site" *ngFor="let site of structure.sites">
                    <div>
                        <div class="icon-site"></div>
                        <div class="label">
                            {{ site.name }}
                        </div>
                    </div>
                    <div
                        class="icon-Editer"
                        [tooltip]="'Editer ce site'"
                        (click)="openEditSite($event, site)"
                    ></div>
                </div>
                <div class="corporation" *ngFor="let corporation of structure.corporations">
                    <div>
                        <div class="icon-corporation"></div>
                        <div class="label">
                            {{ corporation.name }}
                        </div>
                    </div>
                    <div
                        class="icon-Editer"
                        [tooltip]="'Editer cette entreprise'"
                        (click)="openEditSite($event, corporation)"
                    ></div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
    <div class="add-structures" [tooltip]="'Créer une structure'" (click)="openCreateStructure()">
        <div class="icon-plus"></div>
    </div>
</div>

<app-loading *ngIf="isLoading()"></app-loading>
