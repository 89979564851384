import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { LoginService } from './login.service';

import { Site } from '../structures/site';
import { EasiHttpParams } from '../interceptors/easi-http-params';
import { log } from './decorators/log.decorator';

@Injectable({
    providedIn: 'root'
})
export class SiteService {
    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private loginService: LoginService
    ) {}

    @log() createSite(site: Site): Observable<Site> {
        return this.http.post(`/sites`, site);
    }
    @log() updateSite(site: Site): Observable<Site> {
        return this.http.put(`/sites/${site.id}`, site);
    }
    @log() getSite(id: any): Observable<Site> {
        return this.http.get<Site>(`/sites/${id}`);
    }

    @log() getSites(filters): Observable<Site[]> {
        const params: any = {
            offset: filters.offset ? filters.offset : 0,
            ...filters
        };

        return this.http
            .get<Site[]>(`/sites`, { params })
            .pipe(map(this.sitesCallBackForDropdowns));
    }

    sitesCallBackForDropdowns(sitesList: Site[]): Site[] {
        return sitesList.map((site) => ({
            ...site,
            key: site.id,
            title: site.name
        }));
    }

    @log() getSitesCount(filters: any) {
        const params: any = {
            count: true,
            ...filters
        };

        return this.http.get(`/sites`, { params });
    }

    @log() getUnusedSites(): Observable<any> {
        const params: any = { isCorporation: false };
        return this.http.get(
            `/structures/${this.loginService.getUser().structureid}/sites/unused`,
            { params }
        );
    }

    @log() getUnusedCorporations(): Observable<any> {
        const params: any = { isCorporation: true };
        return this.http.get(
            `/structures/${this.loginService.getUser().structureid}/sites/unused`,
            { params }
        );
    }

    deleteAllUnusedSites() {
        const params: any = { isCorporation: false };
        return this.http.get(
            `/structures/${this.loginService.getUser().structureid}/sites/unused`,
            { params }
        );
    }

    @log() deleteAllUnusedCorporations() {
        const params: any = { isCorporation: true };
        return this.http.delete(
            `/structures/${this.loginService.getUser().structureid}/sites/unused`,
            { params }
        );
    }

    @log() deleteUnusedSite(id: number) {
        return this.http.delete(`/sites/${id}`);
    }

    @log() getDomains() {
        return this.http.get(`${this.configService.getTrainingEndPoint()}/domains`, {
            params: new EasiHttpParams({ overrideBaseUrl: true })
        });
    }
}
