<div class="role-group-container">
    <div class="role-group-description">
        <span class="icon-porte-voix"></span>
        <span>{{ roleGroup.name }}</span>
        <span class="role-group-counter">{{ getRoleGroupCounter() }}</span>
    </div>
    <div class="icons">
        <div class="hover-icons">
            <div
                class="icon-Editer"
                tooltip="Modifier ce groupe"
                tooltipColor="#199fe2"
                (click)="editRoleGroup($event)"
            ></div>
            <div
                class="icon-Cloner"
                tooltip="Cloner ce groupe"
                tooltipColor="#199fe2"
                (click)="cloneRoleGroup($event)"
            ></div>
            <div
                class="icon-bin"
                tooltip="Supprimer ce groupe"
                tooltipColor="#199fe2"
                (click)="deleteRoleGroup($event)"
            ></div>
        </div>
        <div class="default-icons"></div>
    </div>
</div>
