import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {
    /**
     * Pipe filtrant un array d'objets en recherchant une string ans une ou plusieurs propiétés de l'objet
     * @param array tableau contenant des objets à filter
     * @param searchTerm La chaine de caractères à chercher
     * @param propsToFilter les propriétés dans lesquelles on cherche cette chaine ex.: 'name' (sous forme de string)
     * Utilisation: | search:searchTerm:propriété:propriété2? etc.
     */
    transform(array: any[], searchTerm: string, ...propsToFilter: string[]): any {
        if (!propsToFilter || !searchTerm) {
            return array;
        }
        if (propsToFilter.length === 1) {
            return array.filter((item) =>
                item[propsToFilter[0]].toLowerCase().includes(searchTerm.toLowerCase())
            );
        } else {
            return array.filter((item) =>
                propsToFilter.some((prop) =>
                    item[prop].toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        }
    }
}
