import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA)
        private data: {
            message: string;
            title: string;
            buttonsValues?: { yes: string; no: string };
        }
    ) {}

    /**
     * Détermine le message à afficher dans le corps de la fenêtre
     * @returns {String} Le message à afficher dans le corps de la fenêtre
     */
    getMainMessage(): String {
        return this.data.message;
    }

    getTitle(): String {
        return this.data.title;
    }

    getYesButtonValue(): string {
        return this.data.buttonsValues ? this.data.buttonsValues.yes : 'OUI';
    }

    getNoButtonValue(): string {
        return this.data.buttonsValues ? this.data.buttonsValues.no : 'NON';
    }

    /**
     * Ferme la fenâtre de confirmation en renvoyant l'observable true
     */
    action(): void {
        this.dialogRef.close(true);
    }

    /**
     * Ferme la fenâtre de confirmation en renvoyant l'observable false
     */
    closeDialog(): void {
        this.dialogRef.close(false);
    }
}
