import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';

import { LoginService } from './login.service';

import { AddNewsComponent } from '../dialogs/add-news/add-news.component';
import { AddAvatarComponent } from '../dialogs/add-avatar/add-avatar.component';
import { AzureConfirmationComponent } from '../dialogs/azure-confirmation/azure-confirmation.component';
import { AzureLoginComponent } from '../dialogs/azure-login/azure-login.component';
import { ConfirmationComponent } from '../dialogs/confirmation/confirmation.component';
import { CreateContactComponent } from '../dialogs/create-contact/create-contact.component';
import { CreateGroupComponent } from '../dialogs/create-group/create-group.component';
import { CreateRoleGroupComponent } from '../dialogs/create-role-group/create-role-group.component';
import { CreateSiteComponent } from '../dialogs/create-site/create-site.component';
import { CreateStructureComponent } from '../dialogs/create-structure/create-structure.component';
import { PreviewImportYpareoComponent } from '../dialogs/preview-import-ypareo/preview-import-ypareo.component';
import { ErrorComponent } from '../dialogs/error/error.component';
import { DiagnosticComponent } from '../dialogs/diagnostic/diagnostic.component';
import { HelpComponent } from '../dialogs/help/help.component';
import { WarningComponent } from '../dialogs/warning/warning.component';
import { UploadFileComponent } from '../dialogs/upload-file/upload-file.component';
import { CsvImportUsersComponent } from '../dialogs/csv-import-users/csv-import-users.component';
import { RightsExceptionsComponent } from '../dialogs/rights-exceptions/rights-exceptions.component';

import { News } from '../structures/news';
import { Role } from '../structures/role';
import { Site } from '../structures/site';
import { Group } from '../structures/group';
import { Contact } from '../structures/contact';
import { PreviewImportYpareo } from '@/structures/preview-import-ypareo';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor(
        private dialog: MatDialog,
        private loginService: LoginService
    ) {
        this.subscriptions.add(
            this.loginService.closeAllDialogs$.subscribe((data: boolean) => {
                if (data) {
                    this.dialog.closeAll();
                }
            })
        );
    }

    private subscriptions: Subscription = new Subscription();

    /**
     * Ouvre la popup de création/édition de news
     * @param news (facultatif) la news à modifier
     */
    openAddNews(news?: News): Observable<any> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '950px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = news
            ? news
            : {
                  title: ''
              };

        const ref: MatDialogRef<AddNewsComponent> = this.dialog.open(
            AddNewsComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    /**
     * Ouvre la popup de création/édition de news
     * @param news (facultatif) la news à modifier
     */
    openAddAvatar(avatar): Observable<any> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '950px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = avatar;

        const ref: MatDialogRef<AddAvatarComponent> = this.dialog.open(
            AddAvatarComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    /**
     * Ouvre la popup de création/édition de news
     * @param news (facultatif) la news à modifier
     */
    openAzureLogin(): Observable<any> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        const ref: MatDialogRef<AzureLoginComponent> = this.dialog.open(
            AzureLoginComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openAzureConfirmation(): Observable<any> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        const ref: MatDialogRef<AzureConfirmationComponent> = this.dialog.open(
            AzureConfirmationComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    /**
     * Reçoit un titre et un message, retourne un booléen (observable)
     * @param title titre de la modale
     * @param message message à afficher
     */
    openConfirmationDialog(
        title: string,
        message: string,
        buttonsValues?: { yes: string; no: string }
    ): Observable<boolean> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {
            title,
            message,
            buttonsValues: buttonsValues
        };
        const ref: MatDialogRef<ConfirmationComponent> = this.dialog.open(
            ConfirmationComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    /**
     * Reçoit un titre et un message, retourne un booléen (observable)
     * @param title Titre de la dialog
     * @param message message à afficher
     */
    openCreateGroup(params: any): Observable<boolean | Group> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {
            ...params
        };
        const ref: MatDialogRef<CreateGroupComponent> = this.dialog.open(
            CreateGroupComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((error) => {
            this.openError(error);
        });
        return ref.afterClosed();
    }

    openCreateRoleGroup(params: any): Observable<Group> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {
            ...params
        };
        const ref: MatDialogRef<CreateRoleGroupComponent> = this.dialog.open(
            CreateRoleGroupComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((error) => {
            this.openError(error);
        });
        return ref.afterClosed();
    }

    /**
     * Reçoit un titre et un message, retourne un booléen (observable)
     * @param title Titre de la dialog
     * @param message message à afficher
     */
    openCreateContact(contact?: Contact): Observable<boolean | Contact> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '1200px';
        dialogConfig.maxHeight = 'none';
        dialogConfig.data = contact;
        const ref: MatDialogRef<CreateContactComponent> = this.dialog.open(
            CreateContactComponent,
            dialogConfig
        );
        ref.componentInstance.openAddAvatar.subscribe((avatar) => {
            this.openAddAvatar(avatar).subscribe((data) => {
                ref.componentInstance.contact.avatar = data;
            });
        });
        return ref.afterClosed();
    }

    /**
     * Reçoit un titre et un message, retourne un booléen (observable)
     * @param title Titre de la dialog
     * @param message message à afficher
     */
    openCreateSite(params: any): Observable<Site> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {
            ...params
        };
        const ref: MatDialogRef<CreateSiteComponent> = this.dialog.open(
            CreateSiteComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((error) => {
            this.openError(error);
        });
        return ref.afterClosed();
    }

    openCreateStructure(structure?: any): Observable<any> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        if (structure) {
            dialogConfig.data = {
                ...structure
            };
        }
        const ref: MatDialogRef<CreateStructureComponent> = this.dialog.open(
            CreateStructureComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe(() => {
            this.openHelpUnauthenticated();
        });
        return ref.afterClosed();
    }

    /**
     * Ouvre la popup d'erreur
     * @param content Le message d'erreur
     */
    openError(content?: String): Observable<any> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        if (content) {
            dialogConfig.data = content;
        }

        const ref: MatDialogRef<ErrorComponent> = this.dialog.open(ErrorComponent, dialogConfig);
        return ref.afterClosed();
    }

    /**
     * Ouvre la dialog de diagnostic
     * @param afterLogin Indique si l'ouverture de la dialog a été déclenché suite à une tentative de login
     * @returns Un observable d'un boolean
     */
    openDiagnostic(afterLogin: boolean = false): Observable<boolean> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.minWidth = '350px';
        dialogConfig.data = afterLogin;
        const ref: MatDialogRef<DiagnosticComponent> = this.dialog.open(
            DiagnosticComponent,
            dialogConfig
        );
        ref.componentInstance.openHelpUnauthenticated.subscribe(() => {
            this.openHelpUnauthenticated();
        });
        return ref.afterClosed();
    }

    openHelpUnauthenticated(): Observable<any> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '95vw';
        dialogConfig.maxWidth = '95vw';
        dialogConfig.maxHeight = '95vh';

        const ref: MatDialogRef<HelpComponent> = this.dialog.open(HelpComponent, dialogConfig);
        return ref.afterClosed();
    }

    openPreviewImportYpareo(data: PreviewImportYpareo): Observable<boolean> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.panelClass = 'new-dialog';
        if (data) {
            dialogConfig.data = data;
        }

        const ref: MatDialogRef<PreviewImportYpareoComponent> = this.dialog.open(
            PreviewImportYpareoComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    /**
     * Ouvre la popup d'avertissement
     * @param body le corps de la modale
     * @param title (fac) titre de la modale, sinon 'Avertissement'
     */
    openWarning(body: string, title?: string): Observable<any> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {
            body
        };
        if (title) {
            dialogConfig.data.title = title;
        }

        const ref: MatDialogRef<WarningComponent> = this.dialog.open(
            WarningComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openUploadFile(): Observable<any> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '950px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};

        const ref: MatDialogRef<UploadFileComponent> = this.dialog.open(
            UploadFileComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openImportUsersCSV(structureid: number): Observable<any> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = structureid;

        const ref: MatDialogRef<CsvImportUsersComponent> = this.dialog.open(
            CsvImportUsersComponent,
            dialogConfig
        );

        ref.componentInstance.openError.subscribe((error) => {
            this.openError(error);
        });
        return ref.afterClosed();
    }

    openRightsExceptions(application: any, role?: Role): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '800px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {
            application,
            role
        };
        const ref: MatDialogRef<RightsExceptionsComponent> = this.dialog.open(
            RightsExceptionsComponent,
            dialogConfig
        );
    }
}
