import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';
import { EasiHttpParams } from 'src/app/interceptors/easi-http-params';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
    constructor(private http: HttpClient, public dialogRef: MatDialogRef<HelpComponent>) {}

    helpData: any;
    searchTerm = '';
    filteredHelpData: any;

    ngOnInit() {
        this.http
            .get('https://cdn.easi-training.fr/help/help_unauthenticated.json', {
                params: new EasiHttpParams({ overrideBaseUrl: true, noCredentials: true })
            })
            .subscribe((data: any) => {
                this.helpData = data;
                this.filteredHelpData = data;
            });
    }

    /**
     * ferme le composant
     */
    close(): void {
        this.dialogRef.close();
    }
}
