import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoginService } from './login.service';

import { User } from '../structures/user';
import { Role } from '../structures/role';

import { log } from './decorators/log.decorator';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    constructor(private http: HttpClient, private loginService: LoginService) {}

    roles: Array<Role> = [];
    rolesAndTags: Array<Role> = [];

    additionalRoles: Array<Role> = [];

    /**
     * récupère la liste des utilisateurs en fonction des paramètres fournis
     * @param params objet contenant les paramètres de requête
     */
    @log() getUsers(args: any): Observable<any> {
        const params = {
            events: true,
            ...args
        };

        return this.http.get(`/users`, { params });
    }

    @log() getUsersCount(params: any): Observable<any> {
        params.count = true;
        return this.http.get(`/users`, { params });
    }

    @log() getRoles(): Observable<any> {
        if (this.roles.length) {
            return new Observable((observer) => {
                observer.next(
                    this.roles.map((role) => {
                        delete role.selected;
                        return role;
                    })
                );
                observer.complete();
            });
        } else {
            return new Observable((observer) => {
                this.http.get<Role[]>(`/roles`).subscribe((data: any) => {
                    this.roles = this.initRoles(data);
                    this.roles = this.addIconsToRoles(this.roles);
                    this.roles = this.formatArrayForDropdown(this.roles);
                    observer.next(this.roles);
                    observer.complete();
                });
            });
        }
    }

    @log() getRolesAndTags(): Observable<any> {
        if (this.rolesAndTags.length) {
            return new Observable((observer) => {
                observer.next(
                    this.rolesAndTags.map((role) => {
                        delete role.selected;
                        return role;
                    })
                );
                observer.complete();
            });
        } else {
            return new Observable((observer) => {
                const observableArray = [];
                observableArray.push(this.http.get<Role[]>(`/roles`));
                if (
                    this.loginService.getUser().roles.nationalAdmin ||
                    this.loginService.getUser().roles.localAdmin
                ) {
                    observableArray.push(this.http.get<Role[]>(`/tags/search`));
                }
                forkJoin(observableArray).subscribe((data: any) => {
                    if (data[1]) {
                        this.rolesAndTags = this.initRoles(data[0].concat(data[1]));
                    } else {
                        this.rolesAndTags = this.initRoles(data[0]);
                    }
                    this.rolesAndTags = this.addIconsToRoles(this.rolesAndTags);
                    this.rolesAndTags = this.formatArrayForDropdown(this.rolesAndTags);
                    observer.next(this.rolesAndTags);
                    observer.complete();
                });
            });
        }
    }

    @log() getAdditionalRoles(): Observable<any> {
        if (this.additionalRoles.length) {
            return new Observable((observer) => {
                observer.next(this.additionalRoles);
                observer.complete();
            });
        } else {
            return new Observable((observer) => {
                this.http.get<Role[]>(`/additional_roles`).subscribe((data: any) => {
                    this.additionalRoles = data;
                    this.additionalRoles = this.addIconsToRoles(this.additionalRoles);
                    this.additionalRoles = this.formatArrayForDropdown(this.additionalRoles);
                    observer.next(this.additionalRoles);
                    observer.complete();
                });
            });
        }
    }

    @log() getTags(): Observable<any> {
        return this.http.get(`/tags/search`);
    }

    /**
     * Met à jour le status enabled de l'utilisateur
     * @param user l'utilisateur à modifier
     * @param enable le statut après l'opération enabled(true)/disabled(false)
     */
    @log() updateUserEnabledStatus(user: User, enable: boolean) {
        return this.http.put(`/users/${user.id}/${enable ? 'enable' : 'disable'}`, {});
    }

    @log() enableUser(user: User) {
        return this.http.put(`/users/${user.id}/enable`, {});
    }

    @log() disableUser(user: User) {
        return this.http.put(`/users/${user.id}/disable`, {});
    }

    /**
     * requête une demande de mot de passe perdu
     * @param username
     * @param structureId
     */
    @log() forgotPassword(username, structureid): Observable<any> {
        return this.http.post('/users/forgot_password', { username, structureid });
    }

    @log() setPassword(userid: number, password: string, token: string) {
        return this.http.post('/users/password', { userid, password, token });
    }

    /**
     * requête une demande de mot de passe perdu
     * @param username
     * @param structureId
     */
    @log() updatePassword(userid: number, oldpassword: string, newpassword: string) {
        return this.http.put('/users/password', { userid, oldpassword, newpassword });
    }

    @log() setEmail(userid: number, email: string, token: string) {
        return this.http.post('/users/email', { token, userid, email });
    }

    @log() updateEmail(userid: number, email: string) {
        return this.http.put('/users/email', { userid, email });
    }

    @log() updateEmailWithHash(userid: number, email: string, token: string, hash: string) {
        return this.http.post('/users/update_email', { token, userid, email, hash });
    }

    @log() validateEmail(userid: number, email: string, token: string) {
        return this.http.post('/users/validate_email', { token, userid, email });
    }

    @log() exportCSV(params: any) {
        const param = params;
        params.encoding = 'UTF-8';
        params.delimiter = 'semicolon';
        params.limit = undefined;
        params.offset = undefined;

        const config = {
            params: param,
            ignoreLoadingBar: false,
            responseType: 'blob' as 'json'
        };

        return this.http.get(`/users/csvexport`, config);
    }

    @log() exportHistoryCSV(params: any) {
        return this.http
            .get(`/users`, {
                params: { events: true, csv: true, ...params },
                responseType: 'text' as 'json'
            })
            .pipe(map(this.formatGetUsersCsvResult));
    }

    @log() importCSV(structureid: number, file: File) {
        const params: any = {
            structureid,
            delimiter: 'semicolon'
        };

        const body: FormData = new FormData();
        body.append('file', file);

        return this.http.post(`/users/csvimport`, body, { params });
    }

    formatGetUsersCsvResult(data) {
        return data.replace(
            'id;username;firstname;lastname;email;enabled;externalID;localStructure;roles;sites;connectionNumber;lastAccess;inscriptionDate;FI/FC',
            "ID;Utilisateur;Prénom;Nom;Email;Actif;ID externe;Structure;Rôle;Sites;Nombre de connexions;Dernier accès;Date d'inscription;FI/FC"
        );
    }

    initRoles(data: Array<Role>) {
        if (this.loginService.getUser().roles.nationalAdmin) {
            this.roles = data;
        } else if (this.loginService.getUser().roles.localAdmin) {
            this.roles = data.filter((type) => type.shortname !== 'nationalAdmin');
        } else {
            this.roles = data.filter(
                (type) =>
                    type.shortname === 'learner' ||
                    type.shortname === 'prospect' ||
                    type.shortname === 'tutor'
            );
        }
        this.roles = this.roles.filter((role: Role) => {
            if (this.loginService.getUser().roles.nationalAdmin) {
                return true;
            } else {
                return role.shortname !== 'siteTeacher' && role.shortname !== 'nationalTeacher';
            }
        });
        return this.roles;
    }

    addIconsToRoles(roles: Array<Role>) {
        for (const role of roles) {
            if (role.shortname === 'nationalAdmin') {
                role.icon = 'icon-adminnational';
            } else if (role.shortname === 'localAdmin') {
                role.icon = 'icon-adminlocal';
            } else if (role.shortname === 'nationalTeacher') {
                role.icon = 'icon-auteurnational';
            } else if (role.shortname === 'internalTeacher') {
                role.icon = 'icon-formateurinterne';
            } else if (role.shortname === 'externalTeacher') {
                role.icon = 'icon-formateurexterne';
            } else if (role.shortname === 'corporationTeacher') {
                role.icon = 'icon-FormateurEntreprise';
            } else if (role.shortname === 'siteTeacher') {
                role.icon = 'icon-formateur-site';
            } else if (role.shortname === 'tutor') {
                role.icon = 'icon-tuteurentreprise';
            } else if (role.shortname === 'learner') {
                role.icon = 'icon-apprenant';
            } else if (role.shortname === 'prospect') {
                role.icon = 'icon-apprenant-prospect';
            }

            if (role.shortname === 'accountManager') {
                role.icon = 'icon-gestionnairedecomptes';
            } else if (role.shortname === 'contentManager') {
                role.icon = 'icon-gestionnairecontenuspayants';
            } else if (role.shortname === 'validator') {
                role.icon = 'icon-valideur';
            } else if (role.shortname === 'externalCallManager') {
                role.icon = 'icon-Telephone';
            } else if (role.shortname === 'contentLocal') {
                role.icon = 'icon-local';
            } else if (role.shortname === 'contentNational') {
                role.icon = 'icon-national';
            } else if (role.shortname === 'contentCreator') {
                role.icon = 'icon-ajouter-contenus';
            }

            if (role.name === 'Référent pédagogique') {
                role.icon = 'icon-easi-training-line';
            }
        }

        return roles;
    }

    formatArrayForDropdown(roles: Array<Role>): Array<any> {
        return roles.map((item) => ({
            ...item,
            title: item.name,
            key: item.shortname
        }));
    }
}
