import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import { FlashMessageComponent } from '../components/shared/flash-message/flash-message.component';
import { LoadingComponent } from '../components/shared/loading/loading.component';
import { DatesSelectorComponent } from '../components/shared/dates-selector/dates-selector.component';

import { ErrorComponent } from '../dialogs/error/error.component';
import { ConfirmationComponent } from '../dialogs/confirmation/confirmation.component';
import { WarningComponent } from '../dialogs/warning/warning.component';
import { HelpComponent } from '../dialogs/help/help.component';

import { DropdownModule } from 'uimm-dropdown';
import { TooltipModule } from 'uimm-tooltip';

import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { SearchPipe } from '../pipes/search.pipe';

@NgModule({
    declarations: [
        FlashMessageComponent,
        LoadingComponent,
        DatesSelectorComponent,
        ErrorComponent,
        ConfirmationComponent,
        WarningComponent,
        HelpComponent,
        SafeHtmlPipe,
        SearchPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        ReactiveFormsModule,
        LoadingBarModule,
        LoadingBarHttpClientModule,
        DropdownModule
    ],
    exports: [
        CommonModule,
        DropdownModule,
        TooltipModule,
        ReactiveFormsModule,
        FormsModule,
        MatExpansionModule,
        LoadingBarHttpClientModule,
        FlashMessageComponent,
        LoadingComponent,
        DatesSelectorComponent,
        ConfirmationComponent,
        ErrorComponent,
        HelpComponent,
        WarningComponent,
        SearchPipe,
        SafeHtmlPipe
    ],
    providers: [SearchPipe]
})
export class SharedModule {}
