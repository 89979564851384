<div *ngIf="!isLoading()">
    <div id="rights-component">
        <ng-container>
            <div class="rights-component_header" *ngIf="isNationalAdmin()">
                <lib-uimm-dropdown
                    [label]="'Structure'"
                    [labelWidth]="'220px'"
                    [(items)]="structures"
                    (itemsChange)="onStructureChange()"
                    [headers]="{
                        search: true,
                        selectAll: false,
                        count: false
                    }"
                ></lib-uimm-dropdown>
            </div>

            <div
                class="rights-component_main"
                *ngIf="structureSelected()"
                [ngStyle]="getMainStyles(applicationsList)"
            >
                <ng-container
                    *ngFor="
                        let application of filterApplicationsToShow(applicationsList);
                        index as appIndex
                    "
                >
                    <div
                        class="application"
                        [ngStyle]="{
                            'grid-column': appIndex + 2 + ' / span 1'
                        }"
                    >
                        <span
                            [ngClass]="getApplicationIcon(application)"
                            [style.color]="getApplicationColor(application)"
                            [tooltip]="application.name"
                            tooltipColor="#199fe2"
                        ></span>
                        <div
                            class="icon-Warning"
                            *ngIf="application.users.length > 0"
                            (click)="viewExceptions($event, application)"
                            tooltip="Exceptions"
                            tooltipColor="#199fe2"
                            tooltipPosition="right"
                        ></div>
                        <div *ngIf="application.app_id === 'easitraining'" class="roles-container">
                            <ng-container *ngFor="let role of additionalRolesAccess">
                                <div
                                    *ngIf="role.shortname !== 'externalCallManager'"
                                    [style.color]="getApplicationColor(application)"
                                    [ngClass]="role.icon"
                                    [tooltip]="role.name"
                                ></div>
                            </ng-container>
                        </div>
                        <div *ngIf="application.app_id === 'easicall'" class="roles-container">
                            <ng-container *ngFor="let role of additionalRolesAccess">
                                <div
                                    *ngIf="role.shortname === 'externalCallManager'"
                                    [style.color]="getApplicationColor(application)"
                                    [ngClass]="role.icon"
                                    [tooltip]="role.name"
                                ></div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngFor="let role of userTypes; index as roleIndex">
                    <ng-container *ngIf="showRole(role) && applicationsList">
                        <div
                            class="role"
                            [ngStyle]="{
                                'grid-row': roleIndex + 2
                            }"
                        ></div>
                        <div
                            class="role-description"
                            [ngStyle]="{
                                'grid-row': roleIndex + 2
                            }"
                        >
                            <span [ngClass]="role.icon"></span>{{ role.name }}
                        </div>
                        <div
                            class="role-button"
                            *ngFor="
                                let application of filterApplicationsToShow(applicationsList);
                                index as appIndex
                            "
                            [ngStyle]="{
                                'grid-row': roleIndex + 2,
                                'grid-column': appIndex + 2 + ' / span 1'
                            }"
                            [ngClass]="getRoleButtonIconClass(application, role)"
                            (click)="updateApplicationAccess(application, role)"
                        >
                            <div
                                class="icon-Warning"
                                *ngIf="
                                    application.users.length > 0 &&
                                    hasExceptionForRole(application, role)
                                "
                                (click)="viewExceptions($event, application, role)"
                                tooltip="Exceptions"
                                tooltipColor="#199fe2"
                                tooltipPosition="right"
                            ></div>
                            <div
                                *ngIf="application.app_id === 'easitraining'"
                                class="roles-container"
                            >
                                <ng-container *ngFor="let additionalRole of additionalRolesAccess">
                                    <div
                                        *ngIf="additionalRole.shortname !== 'externalCallManager'"
                                        [ngClass]="getAdditionalRoleIconClass(role, additionalRole)"
                                        (click)="updateAdditionalRoleAccess(role, additionalRole)"
                                    ></div>
                                </ng-container>
                            </div>
                            <div *ngIf="application.app_id === 'easicall'" class="roles-container">
                                <ng-container *ngFor="let additionalRole of additionalRolesAccess">
                                    <div
                                        *ngIf="additionalRole.shortname === 'externalCallManager'"
                                        [ngClass]="getAdditionalRoleIconClass(role, additionalRole)"
                                        (click)="updateAdditionalRoleAccess(role, additionalRole)"
                                    ></div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div class="empty-structure" *ngIf="!structureSelected()">
                <p>Veuillez sélectionner une structure.</p>
                <div>
                    <img src="assets/img/library-default.png" />
                </div>
            </div>
        </ng-container>
    </div>
</div>

<app-loading *ngIf="isLoading()"></app-loading>
