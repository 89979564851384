<div *ngIf="!isLoading('getSites')">
    <div class="header">
        <div class="count-container">
            <div class="icon-local"></div>
            <div>
                {{ getLabel() }}
            </div>
            <div class="show-element">
                <div>Afficher les :</div>
                <div>
                    Sites
                    <input
                        type="checkbox"
                        [(ngModel)]="displaySites"
                        (click)="toggleDisplaySites()"
                    />
                </div>
                <div>
                    Entreprises
                    <input
                        type="checkbox"
                        [(ngModel)]="displayCorporations"
                        (click)="toggleDisplayCorporations()"
                    />
                </div>
            </div>
        </div>
        <div class="search-container">
            <div class="search-container">
                <input
                    type="search"
                    class="search"
                    [(ngModel)]="searchTerm"
                    placeholder="Chercher..."
                    (keyup.enter)="refreshSites()"
                />
                <span class="icon-search" (click)="refreshSites()"></span>
            </div>
        </div>
    </div>
    <div
        class="container-sites"
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="nextPageSites()"
    >
        <mat-expansion-panel class="container" *ngFor="let site of sites">
            <mat-expansion-panel-header
                [collapsedHeight]="'50px'"
                [expandedHeight]="'50px'"
                (click)="getSite(site)"
            >
                <div class="title">
                    <div
                        class="icon"
                        [ngClass]="!site.isCorporation ? 'icon-site' : 'icon-corporation'"
                    ></div>
                    <div class="label">
                        {{ site.name }}
                    </div>
                </div>
                <div>
                    <div
                        class="icon-Editer"
                        [tooltip]="'Editer ce site'"
                        (click)="openEditSite($event, site)"
                    ></div>
                </div>
            </mat-expansion-panel-header>
            <div>
                <div
                    class="user"
                    *ngFor="let user of site.users"
                    [ngClass]="{
                        disabled: !user.enabled
                    }"
                >
                    <div>
                        <div class="icon" [ngClass]="getUserIcon(user)"></div>
                        <div class="label">
                            {{ user.lastname.toUpperCase() + ' ' + user.firstname }}
                        </div>
                    </div>
                    <div class="icons">
                        <div
                            class="icon-FicheUser"
                            (click)="openUserDialog(user)"
                            [tooltip]="'Voir la fiche'"
                        ></div>
                        <div
                            class="icon-DesactiverUser"
                            *ngIf="user.enabled"
                            (click)="disableUser(user)"
                            [tooltip]="'Désactiver l\'utilisateur'"
                        ></div>
                        <div
                            class="icon-Reactiver"
                            *ngIf="!user.enabled"
                            (click)="enableUser(user)"
                            [tooltip]="'Activer l\'utilisateur'"
                        ></div>

                        <div
                            class="icon-DesactiverUser"
                            *ngIf="!user.enabled"
                            [tooltip]="'Utilisateur désactivé'"
                        ></div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
    <app-loading *ngIf="isLoading('nextPageSites')"></app-loading>

    <div class="add-site" [tooltip]="'Créer un site'" (click)="openCreateSite()">
        <div class="icon-plus"></div>
    </div>
</div>

<app-loading *ngIf="isLoading('getSites')"></app-loading>
