import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';

import { LoginService } from '../services/login.service';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard {
    constructor(private loginService: LoginService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot) {
        switch (route.routeConfig.path) {
            case 'cgu':
            case 'validateEmail':
            case 'setPassword': {
                return true;
            }
            case 'login':
            case 'resendPassword': {
                if (this.loginService.getUser()) {
                    return this.router.parseUrl('/');
                } else {
                    return true;
                }
            }
            case 'updatePassword':
            case 'setEmail':
            case 'updateEmail':
            case 'token': {
                if (this.loginService.getUser()) {
                    return true;
                } else {
                    this.router.navigate(['login']);
                    return false;
                }
            }
            default: {
                return false;
            }
        }
    }
}
