import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ImageCroppedEvent } from 'ngx-image-cropper';

/**
 *  fenetre de dialogue pour la deconnexion.
 */
@Component({
    selector: 'app-add-avatar',
    templateUrl: './add-avatar.component.html',
    styleUrls: ['./add-avatar.component.scss']
})
export class AddAvatarComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public avatar: string,
        public dialogRef: MatDialogRef<AddAvatarComponent>
    ) {}

    file: File = null;
    imageChangedEvent: any = '';
    croppedImage: any = '';

    ngOnInit() {
        if (this.avatar) {
            this.croppedImage = this.avatar;
        }
    }

    canUploadFile(): boolean {
        return this.croppedImage ? true : false;
    }

    handleFileInput(files): void {
        this.file = files[0];
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    saveImage() {
        this.dialogRef.close(this.croppedImage);
    }

    /**
     * Ferme la fenêtre de création de cloud
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
