// Internal dependencies
import { Component, OnInit, model, input } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

// External dependencies

// Modules
import { SharedModule } from '@/modules/shared.module';

// Components

// Services
import { DialogService } from '@/services/dialog.service';
import { YpareoService } from '@/services/ypareo.service';
import { FlashMessageService } from '@/services/flash-message.service';

// Interfaces
import { Role } from '@/structures/role';
import { YpareoUser } from '@/structures/ypareo-user';
import { PreviewImportYpareo } from '@/structures/preview-import-ypareo';

// Pipes

@Component({
    selector: 'app-user-ypareo',
    templateUrl: './user-ypareo.component.html',
    styleUrls: ['./user-ypareo.component.scss'],
    imports: [SharedModule, MatButtonModule, MatCheckboxModule, MatIconModule]
})
export class UserYpareoComponent implements OnInit {
    user = model<YpareoUser>();
    roles = input<Array<Role>>();

    constructor(
        private dialogService: DialogService,
        private ypareoService: YpareoService,
        private flashMessageService: FlashMessageService
    ) {}

    ngOnInit() {}

    // #region Getters

    getUserRole(): Role {
        if (this.roles()) {
            return this.roles().find((role: Role) => this.user().user_type === role.shortname);
        }
    }

    handleSelectUser() {
        this.user.set({ ...this.user(), selected: !this.user().selected });
    }

    // #endregion Getters

    // #region Handlers

    handleSynchronizeUser() {
        this.ypareoService
            .getImportPreview(this.user().ypareo_id)
            .subscribe((data: PreviewImportYpareo) => {
                if (data.groupnames_to_create.length > 0 || data.sitenames_to_create.length > 0) {
                    this.dialogService
                        .openPreviewImportYpareo(data)
                        .subscribe((confirmed: boolean) => {
                            if (confirmed) {
                                this.setUserToSynchronize();
                            }
                        });
                } else {
                    this.setUserToSynchronize();
                }
            });
    }

    // #endregion Handlers

    // #region Internals

    private setUserToSynchronize() {
        this.ypareoService.setUsersToSynchronize(this.user().ypareo_id).subscribe(() => {
            this.user().to_synchronize = 1;
            this.user().selected = false;
            this.flashMessageService.flash('La synchronisation des utilisateurs est en cours');
        });
    }

    // #endregion Internals
}
