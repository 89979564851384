import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-azure-confirmation',
    templateUrl: './azure-confirmation.component.html',
    styleUrls: ['./azure-confirmation.component.scss']
})
export class AzureConfirmationComponent {
    constructor(public dialogRef: MatDialogRef<AzureConfirmationComponent>) {}

    closeDialog() {
        this.dialogRef.close();
    }
}
