<div class="users-ypareo">
    <div class="tm-flex tm-flex-row tm-justify-between tm-gap-2 tm-section filters">
        <div class="tm-flex tm-align-center tm-gap-2">
            <div>
                <span>
                    <b>{{ users?.length || 0 }}</b> utilisateurs
                </span>
                @if (getSelectedUsersCount() > 0) {
                    (<b>{{ getSelectedUsersCount() }}</b> sélectionnés)
                }
            </div>
            <div>
                <div>
                    <mat-form-field class="example-form-field">
                        <mat-label>Recherche</mat-label>
                        <input
                            matInput
                            type="text"
                            [(ngModel)]="filters.search"
                            (change)="getUsers()"
                        />
                        @if (filters.search) {
                            <button
                                matSuffix
                                mat-icon-button
                                aria-label="Clear"
                                (click)="handleClickResetSearch()"
                            >
                                <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="tm-flex tm-align-center tm-gap-0.5">
            <div>
                <button
                    mat-flat-button
                    (click)="handleClickImportSelection()"
                    [disabled]="!getSelectedUsersCount()"
                >
                    Importer la sélection
                </button>
            </div>
            <div>
                <button
                    mat-flat-button
                    (click)="handleClickExportCSV()"
                    [disabled]="!getSelectedUsersCount()"
                >
                    Exporter en CSV
                </button>
            </div>
        </div>
    </div>

    <div class="tm-flex tm-align-center users-header">
        <span [tooltip]="getCheckboxTooltip()">
            <mat-checkbox
                [disabled]="users.length === 0 || users.length > 100"
                (change)="handleToggleUsers($event)"
            ></mat-checkbox>
        </span>

        <mat-form-field>
            <mat-label>
                <mat-icon> account_circle </mat-icon>
                Type d'utilisateurs</mat-label
            >
            <mat-select (selectionChange)="handleUserTypeChange($event)">
                <mat-option>Tous</mat-option>
                @for (role of roles; track role.shortname) {
                    <mat-option [value]="role.shortname">
                        <span [class]="role.icon"></span>{{ role.name }}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>
        <div>Groupes Ypareo</div>
        <div>Groupes EASi</div>
        <mat-form-field>
            <mat-label>État des utilisateurs</mat-label>
            <mat-select (selectionChange)="handleDisplayStatusChange($event)">
                <mat-option>Tous</mat-option>
                <mat-option value="new_user">Nouveau</mat-option>
                <mat-option value="needs_update">Mise à jour</mat-option>
                <mat-option value="up_to_date">Utilisateur EASi</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    @if (!isLoading('getUsers')) {
        <cdk-virtual-scroll-viewport
            appendOnly
            itemSize="50"
            minBufferPx="500"
            maxBufferPx="600"
            class="tm-users"
        >
            <app-user-ypareo
                *cdkVirtualFor="let user of users; index as i"
                [(user)]="users[i]"
                [roles]="roles"
            ></app-user-ypareo>
        </cdk-virtual-scroll-viewport>
    } @else {
        <app-loading></app-loading>
    }
</div>
