import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from './shared.module';

import { LoginComponent } from '../components/login/login/login.component';
import { ResendPasswordComponent } from '../components/login/resend-password/resend-password.component';
import { SetEmailComponent } from '../components/login/set-email/set-email.component';
import { UpdateEmailComponent } from '../components/login/update-email/update-email.component';
import { SetPasswordComponent } from '../components/login/set-password/set-password.component';
import { UpdatePasswordComponent } from '../components/login/update-password/update-password.component';
import { ValidateEmailComponent } from '../components/login/validate-email/validate-email.component';
import { TokenComponent } from '../components/login/token/token.component';
import { CGUComponent } from '../components/login/cgu/cgu.component';

import { DiagnosticComponent } from '../dialogs/diagnostic/diagnostic.component';
import { AzureLoginComponent } from '../dialogs/azure-login/azure-login.component';
import { AzureConfirmationComponent } from '../dialogs/azure-confirmation/azure-confirmation.component';

@NgModule({
    declarations: [
        LoginComponent,
        SetEmailComponent,
        UpdateEmailComponent,
        ResendPasswordComponent,
        SetPasswordComponent,
        UpdatePasswordComponent,
        ValidateEmailComponent,
        TokenComponent,
        CGUComponent,
        DiagnosticComponent,
        AzureLoginComponent,
        AzureConfirmationComponent
    ],
    imports: [CommonModule, SharedModule],
    exports: []
})
export class LoginModule {}
