import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ConfigService } from '../../../services/config.service';
import { DialogService } from '../../../services/dialog.service';
import { LoginService } from '../../../services/login.service';
import { UsersService } from '../../../services/users.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-update-password',
    templateUrl: './update-password.component.html',
    styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit, OnDestroy {
    constructor(
        private formBuilder: UntypedFormBuilder,
        private configService: ConfigService,
        private dialogService: DialogService,
        private loginService: LoginService,
        private usersService: UsersService,
        private router: Router,
        private cookieService: CookieService
    ) {}

    subscriptions: Subscription = new Subscription();

    errorMessage: string;
    successMessage: string;

    updatePasswordForm: UntypedFormGroup;

    ngOnInit() {
        this.updatePasswordForm = this.formBuilder.group({
            oldpassword: ['', Validators.required],
            newpassword: ['', Validators.required],
            confirmation_newpassword: ['', Validators.required]
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    isDarkTheme() {
        return this.cookieService.get('dark') === 'true';
    }

    getHeaderText() {
        return this.configService.getConfig().header_text;
    }

    getImageTop() {
        return this.configService.getConfig().left_image_top_dev;
    }

    getImageBottom() {
        return this.configService.getConfig().left_image_bot;
    }

    getVersion() {
        return this.configService.getConfig().version;
    }

    getRightSidePanelBackground(): any {
        return {
            'background-image': `url(${this.configService.getConfig().right_image})`
        };
    }

    updatePassword(): void {
        if (
            this.updatePasswordForm.value.newpassword ===
            this.updatePasswordForm.value.confirmation_newpassword
        ) {
            this.subscriptions.add(
                this.usersService
                    .updatePassword(
                        this.loginService.getUser().id,
                        this.updatePasswordForm.value.oldpassword,
                        this.updatePasswordForm.value.newpassword
                    )
                    .subscribe(
                        (data: any) => {
                            this.errorMessage = '';
                            this.successMessage =
                                'Votre mot de passe a bien été modifié. Vous allez être redirigé dans 5 secondes.';
                            setTimeout(() => {
                                this.loginService.clearExceptionAndNavigate();
                            }, 5000);
                        },
                        (error: HttpErrorResponse) => {
                            this.errorMessage = error.error.userMessage;
                        }
                    )
            );
        } else {
            this.errorMessage = 'Les mots de passe que vous avez rentrées ne sont pas identiques.';
        }
    }

    goToCGU() {
        this.router.navigate(['cgu']);
    }

    openDiagnostic() {
        this.dialogService.openDiagnostic(false);
    }

    getAnchoredLogoURL() {
        return this.configService.getConfig().anchored_logo_url;
    }
}
