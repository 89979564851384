import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';

import { DialogService } from '../../../services/dialog.service';
import { FlashMessageService } from '../../../services/flash-message.service';
import { LoginService } from '../../../services/login.service';
import { LoadingService } from '../../../services/loading.service';
import { StructuresService } from '../../../services/structures.service';
import { UsersService } from '../../../services/users.service';
import { ApplicationService } from '../../../services/application.service';

import { Application, ApplicationAccess } from '../../../structures/application';
import { Role } from '../../../structures/role';
import { Structure } from '../../../structures/structure';

@Component({
    selector: 'app-rights',
    templateUrl: './rights.component.html',
    styleUrls: ['./rights.component.scss']
})
export class RightsComponent implements OnInit, OnDestroy {
    constructor(
        private applicationService: ApplicationService,
        private structuresService: StructuresService,
        private loginService: LoginService,
        private loadingService: LoadingService,
        private flashMessageService: FlashMessageService,
        private usersService: UsersService,
        private dialogService: DialogService
    ) {}

    subscriptions$: Subscription = new Subscription();
    structures: Array<Structure>;
    userTypes: Role[];
    additionalRoles: Array<any>;
    additionalRolesAccess: Array<any>;
    applicationsList: Array<ApplicationAccess>;

    /**********************************
     *      COMPONENT LIFECYCLE       *
     **********************************/
    ngOnInit(): void {
        this.loadingService.startLoading('rights', 'getStructures');
        this.subscriptions$.add(
            this.structuresService.structures$.subscribe((structuresFromService: Structure[]) => {
                this.loadingService.stopLoading('rights', 'getStructures');
                this.structures = structuresFromService.map((st) => ({
                    ...st,
                    selected: this.loginService.getUser().structureid === st.id
                }));
                if (this.structureSelected().key) {
                    this.getApplicationsAccess();
                    this.getAdditionalRolesAccess();
                }
            })
        );

        this.loadingService.startLoading('rights', 'userTypes');
        this.subscriptions$.add(
            this.usersService.getRoles().subscribe((userTypes: Role[]) => {
                this.userTypes = userTypes;
                this.loadingService.stopLoading('rights', 'userTypes');
            })
        );
        this.subscriptions$.add(
            this.usersService.getAdditionalRoles().subscribe((additionalRoles: Array<any>) => {
                this.additionalRoles = additionalRoles;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    /**********************************
     *     GESTION DES STRUCTURES     *
     **********************************/

    structureSelected(): Structure | undefined {
        if (this.structures && this.structures.some((i) => i.selected)) {
            return this.structures.find((item) => item.selected);
        }
        return undefined;
    }

    onStructureChange(): void {
        if (this.structureSelected().key) {
            this.getApplicationsAccess();
            this.getAdditionalRolesAccess();
        }
    }

    /**********************************
     *       GESTION DES ROLES        *
     **********************************/

    isNationalAdmin(): boolean {
        if (this.loginService.getUser()) {
            return this.loginService.getUser().roles.nationalAdmin;
        }
    }

    isLocalAdmin(): boolean {
        if (this.loginService.getUser()) {
            return this.loginService.getUser().roles.localAdmin;
        }
    }

    /**********************************
     *       GESTION DES DROITS       *
     **********************************/

    getApplicationsAccess() {
        this.loadingService.startLoading('rights', 'getApplicationsAccess');
        this.subscriptions$.add(
            this.applicationService
                .getApplicationsAccess(this.structureSelected().key)
                .subscribe((data: ApplicationAccess[]) => {
                    this.loadingService.stopLoading('rights', 'getApplicationsAccess');
                    this.applicationsList = data.filter((application: ApplicationAccess) => {
                        return (
                            [
                                'easitraining',
                                'easilearning',
                                'easicall',
                                'easicloud',
                                'easi_ncv2p_pilot-' + this.structureSelected().shortname,
                                'easi_ncv2_' + this.structureSelected().shortname,
                                'easiforum',
                                'easichat',
                                'easimedia',
                                'machinepaysdelaloire',
                                'msoffice',
                                'chat',
                                'phone',
                                'orthodidacte_formateur'
                            ].indexOf(application.app_id) > -1
                        );
                    });
                    const indexNCV2 = this.applicationsList.findIndex(
                        (application) =>
                            application.app_id ===
                                'easi_ncv2p_pilot-' + this.structureSelected().shortname ||
                            application.app_id === 'easi_ncv2_' + this.structureSelected().shortname
                    );
                    if (indexNCV2 !== -1) {
                        const NCV2 = this.applicationsList.splice(indexNCV2, 1)[0];
                        const indexNCV1 = this.applicationsList.findIndex(
                            (application) => application.app_id === 'easicloud'
                        );
                        this.applicationsList.splice(indexNCV1 + 1, 0, NCV2);
                    }
                })
        );
    }

    getAdditionalRolesAccess() {
        this.loadingService.startLoading('rights', 'getAdditionalRoleAccess');
        this.subscriptions$.add(
            this.applicationService
                .getAdditionalRolesAccess(this.structureSelected().key)
                .subscribe((data: ApplicationAccess[]) => {
                    this.additionalRolesAccess = data;
                    this.loadingService.stopLoading('rights', 'getAdditionalRoleAccess');
                })
        );
    }

    /**********************************
     *       ICONES ET COULEURS       *
     **********************************/

    getApplicationIcon(application: Application): string {
        switch (application.app_id) {
            case 'easitraining':
                return 'icon-formateurinterne';
            case 'easilearning':
                return 'icon-easi-training-line';
            case 'easicall':
            case 'phone':
                return 'icon-easi-call-line';
            case 'easicloud':
            case 'easi_ncv2p_pilot-' + this.structureSelected().shortname:
            case 'easi_ncv2_' + this.structureSelected().shortname:
                return 'icon-easi-cloud-line';
            case 'easiforum':
                return 'icon-easi-forum-line';
            case 'easichat':
            case 'chat':
                return 'icon-Messages';
            case 'easimedia':
                return 'icon-easi-media-line';
            case 'machinepaysdelaloire':
                return 'icon-logoeasi';
            case 'msoffice':
                return 'icon-Office365';
            case 'orthodidacte_formateur':
                return 'icon orthodidacte';
        }
    }

    getApplicationColor(application: Application): string {
        switch (application.app_id) {
            case 'easitraining':
                return '#007FAD';
            case 'easilearning':
                return '#199fe2';
            case 'easicall':
            case 'phone':
                return '#f36';
            case 'easicloud':
            case 'easi_ncv2p_pilot-' + this.structureSelected().shortname:
            case 'easi_ncv2_' + this.structureSelected().shortname:
                return '#e8b600';
            case 'easiforum':
                return '#7f4482';
            case 'easichat':
            case 'chat':
                return '#102c3a';
            case 'easimedia':
                return '#01a59b';
            case 'machinepaysdelaloire':
                return '#424242';
            case 'msoffice':
                return '#dc5928';
            case 'orthodidacte_formateur':
                return '#424242';
        }
    }

    getMainStyles(applicationsList) {
        if (applicationsList) {
            return {
                'grid-template-columns': `auto repeat(${applicationsList.length}, auto)`,
                'grid-template-rows': `auto`
            };
        }
    }

    getRoleButtonIconClass(application: any, role: Role): string[] {
        return [
            this.hasApplicationRights(application, role) ? 'icon-selected' : 'icon-select',
            this.isRightLocked(application, role) ? 'locked' : ''
        ];
    }

    getAdditionalRoleIconClass(role: any, additionalRole: Role): string[] {
        return [
            this.hasAdditionalRole(role, additionalRole) ? 'icon-selected' : 'icon-select',
            this.isAdditionalRoleLocked(role, additionalRole) ? 'locked' : ''
        ];
    }

    updateAdditionalRoleAccess(role: Role, additionalRole: any): void {
        if (!this.isAdditionalRoleLocked(role, additionalRole)) {
            const params = {
                structureid: this.structureSelected().key,
                type: 'role',
                instance: role.shortname,
                additional_role_id: additionalRole.id,
                allow: !this.hasAdditionalRole(role, additionalRole)
            };
            this.subscriptions$.add(
                this.applicationService
                    .setAdditionalRolesAccess(params)
                    .subscribe((response: boolean) => {
                        this.flashMessageService.flash(
                            'Les droits des utilisateurs ont été mis à jour.'
                        );
                        for (const i in additionalRole.roles) {
                            if (additionalRole.roles[i].role === role.shortname) {
                                additionalRole.roles[i].allow = params.allow;
                            }
                        }
                    })
            );
        }
    }

    /***************************************
     *        LOGIQUE DU COMPOSANT         *
     ***************************************/

    hasApplicationRights(application: any, role: Role): boolean {
        for (const i in application.roles) {
            if (
                application.roles[i].role === role.shortname &&
                application.roles[i].allow !== null
            ) {
                return application.roles[i].allow;
            }
        }
        for (const j in application.general.roles) {
            if (
                application.general.roles[j].role === role.shortname &&
                application.general.roles[j].allow !== null
            ) {
                return application.general.roles[j].allow;
            }
        }
        if (application.structure.allow !== null) {
            return application.structure.allow;
        }
        return application.general.default.allow;
    }

    isRightLocked(application: any, role: Role): boolean {
        for (const i in application.roles) {
            if (
                application.roles[i].role === role.shortname &&
                application.roles[i].locked !== null
            ) {
                return application.roles[i].locked;
            }
        }
        for (const j in application.general.roles) {
            if (
                application.general.roles[j].role === role.shortname &&
                application.general.roles[j].locked !== null
            ) {
                return application.general.roles[j].locked;
            }
        }
        if (application.structure.locked !== null) {
            return application.structure.locked;
        }
        return application.general.default.locked;
    }

    hasAdditionalRole(role: Role, additionalRole: any): boolean {
        for (const i in additionalRole.roles) {
            if (
                additionalRole.roles[i].role === role.shortname &&
                additionalRole.roles[i].allow !== null
            ) {
                return additionalRole.roles[i].allow;
            }
        }
        for (const j in additionalRole.general.roles) {
            if (
                additionalRole.general.roles[j].role === role.shortname &&
                additionalRole.general.roles[j].allow !== null
            ) {
                return additionalRole.general.roles[j].allow;
            }
        }
        if (additionalRole.structure.allow !== null) {
            return additionalRole.structure.allow;
        }
        return additionalRole.general.default.allow;
    }

    isAdditionalRoleLocked(role: Role, additionalRole: any): boolean {
        for (const i in additionalRole.roles) {
            if (
                additionalRole.roles[i].role === role.shortname &&
                additionalRole.roles[i].locked !== null
            ) {
                return additionalRole.roles[i].locked;
            }
        }
        for (const j in additionalRole.general.roles) {
            if (
                additionalRole.general.roles[j].role === role.shortname &&
                additionalRole.general.roles[j].locked !== null
            ) {
                return additionalRole.general.roles[j].locked;
            }
        }
        if (additionalRole.structure.locked !== null) {
            return additionalRole.structure.locked;
        }
        return additionalRole.general.default.locked;
    }

    hasExceptionForRole(application: any, role: Role): boolean {
        for (const i in application.users) {
            if (application.users[i].roles[role.shortname]) {
                return true;
            }
        }
        return false;
    }

    updateApplicationAccess(application: Application, role: Role): void {
        if (!this.isRightLocked(application, role)) {
            const params = {
                structureid: this.structureSelected().key,
                type: 'role',
                instance: role.shortname,
                applicationid: application.id,
                allow: !this.hasApplicationRights(application, role)
            };
            this.subscriptions$.add(
                this.applicationService
                    .setApplicationAccess(params)
                    .subscribe((response: boolean) => {
                        this.flashMessageService.flash(
                            'Les droits des utilisateurs ont été mis à jour.'
                        );
                        for (const i in application.roles) {
                            if (application.roles[i].role === role.shortname) {
                                application.roles[i].allow = params.allow;
                            }
                        }
                    })
            );
        }
    }

    canShowApplication(application): boolean {
        if (
            application.general.default.allow === true ||
            application.general.default.locked === false
        ) {
            return true;
        }

        if (application.structure.allow === true || application.structure.locked === false) {
            return true;
        }

        for (const i in application.general.roles) {
            if (
                application.general.roles[i].allow === true ||
                application.general.roles[i].locked === false
            ) {
                return true;
            }
        }

        for (const j in application.roles) {
            if (application.roles[j].allow === true || application.roles[j].locked === false) {
                return true;
            }
        }

        for (const j in application.roles) {
            if (application.roles[j].allow === true || application.roles[j].locked === false) {
                return true;
            }
        }

        for (const k in application.users) {
            if (application.users[k].allow === true || application.users[k].locked === false) {
                return true;
            }
        }

        for (const l in application.groups) {
            if (application.groups[l].allow === true || application.groups[l].locked === false) {
                return true;
            }
        }

        return false;
    }

    filterApplicationsToShow(appList: Application[]): Application[] {
        if (appList) {
            return appList.filter((app) => this.canShowApplication(app));
        }
    }

    showRole(role: Role): boolean {
        if (
            role.shortname === 'validator' ||
            role.shortname === 'contentManager' ||
            role.shortname === 'accountManager' ||
            role.shortname === 'nationalAdmin' ||
            role.shortname === 'localAdmin' ||
            role.shortname === 'externalCallManager'
        ) {
            return false;
        } else if (role.shortname === 'siteTeacher' && this.isLocalAdmin()) {
            return false;
        } else if (
            role.shortname === 'nationalTeacher' &&
            (this.isLocalAdmin() || this.structureSelected().shortname !== 'N4S')
        ) {
            return false;
        } else {
            return true;
        }
    }

    viewExceptions($event: MouseEvent, application: any, role?: Role): void {
        $event.stopImmediatePropagation();
        this.dialogService.openRightsExceptions(application, role);
    }

    isLoading() {
        return this.loadingService.isLoading('rights');
    }
}
