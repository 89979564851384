export const tinyConfig = {
    toolbar: [
        'undo redo | styleselect | code | styleheading | bold italic underline | bullist numlist | alignleft aligncenter alignright alignjustify | forecolor | table | pagebreak | mybutton_image | mybutton_format'
    ],
    menubar: false,
    height: 400 + 'px',
    plugins:
        'image code imagetools preview textcolor colorpicker advlist pagebreak print paste advlist table media lists',
    style_formats: [
        {
            title: 'Texte courant',
            block: 'p',
            styles: {
                color: '#424242',
                'font-size': '18px',
                'font-style': 'normal',
                'font-weight': 'normal',
                'text-transform': 'normal',
                'text-align': 'left'
            }
        },
        {
            title: 'Titre 1',
            block: 'h1',
            styles: {
                color: '#199fe2',
                'font-weight': 'bold',
                'font-size': '30px',
                'text-transform': 'uppercase',
                'text-align': 'center'
            }
        },
        {
            title: 'Titre 2',
            block: 'h2',
            styles: {
                color: '#199fe2',
                'font-weight': 'bold',
                'font-size': '20px',
                'text-transform': 'uppercase',
                'text-align': 'left'
            }
        },
        {
            title: 'Titre 3',
            block: 'h3',
            styles: {
                color: '#199fe2',
                'font-weight': 'bold',
                'font-size': '18px',
                'text-transform': 'uppercase',
                'text-align': 'left'
            }
        },
        {
            title: 'Légende',
            block: 'p',
            styles: {
                color: '#199fe2',
                'font-size': '18px',
                'font-style': 'italic',
                'text-align': 'left'
            }
        },
        {
            title: 'Encadré',
            inline: 'span',
            styles: {
                display: 'inline-block',
                'font-weight': 'normal',
                'font-size': '18px',
                'font-style': 'normal',
                padding: '10px',
                color: 'white',
                background: '#199fe2',
                'text-align': 'left'
            }
        },
        {
            title: 'Verbatim',
            inline: 'span',
            classes: 'special',
            styles: {
                display: 'inline-block',
                'font-weight': 'bold',
                'font-size': '18px',
                'font-style': 'italic',
                padding: '10px',
                color: 'white',
                background: '#11384b',
                'text-align': 'left'
            }
        }
    ],
    convert_fonts_to_spans: true,
    paste_word_valid_elements:
        'b,strong,i,em,h1,h2,u,p,ol,ul,li,a[href],span,color,font-size,font-color,font-family,mark,table,tr,td',
    paste_retain_style_properties: 'all',
    textcolor_map: [
        '199FE2',
        '199FE2',
        '102C3A',
        '102C3A',
        '146187',
        '146187',
        '11384B',
        '11384B',
        '01A59B',
        '01A59B',
        'CCEDEB',
        'CCEDEB',
        '424242',
        '424242',
        'A8A8A8',
        'A8A8A8',
        'FF5A00',
        'FF5A00',
        'EA63D2',
        'EA63D2',
        'AB80FB',
        'AB80FB',
        '199FE2',
        '199FE2',
        '19BAE2',
        '19BAE2',
        '007A73',
        '007A73',
        '1F94CE',
        '1F94CE',
        '01A59B',
        '01A59B',
        'FF3366',
        'FF3366',
        'FF0000',
        'FF0000'
    ],
    advlist_number_styles: 'default,lower-alpha,lower-roman,upper-alpha,upper-roman',
    advlist_bullet_styles: 'default',
    media_alt_source: false,
    media_poster: false,
    default_link_target: '_blank',
    extended_valid_elements: 'a[href|target=_blank]',
    file_browser_callback: function (field_name, url, type, win) {
        win.document.getElementById(field_name).value = 'my browser value';
    }
};

export const tinyConfigContact = {
    toolbar: [
        'undo redo | styleselect | fontsizeselect | styleheading | bold italic underline | alignleft aligncenter alignright alignjustify'
    ],
    menubar: false,
    height: '100%',
    plugins: 'preview textcolor colorpicker paste',
    content_style:
        'body {background: transparent; height: 120px; margin: 15px 0px; line-height: 28px; font-family: "Source Sans Pro", sans-serif;}',
    style_formats: [
        {
            title: 'Texte courant',
            block: 'p',
            styles: {
                color: '#424242',
                'font-size': '18px',
                'font-style': 'normal',
                'font-weight': 'normal',
                'text-transform': 'normal',
                'text-align': 'left'
            }
        },
        {
            title: 'Titre 1',
            block: 'h1',
            styles: {
                color: '#199fe2',
                'font-weight': 'bold',
                'font-size': '30px',
                'text-transform': 'uppercase',
                'text-align': 'center'
            }
        },
        {
            title: 'Titre 2',
            block: 'h2',
            styles: {
                color: '#199fe2',
                'font-weight': 'bold',
                'font-size': '20px',
                'text-transform': 'uppercase',
                'text-align': 'left'
            }
        },
        {
            title: 'Titre 3',
            block: 'h3',
            styles: {
                color: '#199fe2',
                'font-weight': 'bold',
                'font-size': '18px',
                'text-transform': 'uppercase',
                'text-align': 'left'
            }
        },
        {
            title: 'Légende',
            block: 'p',
            styles: {
                color: '#199fe2',
                'font-size': '18px',
                'font-style': 'italic',
                'text-align': 'left'
            }
        },
        {
            title: 'Encadré',
            inline: 'span',
            styles: {
                display: 'inline-block',
                'font-weight': 'normal',
                'font-size': '18px',
                'font-style': 'normal',
                padding: '10px',
                color: 'white',
                background: '#199fe2',
                'text-align': 'left'
            }
        },
        {
            title: 'Verbatim',
            inline: 'span',
            classes: 'special',
            styles: {
                display: 'inline-block',
                'font-weight': 'bold',
                'font-size': '18px',
                'font-style': 'italic',
                padding: '10px',
                color: 'white',
                background: '#11384b',
                'text-align': 'left'
            }
        }
    ],
    convert_fonts_to_spans: true,
    paste_word_valid_elements:
        'b,strong,i,em,h1,h2,u,p,ol,ul,li,a[href],span,color,font-size,font-color,font-family,mark,table,tr,td',
    paste_retain_style_properties: 'all',
    textcolor_map: [
        '199FE2',
        '199FE2',
        '102C3A',
        '102C3A',
        '146187',
        '146187',
        '11384B',
        '11384B',
        '01A59B',
        '01A59B',
        'CCEDEB',
        'CCEDEB',
        '424242',
        '424242',
        'A8A8A8',
        'A8A8A8',
        'FF5A00',
        'FF5A00',
        'EA63D2',
        'EA63D2',
        'AB80FB',
        'AB80FB',
        '199FE2',
        '199FE2',
        '19BAE2',
        '19BAE2',
        '007A73',
        '007A73',
        '1F94CE',
        '1F94CE',
        '01A59B',
        '01A59B',
        'FF3366',
        'FF3366',
        'FF0000',
        'FF0000'
    ],
    advlist_number_styles: 'default,lower-alpha,lower-roman,upper-alpha,upper-roman',
    advlist_bullet_styles: 'default',
    media_alt_source: false,
    media_poster: false,
    default_link_target: '_blank',
    extended_valid_elements: 'a[href|target=_blank]'
};
