<div id="queued-report-container" *ngIf="status$ | async as status">
    <div class="title">Génération des rapports apprenants</div>
    <div class="in-progress info" *ngIf="status === 'processing'">
        Les rapports sont en cours de traitement...
    </div>
    <div class="created info" *ngIf="status === 'created'">
        Les rapports sont en cours de traitement...
    </div>
    <div class="succeeded info" *ngIf="status === 'succeeded'">
        Le fichier est prêt à être téléchargé
    </div>

    <div
        id="download-report-zip-button"
        [ngClass]="{ disabled: status !== 'succeeded' }"
        (click)="downloadReport()"
    >
        Télécharger le fichier
    </div>

    <app-loading *ngIf="!status"></app-loading>
</div>
