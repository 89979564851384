<div class="activity-report-container">
    <div class="activity-report_download">
        <span
            class="download-button"
            [ngClass]="{
                disabled: isDownloadDisabled()
            }"
            [tooltip]="getDownloadTooltip()"
            tooltipColor="#199fe2"
            (click)="downloadReport()"
        >
            Télécharger le rapport
        </span>
    </div>

    <div class="activity-report_header">
        <lib-uimm-dropdown
            [label]="'Structure'"
            [labelWidth]="'220px'"
            [(items)]="structures"
            (itemsChange)="onStructureChange($event)"
            [headers]="{
                search: true,
                selectAll: false,
                count: false
            }"
            *ngIf="isNationalAdmin()"
        ></lib-uimm-dropdown>

        <lib-uimm-dropdown
            class="element-dropdown"
            [label]="'Apprenants / groupes'"
            [labelWidth]="'190px'"
            [(items)]="elementType"
            (itemsChange)="onStructureChange($event)"
            [uniformWidth]="true"
        ></lib-uimm-dropdown>

        <div class="capabilities">
            <div>
                <span
                    [ngClass]="getCapabilitiesIcon()"
                    (click)="togglePrintItem('printCapabilities')"
                ></span>
                Moyens mis en place par la structure
            </div>

            <div>
                <app-dates-selector
                    [(dateStart)]="printCapabilitiesDates.start"
                    [(dateEnd)]="printCapabilitiesDates.end"
                    [minDateStart]="minDate"
                    [minDateEnd]="minDate"
                    [maxDateStart]="yesterday"
                    [maxDateEnd]="yesterday"
                ></app-dates-selector>
            </div>
        </div>

        <div class="learner-activity">
            <div>
                <span
                    [ngClass]="getActivityIcon()"
                    (click)="togglePrintItem('printLearnerActivity')"
                ></span>
                Activité de l'apprenant
            </div>

            <div>
                <app-dates-selector
                    [(dateStart)]="printLearnerActivityDates.start"
                    [(dateEnd)]="printLearnerActivityDates.end"
                    [minDateStart]="minDate"
                    [minDateEnd]="minDate"
                    [maxDateStart]="yesterday"
                    [maxDateEnd]="yesterday"
                ></app-dates-selector>
            </div>
        </div>

        <div></div>
    </div>
    <div class="activity-report_counters">
        <div class="counter">
            {{ getCounterLabel() }}
        </div>
        <div>
            <span class="search-div">
                <input
                    type="search"
                    class="search"
                    [(ngModel)]="searchTerm"
                    placeholder="Rechercher..."
                    (keyup.enter)="onStructureChange($event)"
                />
                <span class="icon-search" (click)="onStructureChange($event)"></span>
            </span>
        </div>
    </div>
    <div class="warning">
        Les données des rapports sont actualisées la nuit (optimisation de la performance) : vous ne
        pouvez donc télécharger un rapport que jusqu'à la veille.<br />
        La fonctionnalité "Rapport d'activité de l'apprenant" n'est disponible que pour des données
        postérieures au 1er juin 2020
    </div>
    <ng-container *ngIf="!isLoading('activityReportMainView')">
        <div
            class="activity-report_list"
            *ngIf="elementTypeSelected() === 'learners'"
            infiniteScroll
            [infiniteScrollDistance]="1"
            [infiniteScrollThrottle]="50"
            [scrollWindow]="false"
            (scrolled)="nextPageLearners()"
        >
            <div
                class="element"
                *ngFor="let user of users"
                (click)="selectUser(user)"
                [ngClass]="{
                    selected: user.selected,
                    disabled: !isEnabledUser(user),
                    'invalid-date': isInvalidDate(user)
                }"
            >
                <span [ngClass]="user.selected ? 'icon-selected' : 'icon-select'"></span>
                <div [ngClass]="getUserIcon(user)"></div>
                <div class="name">
                    {{ user.lastname.toUpperCase() }}
                    {{ user.firstname }}
                </div>
                <div *ngIf="getYpareoID(user)">
                    {{ getYpareoID(user) }}
                </div>
                <div class="icon-UserYPareo" *ngIf="getYpareoID(user)"></div>
                <div
                    class="icon-DesactiverUser"
                    *ngIf="!isEnabledUser(user)"
                    [tooltip]="'Utilisateur désactivé'"
                ></div>
                <div
                    class="icon-calendrier"
                    *ngIf="isInvalidDate(user) || isValidDate(user)"
                    [tooltip]="getDateTooltip(user)"
                ></div>
            </div>
        </div>
        <div
            class="activity-report_list"
            *ngIf="elementTypeSelected() === 'groups'"
            infiniteScroll
            [infiniteScrollDistance]="1"
            [infiniteScrollThrottle]="50"
            [scrollWindow]="false"
            (scrolled)="nextPageGroups()"
        >
            <div
                class="element"
                *ngFor="let group of groups"
                (click)="selectGroup(group)"
                [ngClass]="{
                    selected: group.selected
                }"
            >
                <span [ngClass]="group.selected ? 'icon-selected' : 'icon-select'"></span>
                <div [ngClass]="getUserIcon(group)"></div>
                {{ group.name }}
            </div>
        </div>
        <app-loading *ngIf="isLoading('activityReportNextPage')"></app-loading>
    </ng-container>
    <app-loading *ngIf="isLoading('activityReportMainView')"></app-loading>
</div>
<app-loading class="waiting" *ngIf="isLoading('downloadActivityReportMainView')"></app-loading>
