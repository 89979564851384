import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export function log(): MethodDecorator {
    if (environment.debug.success || environment.debug.error) {
        return function (target: Function, key: string, descriptor: any) {
            const originalMethod = descriptor.value;

            descriptor.value = function (...args: any[]) {
                const result = originalMethod.apply(this, args);

                if (result instanceof Observable && !environment.production) {
                    return result.pipe(
                        tap(
                            (data: any) => {
                                if (environment.debug.success) {
                                    console.log(
                                        `%c${target.constructor.name}: ${key}`,
                                        'color:#007fad;',
                                        data
                                    );
                                }
                            },
                            (error: any) => {
                                if (environment.debug.error) {
                                    console.error(`%c${target.constructor.name}: ${key}`, error);
                                }
                            }
                        )
                    );
                } else {
                    return result;
                }
            };

            return descriptor;
        };
    } else {
        return function (target: Function, key: string, descriptor: any) {
            return descriptor;
        };
    }
}
