import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DropdownComponent } from './uimm-dropdown.component';
import { ElementComponent } from './element/element.component';


@NgModule({
  declarations: [
    DropdownComponent,
    ElementComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    DropdownComponent,
  ]
})
export class DropdownModule { }
