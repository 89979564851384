import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { DialogService } from '../../../services/dialog.service';
import { LoginService } from '../../../services/login.service';
import { UsersService } from '../../../services/users.service';
import { ConfigService } from '../../../services/config.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-validate-email',
    templateUrl: './validate-email.component.html',
    styleUrls: ['./validate-email.component.scss']
})
export class ValidateEmailComponent implements OnInit, OnDestroy {
    constructor(
        private dialogService: DialogService,
        private loginService: LoginService,
        private usersService: UsersService,
        private configService: ConfigService,
        private router: Router,
        private route: ActivatedRoute,
        private cookieService: CookieService
    ) {}

    subscriptions: Subscription = new Subscription();

    successMessage: string;

    ngOnInit() {
        if (
            !this.loginService.getUser() ||
            (this.loginService.getUser().id === +this.route.snapshot.queryParamMap.get('userid') &&
                this.route.snapshot.queryParamMap.get('token'))
        ) {
            if (this.route.snapshot.queryParamMap.get('hash')) {
                this.updateEmail();
            } else {
                this.validateEmail();
            }
        } else if (
            this.loginService.getUser().id !== +this.route.snapshot.queryParamMap.get('userid') &&
            this.route.snapshot.queryParamMap.get('token')
        ) {
            this.dialogService
                .openError(
                    "Veuillez vous déconnecter avant de lancer à nouveau le processus de validation d'un nouveau compte."
                )
                .subscribe(() => {
                    this.subscriptions.add(
                        this.loginService.logout().subscribe(() => {
                            this.router.navigate(['login']);
                        })
                    );
                });
        } else {
            this.dialogService
                .openError(
                    "Vous ne pouvez pas vous connecter si l'adresse email renseignée pour votre compte n'est pas validée.<br/>Vérifiez votre boite de réception et validez votre adresse email via le lien transmis."
                )
                .subscribe(() => {
                    this.subscriptions.add(
                        this.loginService.logout().subscribe(() => {
                            this.router.navigate(['login']);
                        })
                    );
                });
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    isDarkTheme() {
        return this.cookieService.get('dark') === 'true';
    }

    updateEmail() {
        this.subscriptions.add(
            this.usersService
                .updateEmailWithHash(
                    +this.route.snapshot.queryParamMap.get('userid'),
                    this.route.snapshot.queryParamMap.get('email'),
                    this.route.snapshot.queryParamMap.get('token'),
                    this.route.snapshot.queryParamMap.get('hash')
                )
                .subscribe(() => {
                    this.subscriptions.add(
                        this.loginService.getCurrentUser().subscribe(() => {
                            this.successMessage = this.configService.getConfig().confirmEmailMessage;
                        })
                    );
                })
        );
    }

    validateEmail() {
        this.subscriptions.add(
            this.usersService
                .validateEmail(
                    +this.route.snapshot.queryParamMap.get('userid'),
                    this.route.snapshot.queryParamMap.get('email'),
                    this.route.snapshot.queryParamMap.get('token')
                )
                .subscribe((data: any) => {
                    if (data.redirectUrl) {
                        window.location.href = data.redirectUrl;
                    } else {
                        this.subscriptions.add(
                            this.loginService.getCurrentUser().subscribe(() => {
                                this.successMessage = this.configService.getConfig().confirmEmailMessage;
                            })
                        );
                    }
                })
        );
    }

    logout() {
        this.loginService.logout().subscribe(() => {
            this.router.navigate(['login']);
        });
    }

    redirectOnEasi(): void {
        this.loginService.clearExceptionAndNavigate();
    }
}
