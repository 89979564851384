import { Component, OnInit, Inject } from '@angular/core';
import { Subscription, Observable } from 'rxjs';

import { LoginService } from 'src/app/services/login.service';
import { LoadingService } from 'src/app/services/loading.service';

import { StructuresService } from 'src/app/services/structures.service';
import { GroupsService } from 'src/app/services/groups.service';
import { DialogService } from 'src/app/services/dialog.service';

import { Group } from 'src/app/structures/group';
import { Structure } from 'src/app/structures/structure';

@Component({
    selector: 'app-groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
    constructor(
        private loginService: LoginService,
        private loadingService: LoadingService,
        private structuresService: StructuresService,
        private groupsService: GroupsService,
        private dialogService: DialogService
    ) {}

    subscriptions$: Subscription = new Subscription();

    groups: Array<Group> = [];
    groupsCount = 0;
    groupTypeModalOpened = false;

    structures: Array<Structure>;
    usersTypes: Array<{
        key: string;
        title: string;
        selected: boolean;
    }> = [
        {
            title: 'Apprenants',
            key: 'learners',
            selected: true
        },
        {
            title: 'Formateurs',
            key: 'teachers',
            selected: false
        }
    ];
    searchTerm = '';
    searchPlaceholder = 'Chercher...';
    limit = 30;
    offset = 0;

    ngOnInit() {
        this.subscriptions$.add(
            this.structuresService.getStructures().subscribe((data: Structure[]) => {
                this.structures = data.map((structure: Structure) => ({
                    ...structure,
                    key: structure.id,
                    title: structure.name,
                    selected: this.loginService.getUser().structureid === structure.id
                }));
                if (!this.loginService.getUser().roles.nationalAdmin) {
                    this.onFiltersChange();
                }
            })
        );
    }

    onFiltersChange(): void {
        if (!this.structureSelected()) {
            return;
        }
        this.groups = [];
        this.offset = 0;
        this.getGroups();
        this.getGroupsCount();
    }

    getGroups(): void {
        let params: any = {
            structureid: this.structureSelected(),
            limit: this.limit,
            offset: this.offset
        };
        if (this.searchTerm.length > 0) {
            params = {
                ...params,
                search: this.searchTerm
            };
        }
        if (this.userTypeSelected()) {
            params = {
                ...params,
                type: this.userTypeSelected() === 'teachers' ? 'teacher' : 'learner'
            };
        }
        if (this.offset === 0) {
            this.loadingService.startLoading('groupsMainView', 'getGroups');
        } else {
            this.loadingService.startLoading('groupsNextPage', 'getGroups');
        }
        this.subscriptions$.add(
            this.groupsService.getGroups(params).subscribe((groupsReceived: Group[]) => {
                if (this.offset === 0) {
                    this.loadingService.stopLoading('groupsMainView', 'getGroups');
                } else {
                    this.loadingService.stopLoading('groupsNextPage', 'getGroups');
                }
                this.groups = [...this.groups, ...groupsReceived];
                this.offset += this.limit;
            })
        );
    }

    getGroupsCount(): void {
        let params: any = {
            structureid: this.structureSelected()
        };
        if (this.searchTerm.length > 0) {
            params = {
                ...params,
                search: this.searchTerm
            };
        }
        if (this.userTypeSelected()) {
            params = {
                ...params,
                type: this.userTypeSelected() === 'teachers' ? 'teacher' : 'learner'
            };
        }
        this.loadingService.startLoading('groupsMainView', 'getGroupsCount');

        this.subscriptions$.add(
            this.groupsService.getGroupsCount(params).subscribe((data: { count: number }) => {
                this.loadingService.stopLoading('groupsMainView', 'getGroupsCount');
                this.groupsCount = data.count;
            })
        );
    }

    isNationalAdmin(): boolean {
        if (this.loginService.getUser()) {
            return this.loginService.getUser().roles.nationalAdmin;
        }
    }

    userTypeSelected(): string | false {
        if (this.usersTypes.find((item) => item.selected)) {
            return this.usersTypes.find((item) => item.selected).key;
        }
        return false;
    }

    structureSelected(): number | undefined {
        if (this.structures && this.structures.find((i) => i.selected)) {
            return this.structures.find((item) => item.selected).key;
        }
        return undefined;
    }

    openGroupTypeModal($event: Event): void {
        $event.stopImmediatePropagation();
        this.groupTypeModalOpened = true;
    }

    openCreateGroup(groupType: 'student' | 'teacher'): void {
        this.groupTypeModalOpened = false;
        if (!this.structureSelected()) {
            return;
        }
        const params = {
            localStructure: this.structures.find((s) => s.selected).name,
            structureid: this.structures.find((s) => s.selected).id,
            type: groupType === 'teacher' ? 'teacher' : 'learner',
            users: []
        };
        this.dialogService.openCreateGroup(params).subscribe({
            next: (groupCreated: Group | false) => {
                if (groupCreated) {
                    this.groups = [groupCreated, ...this.groups].sort((a, b) =>
                        a.name.localeCompare(b.name)
                    );
                    this.groupsCount += 1;
                }
            }
        });
    }

    isLoading(view: string) {
        return this.loadingService.isLoading(view);
    }
}
