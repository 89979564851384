<div id="help-container">
    <span class="icon-close" (click)="close()"></span>

    <ng-container *ngIf="helpData">
        <!-- SECTIONS -->
        <ng-container *ngFor="let section of filteredHelpData.sections">
            <div class="section">
                <div class="section-title" *ngIf="section">
                    {{ section.section_title }}
                </div>
                <!-- QUESTIONS FREQUEMMENT POSEES DE SECTION -->
                <div class="questions" *ngIf="section?.question_items">
                    <ng-container *ngFor="let question of section.question_items">
                        <div class="question-card">
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>{{ question.question_title }}</mat-panel-title>
                                </mat-expansion-panel-header>
                                <div
                                    class="question-card__content"
                                    [innerHTML]="question.question_test | safeHtml"
                                ></div>
                            </mat-expansion-panel>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
