<div id="error-component">
    <div class="header">
        <span class="icon-Warning"></span>
        <span class="label">Erreur</span>
    </div>
    <mat-dialog-content class="content" [innerHTML]="errorMessage"></mat-dialog-content>
    <div class="footer">
        <div class="create-button" (click)="closeDialog()">OK</div>
    </div>
</div>
