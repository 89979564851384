import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { LoginService } from '../../../services/login.service';
import { StructuresService } from '../../../services/structures.service';

import { Structure } from '../../../structures/structure';

@Component({
    selector: 'app-national-dashboard',
    templateUrl: './national-dashboard.component.html',
    styleUrls: ['./national-dashboard.component.scss']
})
export class NationalDashboardComponent implements OnInit {
    constructor(
        private sanitizer: DomSanitizer,
        private loginService: LoginService,
        private structuresService: StructuresService
    ) {}

    iframeUrl: SafeResourceUrl;
    structures: Array<Structure>;

    today: Date;
    startDate: Date;
    endDate: Date;

    ngOnInit() {
        this.today = new Date();
        this.startDate = new Date();
        this.endDate = new Date();
        this.startDate.setMonth(this.startDate.getMonth() - 1, this.startDate.getDate() - 1);
        this.startDate.setUTCHours(22, 0, 0, 0);
        this.endDate.setDate(this.endDate.getDate() - 1);
        this.endDate.setUTCHours(21, 59, 59, 0);

        this.structuresService.structures$.subscribe((data: Array<Structure>) => {
            this.structures = data.map((structure) => ({
                ...structure,
                selected: this.loginService.getUser().structureid === structure.id
            }));
            this.structures.unshift({
                title: 'Toutes les structures',
                key: 0
            });
            this.updateIframeUrl();
        });
    }

    isNationalAdmin(): boolean {
        return this.loginService.getUser().roles.nationalAdmin;
    }

    getStructureSelected(): number | undefined {
        if (this.structures && this.structures.some((i) => i.selected)) {
            return this.structures.find((item) => item.selected).key;
        }
        return undefined;
    }

    updateIframeUrl() {
        if (this.getStructureSelected() !== undefined) {
            this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                `/assets/html/national_dashboard/index.html?structureid=${this.getStructureSelected()}&datestart=${this.startDate.toISOString()}&dateend=${this.endDate.toISOString()}`
            );
        }
    }

    getIframeEndpoint() {
        return this.iframeUrl;
    }
}
