import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import html2canvas from 'html2canvas';
import saveSvgAsPng from 'save-svg-as-png';
import { FlashMessageService } from './flash-message.service';
import { font } from '../../assets/fonts/SourceSansPro-Regular-normal';
import { fontBold } from '../../assets/fonts/SourceSansPro-Bold-bold';

// const jsPDF = jsPDF;
// (window as any).html2canvas = html2canvas;

@Injectable({
    providedIn: 'root'
})
export class PdfService {
    UIMMLogo: any;
    EASILogo: any;

    constructor(private flashMessageService: FlashMessageService) {
        this.getBase64Image('./assets/img/logo_uimm.png', 'UIMMLogo');
        this.getBase64Image('./assets/img/logo_easi.png', 'EASILogo');
    }

    async generatePDF(title: string, paramsUsed: any[]) {
        this.flashMessageService.flash(
            'Le graphique est en cours de génération. Merci de patienter'
        );
        const pdf = new jsPDF('l', 'mm', 'a4');
        const PAGE_WIDTH = pdf.internal.pageSize.getWidth();
        const PAGE_HEIGHT = pdf.internal.pageSize.getHeight();
        const MARGIN_TOP = 20;
        const MARGIN_LEFT = 20;

        const graph: any = document.getElementsByClassName('ngx-charts')[0];
        // Pour récupérer les bouts coupés à cause des datalabels modifiés
        if (graph) {
            if (title === `Top 30 des activités les plus utilisées`) {
                graph.setAttribute('width', 950);
                this.hackForCorrectFirefoxPDFRendering(graph);
            } else {
                graph.setAttribute('height', 410);
                this.hackForCorrectFirefoxPDFRendering(graph);
            }
            const elArray = graph.querySelectorAll('[ngx-tooltip]');
            // Fix for bug in lib when exporting bar graph
            elArray.forEach(function (element) {
                element.removeAttribute('ng-reflect-tooltip-title');
            });

            // retire les lignes verticales du pdf
            if (title !== `Top 30 des activités les plus utilisées`) {
                const verticalGridLines = graph.querySelectorAll('.gridline-path-vertical');
                verticalGridLines.forEach((line) => {
                    line.remove();
                });
            }
        }

        // FONTS
        pdf.addFileToVFS('Source Sans Pro.ttf', font);
        pdf.addFileToVFS('Source Sans Pro Bold.ttf', fontBold);
        pdf.addFont('Source Sans Pro.ttf', 'SourceSansPro', 'normal');
        pdf.addFont('Source Sans Pro Bold.ttf', 'SourceSansPro', 'bold');
        pdf.setFont('SourceSansPro');

        // HEADER
        pdf.setFontSize(18);
        pdf.text(title, PAGE_WIDTH / 2, MARGIN_TOP, { align: 'center' });
        if (paramsUsed.length > 0) {
            const verticalposition = MARGIN_TOP + 10;
            const horizontalPosition = MARGIN_LEFT;
            pdf.setFontSize(9);
            pdf.setTextColor('#a8a8a8');

            autoTable(pdf, {
                body: paramsUsed.map((p) => ({
                    title: p.name,
                    value: p.value
                })),
                startY: MARGIN_TOP + 5,
                styles: {
                    fontSize: 7,
                    overflow: 'ellipsize'
                    // fontStyle: 'SourceSansPro'
                },
                didParseCell: (data) => {
                    if (data.column.dataKey === 'title') {
                        data.cell.styles.fontStyle = 'bold';
                    }
                    data.cell.styles.font = 'SourceSansPro';
                }
            });
        }
        // FOOTER
        pdf.addImage(this.UIMMLogo, 'PNG', PAGE_WIDTH - MARGIN_LEFT - 35, PAGE_HEIGHT - 25, 15, 15);
        pdf.addImage(this.EASILogo, 'PNG', PAGE_WIDTH - MARGIN_LEFT - 15, PAGE_HEIGHT - 25, 15, 15);

        // GRAPH
        if (graph) {
            await saveSvgAsPng
                .svgAsPngUri(graph, { scale: 2, excludeCss: false })
                .then((uriPng) => {
                    if (title === `Top 30 des activités les plus utilisées`) {
                        pdf.addImage(
                            uriPng,
                            'PNG',
                            45,
                            65,
                            (PAGE_WIDTH - 90) * 0.9,
                            (((PAGE_WIDTH - 90) * 5) / 9) * 0.9
                        );
                    } else {
                        pdf.addImage(uriPng, 'PNG', 35, 60, PAGE_WIDTH - 75, (PAGE_WIDTH - 75) / 2);
                    }
                    const legend = document.getElementsByClassName(
                        'chart-legend'
                    )[0] as HTMLElement;
                    html2canvas(legend, {}).then((legendCanvas) => {
                        const { width, height } = legendCanvas;
                        const leftPosition = PAGE_WIDTH / 2 - width / 8;
                        let topPosition: number;
                        if (title === `Top 30 des activités les plus utilisées`) {
                            topPosition = PAGE_HEIGHT - (10 + height + MARGIN_TOP / 2);
                        } else if (title === `Nombre d'utilisateurs actifs`) {
                            topPosition = PAGE_HEIGHT - (height + MARGIN_TOP / 2) + 32;
                        } else {
                            topPosition = PAGE_HEIGHT - (height + MARGIN_TOP / 2) + 5;
                        }
                        const widthOnPage = width / 4;
                        const heightOnPage = height / 4;

                        pdf.addImage(
                            legendCanvas,
                            leftPosition,
                            topPosition,
                            widthOnPage,
                            heightOnPage
                        );
                        pdf.save(
                            `UIMM-${title.split(' ').join('_')}-${new Date()
                                .toLocaleDateString('fr-FR')
                                .split('/')
                                .join('-')}.pdf`
                        );
                        this.flashMessageService.stopFlashing();
                    });
                });
        } else {
            pdf.save(
                `UIMM-${title.split(' ').join('_')}-${new Date()
                    .toLocaleDateString('fr-FR')
                    .split('/')
                    .join('-')}.pdf`
            );
            this.flashMessageService.stopFlashing();
        }
    }

    getBase64Image(img, target) {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', img, true);
        xhr.responseType = 'blob';
        xhr.onload = () => {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                const res = event.target.result;
                this[target] = res;
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.send();
    }

    /**
     * Décale de quelques pixels le sous-élément <g.bar-chart.chart> du graph
     * Sinon le graph est coupé sur ce navigateur lors de l'export.
     * NE PAS ENLEVER
     */
    hackForCorrectFirefoxPDFRendering(element) {
        if (navigator.userAgent.indexOf('Firefox') > 0) {
            let svgToFixForFirefox = element.querySelector('.bar-chart.chart');
            if (svgToFixForFirefox) {
                svgToFixForFirefox.setAttribute('transform', 'translate(290, 10)');
            } else {
                svgToFixForFirefox = element.querySelector('.chart');
                const attributeValue = svgToFixForFirefox.getAttribute('transform');
                svgToFixForFirefox.setAttribute('transform', attributeValue + ' scale(0.97)');
            }
        }
    }
}
