<div
    class="user"
    [ngClass]="{
        'user-disabled': !user.enabled,
        'date-invalid': isDateInvalid()
    }"
    [tooltip]="getUserTooltip()"
    [tooltipPosition]="'below'"
>
    <div class="user-description">
        <ng-container>
            <span
                *ngFor="let role of roles"
                [ngClass]="role.icon"
                [tooltip]="role.name"
                tooltipPosition="right"
            ></span>
            <span
                *ngFor="let additionnalRole of additionnalRoles"
                [ngClass]="additionnalRole.icon"
                [tooltip]="additionnalRole.name"
                tooltipPosition="right"
            ></span>
        </ng-container>
        {{ user.lastname.toUpperCase() }}
        {{ user.firstname }}
    </div>
    <div class="user-icons">
        <div class="dynamic-icons">
            <span
                class="icon-FicheUser"
                *ngIf="!hasIncompleteEvents(user)"
                (click)="openUserDialog(user)"
                tooltip="Voir la fiche"
            ></span>
            <span
                class="icon-logAs"
                (click)="loginAs(user)"
                *ngIf="canLoginAs()"
                tooltip="M'identifier en tant que"
            ></span>
            <span
                class="icon-DesactiverUser"
                (click)="disableUser(user)"
                *ngIf="user.enabled"
                tooltip="Désactiver cet utilisateur"
            ></span>
            <span
                class="icon-Reactiver"
                (click)="enableUser(user)"
                *ngIf="!user.enabled"
                tooltip="Activer cet utilisateur"
            ></span>
        </div>
        <div class="fix-icons">
            <span
                class="icon-FicheUser with-warning"
                *ngIf="hasIncompleteEvents(user)"
                (click)="openUserDialog(user)"
                tooltip="Activation du compte non finalisée : consulter la fiche pour plus d'information"
            ></span>

            <span class="reminder-count" *ngIf="hasReminder()" tooltip="Nombre de rappel(s)">
                ({{ user.cgu_validation_reminder_mail }})
            </span>

            <span
                class="icon-DesactiverUser"
                *ngIf="!user.enabled"
                tooltip="Utilisateur désactivé"
            ></span>
            <span
                class="icon-calendrier"
                [ngClass]="getDateClass()"
                *ngIf="showDateIcon()"
                [tooltip]="getDateTooltip()"
            ></span>
        </div>
    </div>
</div>
