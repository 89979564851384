// Internal dependencies
import { Component, OnInit } from '@angular/core';

// External dependencies

// Modules
import { SharedModule } from '@/modules/shared.module';

// Components
import { ConfigurationYpareoComponent } from './configuration-ypareo/configuration-ypareo.component';
import { UsersYpareoComponent } from './users-ypareo/users-ypareo.component';

// Services
import { YpareoService } from '@/services/ypareo.service';
import { LoginService } from '@/services/login.service';

// Interfaces

// Pipes

interface Setting {
    key: string;
    value: string;
    description: string;
    setting_type: string;
    metadata: any;
}

interface ParsedSetting {
    [key: string]: string;
}

@Component({
    selector: 'app-import-ypareo',
    templateUrl: './import-ypareo.component.html',
    styleUrls: ['./import-ypareo.component.scss'],
    imports: [SharedModule, ConfigurationYpareoComponent, UsersYpareoComponent]
})
export class ImportYpareoComponent implements OnInit {
    search: string;
    settings: ParsedSetting;

    constructor(private loginService: LoginService, private ypareoService: YpareoService) {}

    ngOnInit() {
        this.ypareoService
            .fetchSettings(this.loginService.getUser().structureid)
            .subscribe((data: Array<Setting>) => {
                this.settings = data.reduce((acc: ParsedSetting, item: Setting) => {
                    acc[item.key] = item.value;
                    return acc;
                }, {});
            });
    }

    // #region Getters

    isViewVisible(view: string): { visible: boolean; collapsed: boolean } {
        if (this.settings) {
            if (view === 'test_webservices_ypareo') {
                // Tant qu’aucune configuration n’est validée et testée le volet apparait ouvert pour les administrateurs
                if (this.loginService.getUser().roles.localAdmin) {
                    return {
                        visible: true,
                        collapsed: false
                    };
                }
            } else if (view === 'no_configuration_found') {
                // Tant qu’aucune configuration n’est validée les gestionnaires de comptes ne voient qu’un texte les incitant à prendre contact avec un admin local de leur pôle
                if (
                    !(this.settings.ypareo_base_url !== '' && this.settings.ypareo_token !== '') &&
                    this.loginService.getUser().additionalRoles.accountManager
                ) {
                    return {
                        visible: true,
                        collapsed: false
                    };
                }
            } else if (view === 'import_users') {
                // Quand la configuration est validée on fait apparaitre le volet « import d’utilisateurs » aux administrateurs locaux. Dès lors, le volet apparaitra « replié » par défaut.
                if (
                    this.settings.ypareo_base_url !== '' &&
                    this.settings.ypareo_token !== '' &&
                    this.loginService.getUser().roles.localAdmin
                ) {
                    return {
                        visible: true,
                        collapsed: true
                    };
                }
                // Quand la configuration est validée on fait apparaitre le volet « import d’utilisateurs » aux gestionnaires de comptes. Le texte précédemment affiché est masqué dès lors qu’une configuration est validée.
                else if (
                    this.settings.ypareo_base_url !== '' &&
                    this.settings.ypareo_token !== '' &&
                    this.loginService.getUser().additionalRoles.accountManager
                ) {
                    return {
                        visible: true,
                        collapsed: false
                    };
                }
            }
        }

        return {
            visible: false,
            collapsed: false
        };
    }

    // #endregion Getters

    // #region Handlers

    // #endregion Handlers

    // #region Internals

    // #endregion Internals
}
