import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EasiHttpParams } from './easi-http-params';
import { filter, tap } from 'rxjs/operators';
import { LogService } from '../services/log.service';
import { ConfigService } from '../services/config.service';

@Injectable()
export class LogInterceptor implements HttpInterceptor {
    constructor(private configService: ConfigService, private logService: LogService) {}

    /**
     * La requête est interceptée ici, si ses paramètres sont de type LogHttpParams (donc avec une string custom)
     * La réponse est transmise au log service
     * Sinon la réponse passe sans log
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.params instanceof EasiHttpParams && request.params.log) {
            const log = request.params.log;

            return next.handle(request).pipe(
                /**
                 * Filtrage des HttpEvent de type 0, qui correspondent à une requête correctement envoyée.
                 */
                filter((event: HttpEvent<any>) => event.type !== 0),
                tap(
                    (response: HttpResponse<any>) => {
                        if (response) {
                            if (response.body) {
                                this.logService.success(log, response.body);
                            }
                        }
                    },
                    (error: HttpErrorResponse) => {
                        if (error) {
                            this.logService.error(log, error);
                        }
                    }
                )
            );
        } else {
            return next.handle(request);
        }
    }
}
