import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { log } from './decorators/log.decorator';
import { EasiHttpParams } from '../interceptors/easi-http-params';

import { PreviewImportYpareo } from '@/structures/preview-import-ypareo';

interface Setting {
    key: string;
    value: string;
    description: string;
    setting_type: string;
    metadata: any;
}

@Injectable({
    providedIn: 'root'
})
export class YpareoService {
    constructor(private http: HttpClient) {}

    @log() fetchSettings(structureId: number): Observable<any> {
        return this.http.get<Setting[]>(`/ypareo/settings/${structureId}`);
    }

    @log() testNewSettings(params: any): Observable<any> {
        const config = {
            params: new EasiHttpParams({ noError: true, params: { ...params } })
        };
        return this.http.get<Setting[]>(`/ypareo/connection_test`, config);
    }

    /**
     * Saves settings for a given structure ID to the API.
     * @param structureId - The ID of the structure to save settings for.
     * @param settings - The settings to be saved.
     * @returns An observable of the HTTP response.
     */
    @log() saveSettings(structureId: number, settings: object): Observable<any> {
        return this.http.post(`/ypareo/settings/${structureId}`, { settings });
    }

    @log() searchUsers(structureId: number, filters: any): Observable<any> {
        Object.keys(filters).forEach((key) =>
            filters[key] === undefined ? delete filters[key] : {}
        );

        return this.http.get<Setting[]>(`/ypareo/search_users/${structureId}`, {
            params: {
                ...filters
            }
        });
    }

    @log() getUsersCSV(structureId: number, ypareoIds: string): Observable<any> {
        return this.http.get<any>(`/ypareo/get_csv`, {
            params: {
                ypareo_ids: ypareoIds,
                structure_id: structureId
            },
            responseType: 'blob' as 'json'
        });
    }

    @log() getImportPreview(ypareoIds: string): Observable<PreviewImportYpareo> {
        return this.http.get<PreviewImportYpareo>(`/ypareo/get_import_preview`, {
            params: {
                ypareo_ids: ypareoIds
            }
        });
    }

    @log() setUsersToSynchronize(ypareoIds: string) {
        return this.http.post<boolean>(`/ypareo/set_users_to_synchronize`, {
            ypareo_ids: ypareoIds
        });
    }
}
