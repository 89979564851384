<div id="groups-component-container" *ngIf="!isLoading('groupsMainView')">
    <!-- ##### HEADER ##### -->
    <div class="groups-header">
        <div class="filters-dropdowns-container">
            <lib-uimm-dropdown
                [label]="'Structure'"
                [labelWidth]="'220px'"
                [(items)]="structures"
                (itemsChange)="onFiltersChange()"
                [headers]="{
                    search: true,
                    selectAll: false,
                    count: true
                }"
                *ngIf="isNationalAdmin()"
            ></lib-uimm-dropdown>
            <lib-uimm-dropdown
                [label]="'Apprenants / Formateurs'"
                [labelWidth]="'220px'"
                [(items)]="usersTypes"
                (itemsChange)="onFiltersChange()"
                [uniformWidth]="true"
            ></lib-uimm-dropdown>
        </div>
        <div class="count-and-search-container" *ngIf="structureSelected()">
            <div class="count">
                <strong>{{ groupsCount }}</strong>
                groupes
            </div>
            <div class="search-container">
                <input
                    type="search"
                    class="search"
                    [(ngModel)]="searchTerm"
                    [placeholder]="searchPlaceholder"
                    (keyup.enter)="onFiltersChange()"
                    (focus)="searchPlaceholder = 'Entrez un mot clef...'"
                    (blur)="searchPlaceholder = 'Chercher...'"
                />
                <span class="icon-search" (click)="onFiltersChange()"></span>
            </div>
        </div>
    </div>
    <!-- ##### END HEADER ##### -->

    <div
        class="groups-list"
        *ngIf="structureSelected()"
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="getGroups()"
    >
        <app-group-entry *ngFor="let group of groups" [group]="group"></app-group-entry>
    </div>

    <div class="message_empty_structure" *ngIf="structureSelected() === undefined">
        <p>Veuillez sélectionner une structure.</p>
        <div>
            <img src="assets/img/library-default.png" />
        </div>
    </div>

    <app-loading *ngIf="isLoading('groupsNextPage')"></app-loading>
</div>

<div class="add-group-button" *ngIf="structureSelected()" (click)="openGroupTypeModal($event)">
    <div class="icon icon-groupe" tooltip="Créer un nouveau groupe" tooltipColor="#199fe2"></div>
    <div class="modal" *ngIf="groupTypeModalOpened" (clickOutside)="groupTypeModalOpened = false">
        <div class="create-button create-students-groups" (click)="openCreateGroup('student')">
            <span class="icon-apprenant"></span>Créer un groupe d'apprenants
        </div>
        <div class="create-button create-teachers-groups" (click)="openCreateGroup('teacher')">
            <span class="icon-formateurinterne"></span>Créer un groupe de formateurs
        </div>
    </div>
</div>

<app-loading *ngIf="isLoading('groupsMainView')"></app-loading>
