import { Component, OnInit, Inject, ApplicationRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, Subject } from 'rxjs';

import { ApplicationService } from '../../services/application.service';
import { LoginService } from '../../services/login.service';
import { SiteService } from '../../services/site.service';
import { StructuresService } from '../../services/structures.service';
import { ContactService } from '../../services/contact.service';
import { ConfigService } from 'src/app/services/config.service';
import { UsersService } from '../../services/users.service';

import { tinyConfigContact } from '../../../assets/config/tiny-mce-config';

import { Contact } from '../../structures/contact';
import { Structure } from '../../structures/structure';
import { Site } from '../../structures/site';
import { Role } from '../../structures/role';
import { Application } from '../../structures/application';

@Component({
    selector: 'app-create-contact',
    templateUrl: './create-contact.component.html',
    styleUrls: ['./create-contact.component.scss']
})
export class CreateContactComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public contact: Contact,
        public dialogRef: MatDialogRef<CreateContactComponent>,
        private applicationService: ApplicationService,
        private structuresService: StructuresService,
        private contactService: ContactService,
        private configService: ConfigService,
        private loginService: LoginService,
        private usersService: UsersService,
        private siteService: SiteService,
        private application: ApplicationRef
    ) {
        this.tinyConfig = tinyConfigContact;
    }

    subscriptions: Subscription = new Subscription();
    openAddAvatar: Subject<any> = new Subject();

    structures: Array<Structure>;
    sites: Array<Site>;
    roles: Array<Role>;
    applications: Array<Application>;
    tinyConfig: any;

    firstMenu = false;
    secondMenu = false;

    ngOnInit() {
        if (!this.contact) {
            this.contact = {
                applications: {},
                assistance_card: `<div class="assistance"><h3 style="color: #199fe2; font-weight: bold; font-size: 18px; text-transform: uppercase; text-align: center; margin: 0;">Assistance technique site 1</h3>
                <p style="text-align: center;"><span style="color: #199fe2;">Vous avez besoin d'aide ?</span></p>
                <p style="text-align: center;"><span style="color: #199fe2;">N'hésitez pas à contacter :</span></p></div>`,
                avatar: '',
                contact_card: `<div style="text-align: center;"><span style="color: white;"><strong><span style="font-size: 18pt;">Pr&eacute;nom Nom</span></strong></span></div>
                <div style="text-align: center;"><span style="color: white; font-size: 14pt;">Titre</span></div>
                <div style="text-align: center;"><span style="color: white; font-size: 14pt;">Adresse email</span></div>
                <div style="text-align: center;"><span style="color: white;"><strong><span style="font-size: 14pt;">T&eacute;l&eacute;phone</span></strong></span></div>`,
                display_contact_card: true,
                hidden: false,
                name: '',
                roles: {},
                sites: [],
                tags: {},
                structureid: undefined,
                training_types: []
            };
        }
        this.subscriptions.add(
            this.structuresService.getStructures().subscribe((data: Array<Structure>) => {
                data.push({
                    name: 'Administration nationale',
                    title: 'Administration nationale',
                    id: 0,
                    key: 0
                });
                this.structures = data.map((structure: Structure) => ({
                    ...structure,
                    key: structure.id,
                    title: structure.name,
                    selected: structure.id === this.contact.structureid
                }));
                if (!this.isNationalAdmin()) {
                    this.contact.structureid = this.loginService.getUser().structureid;
                }

                this.getRoles();

                if (this.getSelectedStructure()) {
                    this.updateStructure();
                }
            })
        );
    }

    updateStructure() {
        this.contact.structureid = this.getSelectedStructure().id;
        this.getApplications();
        if (this.getSelectedStructure().id !== 0) {
            this.getSites();
        } else {
            this.sites = [];
        }
    }

    getApplications() {
        if (this.getSelectedStructure()) {
            this.subscriptions.add(
                this.applicationService
                    .getApplicationsAccess(this.getSelectedStructure().id)
                    .subscribe((applications: Array<Application>) => {
                        this.applications = applications;
                        if (!this.contact.id) {
                            this.applications.map((application) => {
                                this.contact.applications[application.app_id] = true;
                            });
                        }
                    })
            );
        }
    }

    getRoles() {
        this.subscriptions.add(
            this.usersService.getRolesAndTags().subscribe((roles: Array<Role>) => {
                if (
                    !this.loginService.getUser().roles.nationalAdmin &&
                    roles.findIndex((role) => role.shortname === 'nationalAdmin') !== -1
                ) {
                    roles.splice(
                        roles.findIndex((role) => role.shortname === 'nationalAdmin'),
                        1
                    );
                }
                this.roles = roles;
                if (!this.contact.id) {
                    this.roles.map((role) => {
                        if (role.shortname !== 'educationalSpecialist') {
                            this.contact.roles[role.shortname] = false;
                        } else {
                            this.contact.tags[role.shortname] = false;
                        }
                    });
                }
            })
        );
    }

    getSelectedStructure() {
        if (this.isNationalAdmin() && this.structures) {
            const structure = this.structures.filter((struct) => struct.selected)[0];
            if (structure) {
                return {
                    id: structure.key,
                    name: structure.name
                };
            }
        } else {
            return {
                id: this.loginService.getUser().structureid,
                name: this.loginService.getUser().localStructure
            };
        }
    }

    getSites() {
        if (this.getSelectedStructure()) {
            const params = {
                structureid: this.getSelectedStructure().id,
                offset: 0
            };
            this.subscriptions.add(
                this.siteService.getSites(params).subscribe((data: Array<Site>) => {
                    this.sites = data.map((site: Site) => ({
                        ...site,
                        key: site.id,
                        title: site.name,
                        selected: this.contact.sites.findIndex((site2) => site2 === site.name) > -1
                    }));
                })
            );
        }
    }

    getSiteDropdownLabel() {
        if (this.sites) {
            if (this.getSelectedSite().length === 0) {
                return 'Tous les sites';
            } else if (this.getSelectedSite().length === 1) {
                return 'Site';
            } else {
                return 'Sites';
            }
        }
    }

    getSelectedSite() {
        return this.sites.filter((site: Site) => site.selected);
    }

    getTitle() {
        return !this.contact.id ? 'Créer un contact' : 'Modifier le contact';
    }

    getFooter() {
        return !this.contact.id ? 'Créer le contact' : 'Modifier le contact';
    }

    isNationalAdmin() {
        return this.loginService.getUser().roles.nationalAdmin;
    }

    displayFirstMenu() {
        if (!this.firstMenu) {
            return 'hidden';
        }
    }

    displaySecondMenu() {
        if (!this.secondMenu) {
            return 'hidden';
        }
    }

    toggleFirstMenu() {
        this.firstMenu = !this.firstMenu;
        this.application.tick();
    }

    toggleSecondMenu() {
        this.secondMenu = !this.secondMenu;
        this.application.tick();
    }

    toggleDisplayContactCard() {
        this.contact.display_contact_card = !this.contact.display_contact_card;
    }

    toggleRole(role: Role) {
        if (role.shortname === 'educationalSpecialist') {
            this.contact.tags[role.shortname] = !this.contact.tags[role.shortname];
        } else {
            this.contact.roles[role.shortname] = !this.contact.roles[role.shortname];
        }
    }

    toggleAllRole() {
        if (this.getAllSelectedRoleClass() === 'icon-selected') {
            this.roles.map((role: Role) => {
                if (role.shortname === 'educationalSpecialist') {
                    this.contact.tags[role.shortname] = false;
                } else {
                    this.contact.roles[role.shortname] = false;
                }
            });
        } else {
            this.roles.map((role: Role) => {
                if (role.shortname === 'educationalSpecialist') {
                    this.contact.tags[role.shortname] = true;
                } else {
                    this.contact.roles[role.shortname] = true;
                }
            });
        }
    }

    getSelectedRoleClass(role: Role) {
        if (this.contact.roles[role.shortname]) {
            if (role.icon) {
                return [role.icon, 'selected'];
            } else {
                return ['selected'];
            }
        }
        if (this.contact.tags[role.shortname]) {
            if (role.icon) {
                return [role.icon, 'selected'];
            } else {
                return ['selected'];
            }
        } else {
            if (role.icon) {
                return [role.icon];
            } else {
                return [''];
            }
        }
    }

    getAllSelectedRoleClass() {
        if (!this.roles) {
            return '';
        }
        let found = false;
        this.roles.map((role: Role) => {
            if (this.contact.roles[role.shortname]) {
                found = true;
            }
        });
        return found ? 'icon-selected' : 'icon-select';
    }

    toggleTrainingType(type: string) {
        if (this.isSelectedTrainingType(type)) {
            this.contact.training_types.splice(this.contact.training_types.indexOf(type), 1);
        } else {
            this.contact.training_types.push(type);
        }
    }

    isSelectedTrainingType(type: string) {
        return this.contact.training_types.indexOf(type) > -1;
    }

    getApplicationIcon(application: Application) {
        switch (application.app_id) {
            case 'easitraining':
                return 'icon-formateurinterne';
            case 'easilearning':
                return 'icon-easi-training-line';
            case 'easicall':
                return 'icon-easi-call-line';
            case 'easicloud':
                return 'icon-easi-cloud-line';
            case 'easiforum':
                return 'icon-easi-forum-line';
            case 'easichat':
                return 'icon-Messages';
            case 'easimedia':
                return 'icon-easi-media-line';
        }
        return '';
    }

    toggleApplication(application: Application) {
        this.contact.applications[application.app_id] = !this.contact.applications[
            application.app_id
        ];
    }

    toggleAllApplication() {
        if (this.getAllSelectedApplicationClass() === 'icon-selected') {
            this.applications.map((application: Application) => {
                this.contact.applications[application.app_id] = false;
            });
        } else {
            this.applications.map((application: Application) => {
                this.contact.applications[application.app_id] = true;
            });
        }
    }

    getSelectedApplicationClass(application: Application) {
        if (this.contact.applications[application.app_id]) {
            return 'selected';
        } else {
            return '';
        }
    }

    getAllSelectedApplicationClass() {
        if (!this.applications) {
            return '';
        }
        let found = false;
        this.applications.map((application: Application) => {
            if (this.contact.applications[application.app_id]) {
                found = true;
            }
        });
        return found ? 'icon-selected' : 'icon-select';
    }

    createContact() {
        if (!this.contact.name) {
            return;
        }
        this.contact.sites = this.getSelectedSite().map((site: Site) => site.key);
        let avatar = '';
        if (this.contact.avatar && this.contact.avatar.search(/data:image\/.*;base64/g) > -1) {
            avatar = this.contact.avatar;
            this.contact.avatar = '';
        }

        if (!this.contact.id) {
            this.contactService.createContact(this.contact).subscribe((contact) => {
                if (avatar) {
                    contact.avatar = avatar;
                    this.contactService.postAvatar(contact, avatar).subscribe();
                }
                this.contactService.emitContactCreated(contact);
                this.dialogRef.close(true);
            });
        } else {
            this.contactService.updateContact(this.contact).subscribe((contact) => {
                if (avatar) {
                    contact.avatar = avatar;
                    this.contactService.postAvatar(contact, avatar).subscribe();
                }
                this.contactService.emitContactUpdated(contact);
                this.dialogRef.close(true);
            });
        }
    }

    getAPIKey() {
        return this.configService.getConfig().tinyMCE_api_key;
    }

    uploadAvatar() {
        this.openAddAvatar.next(this.contact.avatar);
    }

    closeDialog(): void {
        this.dialogRef.close(false);
    }
}
