<div class="header">
    <lib-uimm-dropdown
        [label]="'Structure'"
        [labelWidth]="'250px'"
        [(items)]="structures"
        (itemsChange)="updateIframeUrl()"
        [headers]="{
            search: true,
            selectAll: false,
            count: false
        }"
        *ngIf="isNationalAdmin()"
    ></lib-uimm-dropdown>
    <app-dates-selector
        [(dateStart)]="startDate"
        (dateStartChange)="updateIframeUrl()"
        [maxDateStart]="endDate"
        [(dateEnd)]="endDate"
        (dateEndChange)="updateIframeUrl()"
        [maxDateEnd]="today"
    ></app-dates-selector>
</div>
<div class="container">
    <iframe [src]="getIframeEndpoint()" *ngIf="getStructureSelected() !== undefined"></iframe>
    <div class="empty-structure" *ngIf="getStructureSelected() === undefined">
        <p>Veuillez sélectionner une structure.</p>
        <div>
            <img src="assets/img/library-default.png" />
        </div>
    </div>
</div>
